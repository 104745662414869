import { createInstance, token } from "./api";

const instance = createInstance("/payment");

//POST

export const payNow = async (payData: any) => {
  const response = await instance.post("/execute-payment", payData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log(response);

  return response;
};

// GET

export const checkPay = async (paymentId: any) => {
  const response = await instance.get(
    `/verify-payment?paymentId=${paymentId}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};
