import { tokens } from "@fluentui/react-components";
import {
  CheckmarkCircleFilled,
  CheckmarkFilled,
  ClipboardLinkFilled,
  ClockFilled,
  ErrorCircleFilled,
  LiveFilled,
  PeopleAudienceFilled,
  PeopleEyeFilled,
} from "@fluentui/react-icons";

const statusData = [
  {
    status: 0,
    label: "Active",
    icon: LiveFilled,
    color: tokens.colorPaletteGreenForeground3,
    count: 0,
  },
  {
    status: 1,
    label: "Pending",
    icon: ClockFilled,
    color: tokens.colorPaletteMarigoldBackground3,
    count: 0,
  },
  {
    status: 2,
    label: "Finished",
    icon: CheckmarkCircleFilled,
    color: tokens.colorNeutralForeground3,
    count: 0,
  },
];

export const eventStatus = {
  registrationStatus: {
    label: "Registration Status",
    icon: ClipboardLinkFilled,
    status: {
      active: {
        icon: LiveFilled,
        label: "Active",
        color: tokens.colorPaletteGreenForeground3,
      },
      complete: {
        icon: CheckmarkFilled,
        label: "Complete",
        color: tokens.colorNeutralForeground3,
      },
      disabled: {
        icon: ErrorCircleFilled,
        label: "Disabled",
        color: tokens.colorPaletteRedForeground3,
      },
    },
  },
  audience: {
    expectedAudience: {
      label: "Expected Audience",
      icon: PeopleEyeFilled,
      count: 0,
    },
    attendedAudience: {
      label: "Attended Audience",
      icon: PeopleAudienceFilled,
      count: 0,
    },
  },
};

export default statusData;
