import {
  Caption1,
  Card,
  CardHeader,
  Checkbox,
  Dropdown,
  DropdownProps,
  Field,
  Image,
  Input,
  makeStyles,
  Option,
  OptionGroup,
  Radio,
  Select,
  shorthands,
  Text,
  tokens,
} from "@fluentui/react-components";
import { FC, useCallback, useEffect, useState } from "react";
import { countryCodes } from "../data/countryCodeData";

const InputNameStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingVerticalM,
    "& .fui-Input": { width: "100%" },
  },
});

interface InputNameProps {
  title: any;
  fname: any;
  lname: any;
}

export const InputName: FC<InputNameProps> = (props) => {
  const InputNameClasses = InputNameStyles();

  return (
    <Field label={"Name"} required>
      <div className={InputNameClasses.root}>
        <Select onChange={(e: any) => props.title(e.target.value)}>
          <option value="Dr.">Dr.</option>
          <option value="Prof.">Prof.</option>
          <option value="Mr.">Mr.</option>
          <option value="Mrs.">Mrs.</option>
        </Select>
        <Input
          id="FName"
          onChange={(e: any) => props.fname(e.target.value)}
          placeholder="First Name"
        />
        <Input
          id="LName"
          onChange={(e: any) => props.lname(e.target.value)}
          placeholder="Last Name"
        />
      </div>
    </Field>
  );
};

interface PhoneInputProps {
  didValue: any;
  ccValue: any;
  ccState: any;
}

export const PhoneInput: FC<PhoneInputProps> = ({
  didValue,
  ccValue,
  ccState,
}) => {
  const [open, setOpen] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [ipCount, setIpCount] = useState(8);
  const [country, setCountry] = useState(countryCodes[0].name);
  var foundCountry = countryCodes.find((cc) => cc.name === country);
  var phoneCode = foundCountry?.phoneCode;

  const handleCC = (e: any) => {
    ccState(e.target.value);
  };

  useEffect(() => {
    ccState(phoneCode && `+${phoneCode[0]}`);
  }, [country]);

  const handleOpenChange: DropdownProps["onOpenChange"] = (e: any, data: any) =>
    setOpen(data.open);

  const onActiveOptionChange = useCallback(
    (_: any, data: any) => {
      setCountry(data?.nextOption?.text);
    },
    [setCountry]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    didValue(newValue);
    setPhoneNo(newValue);
    setIpCount(
      foundCountry?.limit ? foundCountry?.limit - e.target.value.length : 0
    );
  };

  return (
    <Field
      label="Phone Number"
      required
      validationState={
        !foundCountry?.limit ? "warning" : ipCount > 0 ? "error" : "success"
      }
      validationMessage={
        !foundCountry?.limit
          ? "No standard character limit. Please make sure required values are entered."
          : ipCount > 0
          ? `${ipCount} numbers remaining`
          : "All set"
      }
    >
      <div
        style={{
          display: "flex",
          gap: tokens.spacingHorizontalL,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: tokens.spacingHorizontalS,
          }}
        >
          <span
            className="twemoji"
            onClick={() => setOpen(!open)}
            style={{
              display: "flex",
              alignItems: "center",
              padding: `0 ${tokens.spacingHorizontalL}`,
              fontSize: tokens.fontSizeBase600,
              backgroundColor: tokens.colorNeutralBackground1,
              border: `${tokens.colorNeutralBackground1Hover} solid ${tokens.strokeWidthThin}`,
              borderRadius: tokens.borderRadiusMedium,
            }}
          >
            {foundCountry?.flag}
          </span>
          <Dropdown
            open={open}
            defaultValue={country}
            placeholder="Select Country"
            onOpenChange={handleOpenChange}
            onActiveOptionChange={onActiveOptionChange}
          >
            <OptionGroup>
              <Option
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
                text={countryCodes[0].name}
                value={countryCodes[0].iso.three}
              >
                <span className="twemoji">{countryCodes[0].flag}</span>
                {countryCodes[0].name}
              </Option>
            </OptionGroup>
            <OptionGroup label="GCC Countries">
              {countryCodes.slice(1, 6).map((cc) => (
                <Option
                  value={cc.iso.three}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                  text={cc.name}
                >
                  <span className="twemoji">{cc.flag}</span>
                  {cc.name}
                </Option>
              ))}
            </OptionGroup>
            <OptionGroup label="All Countries">
              {countryCodes.slice(6).map((cc) => (
                <Option
                  value={cc.iso.three}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                  text={cc.name}
                >
                  <span className="twemoji">{cc.flag}</span>
                  {cc.name}
                </Option>
              ))}
            </OptionGroup>
          </Dropdown>
        </div>

        <Input
          id="phoneDid"
          contentBefore={
            <Select
              appearance="underline"
              value={ccValue}
              onChange={handleCC}
              disabled={
                phoneCode?.length
                  ? phoneCode?.length > 1
                    ? false
                    : true
                  : true
              }
            >
              {phoneCode?.map((cc) => (
                <option>+{cc}</option>
              ))}
            </Select>
          }
          placeholder="Enter Number"
          onChange={handleInputChange}
          value={phoneNo}
          maxLength={foundCountry?.limit}
        />
      </div>
    </Field>
  );
};

interface InputMailerProps {
  mailValue?: any;
}

export const InputMailer: FC<InputMailerProps> = ({ mailValue }) => {
  const InputClasses = InputNameStyles();
  return (
    <Field label={"E Mail Address"} required>
      <div>
        <Input
          id="audienceMail"
          onChange={(e: any) => mailValue(e.target.value)}
          type="email"
          placeholder="example@domain.com"
          style={{ width: "100%" }}
          // contentAfter={
          //   <div className={InputClasses.root}>
          //     <Label>@</Label>
          //     {/* <Select appearance="underline">
          //       <option>gmail.com</option>
          //     </Select> */}
          //     <Input appearance="underline" placeholder="example.com" />
          //   </div>
          // }
        />
      </div>
    </Field>
  );
};

interface InputWorkplaceProps {
  wState: any;
  jtState: any;
}

export const InputWorkplace: FC<InputWorkplaceProps> = ({
  wState,
  jtState,
}) => {
  const InputClasses = InputNameStyles();
  return (
    <Field label="Work Info" required>
      <div className={InputClasses.root}>
        <Input
          id="wp"
          onChange={(e: any) => wState(e.target.value)}
          placeholder="Workplace"
        />
        <Input
          id="job"
          onChange={(e: any) => jtState(e.target.value)}
          placeholder="Job Title"
        />
      </div>
    </Field>
  );
};

interface SideEventCheckCardsProps {
  name: string;
  fee: any;
  se: any;
  index: any;
  changedTo: any;
}

export const SideEventCheckCards: FC<SideEventCheckCardsProps> = ({
  se,
  fee,
  name,
  index,
  changedTo,
}) => {
  const InputClasses = InputNameStyles();
  return (
    <label htmlFor={index} key={index} style={{ cursor: "pointer" }}>
      <Card>
        <CardHeader
          header={
            <div className={InputClasses.root}>
              <Checkbox
                id={index}
                value={se.title}
                shape="circular"
                onChange={changedTo}
              />
              <div>
                <Text weight="semibold" block>
                  {name}
                </Text>
                <Caption1>{`${se.fee.amount > 0 ? se.fee.amount : "Free"} ${
                  se.fee.amount > 0 ? se.fee.currency : ""
                }`}</Caption1>
              </div>
            </div>
          }
        />
      </Card>
    </label>
  );
};

const payModeStyles = makeStyles({
  root: {
    display: "grid",
    borderRadius: "20px",
    alignItems: "center",
    gridTemplateColumns: "auto 1fr 3rem",
    boxShadow: tokens.shadow2,
    gridGap: tokens.spacingHorizontalL,
    backgroundColor: tokens.colorNeutralBackground1,
    cursor: "pointer",
  },
  image: {
    height: "3rem",
    aspectRatio: "16/9",
    objectFit: "cover",
    objectPosition: "left",
    borderRadius: "20px 0 0 20px",
  },
});

interface PaymentModeProps {
  data: any;
}

export const PaymentMode: FC<PaymentModeProps> = ({ data }) => {
  const payModeClasses = payModeStyles();
  const PayIcon = data.icon;

  return (
    <label htmlFor={data.label} className={payModeClasses.root}>
      {/* <Image className={payModeClasses.image} src={data.image} /> */}
      <div
        style={{
          width: "4rem",
          height: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PayIcon />
      </div>
      <Text weight="bold">{data.label}</Text>
      <Radio value={data.id} id={data.label} />
    </label>
  );
};
