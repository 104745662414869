import {
  Card,
  CardHeader,
  makeStyles,
  Text,
  Title3,
  tokens,
} from "@fluentui/react-components";
import { FC } from "react";

export interface ContentProps {
  title: string;
  icon: any;
  count: any;
  color?: any;
  bigIcon?: boolean;
  statusIcon?: any;
  statusColor?: any;
}

const cardStyles = makeStyles({
  root: { borderRadius: "20px", backgroundColor:tokens.colorNeutralBackground2 },
  headerContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingHorizontalS,
    padding: tokens.spacingHorizontalXXXL,
  },

  cardCounter: {
    lineHeight: 0,
    fontFamily: "Cascadia Mono",
    fontSize: tokens.fontSizeHero800,
    fontWeight: tokens.fontWeightBold,
  },
});

const StatusCard: FC<ContentProps> = ({
  title,
  icon,
  count = 0,
  color,
  bigIcon,
  statusIcon,
  statusColor,
}) => {
  const cardClasses = cardStyles();
  const CardIcon = icon;
  var StatusIcon = statusIcon;

  return (
    <Card className={cardClasses.root}>
      <CardHeader
        header={
          <div className={cardClasses.headerContainer}>
            <CardIcon
              style={{
                color: color ? color : "inherit",
                fontFamily: tokens.fontFamilyBase,
                fontSize: bigIcon
                  ? tokens.fontSizeHero900
                  : tokens.fontSizeBase600,
                fontWeight: tokens.fontWeightSemibold,
                lineHeight: tokens.lineHeightBase600,
              }}
            />
            <Title3>{title}</Title3>
            {statusIcon && (
              <StatusIcon
                style={{
                  marginLeft: "auto",
                  fontSize: tokens.fontSizeBase500,
                  fontFamily: tokens.fontFamilyBase,
                  lineHeight: tokens.lineHeightBase600,
                  fontWeight: tokens.fontWeightSemibold,
                  color: statusColor ? statusColor : color ? color : "inherit",
                }}
              />
            )}
            <Text
              style={{
                color: statusColor ? statusColor : color ? color : "inherit",
                marginLeft: statusIcon ? "0" : "auto",
                fontSize: statusIcon
                  ? tokens.fontSizeBase600
                  : tokens.fontSizeHero800,
              }}
              className={cardClasses.cardCounter}
            >
              {count}
            </Text>
          </div>
        }
      />
    </Card>
  );
};

export default StatusCard;
