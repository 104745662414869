import {
  Button,
  Caption2,
  Field,
  Input,
  Link,
  makeStyles,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  ProgressBar,
  Select,
  TableCellActions,
  TableCellLayout,
  Text,
  tokens,
  Tooltip,
} from "@fluentui/react-components";

import {
  AppsListDetailFilled,
  ArrowEnterFilled,
  CalendarRegular,
  CertificateFilled,
  ClipboardPasteFilled,
  ClockRegular,
  EditFilled,
  EyeFilled,
  EyeOffFilled,
  FormFilled,
  GlobeFilled,
  LinkFilled,
  LocationRegular,
  MoneyFilled,
  MoreHorizontalFilled,
  OpenFilled,
  PeopleRegular,
  QrCodeFilled,
  RibbonFilled,
  ToolboxFilled,
} from "@fluentui/react-icons";
import { AppsvenueEvent } from "../data/eventData";
import { FC, useState } from "react";
import { DatePicker } from "@fluentui/react-datepicker-compat";

import { statusData } from "../data";
import { editEventName } from "../utils/event";
import { useNavigate } from "react-router-dom";

const datagridCellStyles = makeStyles({
  name_en: {
    fontWeight: tokens.fontWeightSemibold,
  },
  name_ar: {
    display: "block",
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralForeground3,
  },
  popoverSurface: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalS,
  },
  nameIO: {
    width: "20rem",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingHorizontalS,
  },
  button: {
    width: "100%",
  },
  feeSwitchContainer: {
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingHorizontalXS,
  },
  durationContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: tokens.spacingVerticalXS,
    padding: `${tokens.spacingVerticalS} 0`,
  },
  durationRowContainer: {
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingHorizontalS,
  },
  durationEditFormContainer: {
    display: "flex",
    gap: tokens.spacingHorizontalM,
  },
  durationEditField: {
    width: "50%",
  },
  locationText: {
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingHorizontalS,
  },
  link: {
    color: tokens.colorBrandForegroundInverted,
    fontWeight: tokens.fontWeightSemibold,
  },
  iconSize: {
    fontSize: tokens.fontSizeBase500,
  },
  vEditForm: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalL,
  },
  audienceLabelGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 0.25rem 1.5rem",
    gridGap: tokens.spacingHorizontalS,
  },
  textEnd: {
    textAlign: "end",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  actionContainer: {
    display: "flex",
    gap: tokens.spacingHorizontalS,
  },
});

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export interface CellProp {
  cellData: AppsvenueEvent;
}

const EventNameCell: FC<CellProp> = ({ cellData }) => {
  const datagirdCellClasses = datagridCellStyles();

  const [en, setEn] = useState(cellData.name.en ? cellData.name.en : "");
  const [ar, setAr] = useState(cellData.name.ar ? cellData.name.ar : "");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "en") {
      setEn(value);
    } else if (name === "ar") setAr(value);
  };

  const onEventNameSubmit = async () => {
    const response = await editEventName(en, ar, cellData._id);
    console.log("Edited Name\n", response);
  };

  return (
    <Tooltip
      relationship="label"
      content={
        <>
          <p>{cellData.name.en}</p>
          {cellData.name.ar && <p>{cellData.name.ar}</p>}
        </>
      }
    >
      <TableCellLayout truncate>
        <span className={datagirdCellClasses.name_en}>{cellData.name.en}</span>
        {cellData.name.ar && (
          <span className={datagirdCellClasses.name_ar}>
            {cellData.name.ar}
          </span>
        )}
        <TableCellActions>
          <Popover>
            <PopoverTrigger disableButtonEnhancement>
              <Button
                appearance="subtle"
                shape="circular"
                icon={<EditFilled />}
              />
            </PopoverTrigger>
            <PopoverSurface className={datagirdCellClasses.popoverSurface}>
              <Field
                label="Edit Event Name"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: tokens.spacingVerticalS,
                }}
              >
                <Input
                  name="en"
                  placeholder="Edit Event Name"
                  value={en}
                  onChange={handleChange}
                  defaultValue={cellData.name.en}
                  className={datagirdCellClasses.nameIO}
                  required
                />
                <Input
                  name="ar"
                  placeholder="أدخل اسم الحدث"
                  value={ar}
                  onChange={handleChange}
                  style={{ direction: "rtl" }}
                  defaultValue={cellData.name.ar}
                  className={datagirdCellClasses.nameIO}
                />
              </Field>
              <div className={datagirdCellClasses.buttonContainer}>
                <Button
                  appearance="primary"
                  onClick={onEventNameSubmit}
                  className={datagirdCellClasses.button}
                >
                  Save
                </Button>
                <Caption2 align="center" block>
                  Click outside to close this popup
                </Caption2>
              </div>
            </PopoverSurface>
          </Popover>
        </TableCellActions>
      </TableCellLayout>
    </Tooltip>
  );
};

const EventPrefixCell: FC<CellProp> = ({ cellData }) => {
  return <TableCellLayout>{cellData.prefix}</TableCellLayout>;
};

const EventDescriptionCell: FC<CellProp> = ({ cellData }) => {
  return (
    <Tooltip relationship="label" content={cellData.description}>
      <TableCellLayout truncate>{cellData?.description}</TableCellLayout>
    </Tooltip>
  );
};

const EventFeesCell: FC<CellProp> = ({ cellData }) => {
  const datagirdCellClasses = datagridCellStyles();
  return (
    <TableCellLayout>
      {cellData.fee && cellData.fee.amount > 0
        ? cellData.fee?.amount + " KWD"
        : "Free"}
      <TableCellActions>
        <Popover>
          <PopoverTrigger disableButtonEnhancement>
            <Tooltip relationship="label" content="Edit">
              <Button
                icon={<EditFilled />}
                shape="circular"
                appearance="subtle"
              />
            </Tooltip>
          </PopoverTrigger>
          <PopoverSurface className={datagirdCellClasses.popoverSurface}>
            <Field label="Update Fees"></Field>
            <Field>
              <Input
                defaultValue={cellData.fee?.amount.toString()}
                placeholder="Enter Amount"
                contentBefore={<MoneyFilled />}
                contentAfter={
                  <Select appearance="underline">
                    <option>KWD</option>
                    <option>USD</option>
                  </Select>
                }
              />
            </Field>
            <div className={datagirdCellClasses.buttonContainer}>
              <Button
                className={datagirdCellClasses.button}
                appearance="primary"
              >
                Save
              </Button>
              <Caption2 align="center" block>
                Click outside to close this popup
              </Caption2>
            </div>
          </PopoverSurface>
        </Popover>
      </TableCellActions>
    </TableCellLayout>
  );
};

const EventDurationStartCell: FC<CellProp> = ({ cellData }) => {
  const datagirdCellClasses = datagridCellStyles();
  return (
    <TableCellLayout truncate>
      <div className={datagirdCellClasses.durationContainer}>
        <div className={datagirdCellClasses.durationRowContainer}>
          <CalendarRegular style={{ fontSize: tokens.fontSizeBase500 }} />
          <Text size={300} font="numeric" style={{ width: "5rem" }}>
            {cellData.event_duration.start.substring(8, 10)}{" "}
            {month[parseInt(cellData.event_duration.start.substring(5, 7)) - 1]}
            {", "}
            {cellData.event_duration.start.substring(0, 4)}
          </Text>
        </div>
        <div className={datagirdCellClasses.durationRowContainer}>
          <ClockRegular style={{ fontSize: tokens.fontSizeBase500 }} />
          <Text size={300} font="numeric">
            {parseInt(cellData.event_duration.start.substring(11, 13)) > 12
              ? parseInt(cellData.event_duration.start.substring(11, 13)) - 12 <
                10
                ? "0" +
                  (
                    parseInt(cellData.event_duration.start.substring(11, 13)) -
                    12
                  ).toString()
                : parseInt(cellData.event_duration.start.substring(11, 13)) - 12
              : cellData.event_duration.start.substring(11, 13)}
            {" : "}
            {cellData.event_duration.start.substring(14, 16)}{" "}
            {parseInt(cellData.event_duration.start.substring(11, 13)) > 12
              ? "PM"
              : "AM"}
          </Text>
        </div>
      </div>
      <TableCellActions>
        <Popover>
          <PopoverTrigger disableButtonEnhancement>
            <Tooltip relationship="label" content="Edit">
              <Button
                icon={<EditFilled />}
                appearance="subtle"
                shape="circular"
              />
            </Tooltip>
          </PopoverTrigger>
          <PopoverSurface className={datagirdCellClasses.popoverSurface}>
            <Field label="Modify Start Date">
              <div className={datagirdCellClasses.durationEditFormContainer}>
                <DatePicker
                  placeholder="Select Date"
                  className={datagirdCellClasses.durationEditField}
                />
                <Input type="time" style={{ width: "100%" }} />
              </div>
            </Field>
            <div className={datagirdCellClasses.buttonContainer}>
              <Button
                className={datagirdCellClasses.button}
                appearance="primary"
              >
                Save
              </Button>
              <Caption2 align="center" block>
                Click outside to close this popup
              </Caption2>
            </div>
          </PopoverSurface>
        </Popover>
      </TableCellActions>
    </TableCellLayout>
  );
};

const EventDurationEndCell: FC<CellProp> = ({ cellData }) => {
  const datagirdCellClasses = datagridCellStyles();
  return (
    <TableCellLayout truncate>
      <div className={datagirdCellClasses.durationContainer}>
        <div className={datagirdCellClasses.durationRowContainer}>
          <CalendarRegular style={{ fontSize: tokens.fontSizeBase500 }} />
          <Text size={300} font="numeric" style={{ width: "5rem" }}>
            {cellData.event_duration.end.substring(8, 10)}{" "}
            {month[parseInt(cellData.event_duration.end.substring(5, 7)) - 1]}
            {", "}
            {cellData.event_duration.end.substring(0, 4)}
          </Text>
        </div>
        <div className={datagirdCellClasses.durationRowContainer}>
          <ClockRegular style={{ fontSize: tokens.fontSizeBase500 }} />
          <Text size={300} font="numeric">
            {parseInt(cellData.event_duration.end.substring(11, 13)) > 12
              ? parseInt(cellData.event_duration.end.substring(11, 13)) - 12 <
                10
                ? "0" +
                  (
                    parseInt(cellData.event_duration.end.substring(11, 13)) - 12
                  ).toString()
                : parseInt(cellData.event_duration.end.substring(11, 13)) - 12
              : cellData.event_duration.end.substring(11, 13)}
            {" : "}
            {cellData.event_duration.end.substring(14, 16)}{" "}
            {parseInt(cellData.event_duration.end.substring(11, 13)) > 12
              ? "PM"
              : "AM"}
          </Text>
        </div>
      </div>
      <TableCellActions>
        <Popover>
          <PopoverTrigger disableButtonEnhancement>
            <Tooltip relationship="label" content="Edit">
              <Button
                icon={<EditFilled />}
                appearance="subtle"
                shape="circular"
              />
            </Tooltip>
          </PopoverTrigger>
          <PopoverSurface className={datagirdCellClasses.popoverSurface}>
            <Field label="Modify End Date">
              <div className={datagirdCellClasses.durationEditFormContainer}>
                <DatePicker
                  placeholder="Select Date"
                  className={datagirdCellClasses.durationEditField}
                />
                <Input type="time" style={{ width: "100%" }} />
              </div>
            </Field>
            <div className={datagirdCellClasses.buttonContainer}>
              <Button
                className={datagirdCellClasses.button}
                appearance="primary"
              >
                Save
              </Button>
              <Caption2 align="center" block>
                Click outside to close this popup
              </Caption2>
            </div>
          </PopoverSurface>
        </Popover>
      </TableCellActions>
    </TableCellLayout>
  );
};

const EventVenueCell: FC<CellProp> = ({ cellData }) => {
  const datagirdCellClasses = datagridCellStyles();
  const [vLocation, setVLocation] = useState(cellData.venue.location);
  const handleVLocChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVLocation(event.target.value);
  };
  const pasteText = async () => {
    try {
      setVLocation(await navigator.clipboard.readText());
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  };
  return (
    <TableCellLayout truncate>
      {cellData.venue.location ? (
        <Link
          href={cellData.venue.location}
          target="_blank"
          className={mergeClasses(datagirdCellClasses.locationText)}
        >
          <LocationRegular className={datagirdCellClasses.iconSize} />

          {cellData.venue.name}
        </Link>
      ) : (
        <div className={datagirdCellClasses.locationText}>
          <LocationRegular className={datagirdCellClasses.iconSize} />
          {cellData.venue.name}
        </div>
      )}
      <TableCellActions>
        <Popover>
          <PopoverTrigger>
            <Tooltip relationship="label" content="Edit">
              <Button
                icon={<EditFilled />}
                appearance="subtle"
                shape="circular"
              />
            </Tooltip>
          </PopoverTrigger>
          <PopoverSurface className={datagirdCellClasses.popoverSurface}>
            <Field label="Modify Venue Informations">
              <div className={datagirdCellClasses.vEditForm}>
                <Input
                  placeholder="Enter Venue Name"
                  defaultValue={cellData.venue.name}
                />
                <Input
                  type="url"
                  onChange={handleVLocChanges}
                  value={vLocation}
                  contentBefore={<LinkFilled />}
                  contentAfter={
                    <Button
                      appearance="transparent"
                      onClick={pasteText}
                      icon={<ClipboardPasteFilled />}
                    />
                  }
                  placeholder="Enter Venue Location"
                />
              </div>
            </Field>
            <div className={datagirdCellClasses.buttonContainer}>
              <Button
                className={datagirdCellClasses.button}
                appearance="primary"
              >
                Save
              </Button>
              <Caption2 align="center" block>
                Click outside to close this popup
              </Caption2>
            </div>
          </PopoverSurface>
        </Popover>
      </TableCellActions>
    </TableCellLayout>
  );
};

const EventAudienceCell: FC<CellProp> = ({ cellData }) => {
  const datagirdCellClasses = datagridCellStyles();
  return (
    <TableCellLayout>
      <Tooltip
        positioning="after"
        relationship="label"
        content={
          <div className={datagirdCellClasses.audienceLabelGrid}>
            <b>Attendees</b>
            <b>:</b>
            <span className={datagirdCellClasses.textEnd}>
              {cellData.audience.attended}
            </span>

            <b>Registered</b>
            <b>:</b>
            <span className={datagirdCellClasses.textEnd}>
              {cellData.audience.registered}
            </span>
          </div>
        }
      >
        <Field
          label={
            <>
              <PeopleRegular className={datagirdCellClasses.iconSize} />
            </>
          }
          hint={
            <p className={datagirdCellClasses.textEnd}>
              {cellData.audience.attended +
                " / " +
                cellData.audience.registered}
            </p>
          }
        >
          <ProgressBar
            bar={cellData.audience.registered}
            max={
              cellData.audience.registered === 0
                ? 1
                : cellData.audience.registered
            }
            value={cellData.audience.attended}
            thickness="large"
            shape="rounded"
            style={{ width: "5rem" }}
          />
        </Field>
      </Tooltip>
    </TableCellLayout>
  );
};

const EventMailSenderCell: FC<CellProp> = ({ cellData }) => {
  const datagirdCellClasses = datagridCellStyles();
  const [showPass, setShowPass] = useState(false);
  const togglePasswordView = () => {
    setShowPass(!showPass);
  };
  return (
    <TableCellLayout>
      {cellData.mail_sender.user}
      <TableCellActions>
        <Popover>
          <PopoverTrigger disableButtonEnhancement>
            <Tooltip relationship="label" content="Edit">
              <Button
                icon={<EditFilled />}
                appearance="subtle"
                shape="circular"
              />
            </Tooltip>
          </PopoverTrigger>
          <PopoverSurface className={datagirdCellClasses.popoverSurface}>
            <Field label="Modify Mail Information">
              <div className={datagirdCellClasses.vEditForm}>
                <Input
                  size="large"
                  type="email"
                  placeholder="Enter Mail ID"
                  defaultValue={cellData.mail_sender.user}
                />
                <Input
                  size="large"
                  type={showPass ? "text" : "password"}
                  placeholder="Enter Password"
                  contentAfter={
                    <Tooltip
                      relationship="label"
                      content={showPass ? "Hide Password" : "Show Password"}
                    >
                      <Button
                        appearance="transparent"
                        icon={showPass ? <EyeFilled /> : <EyeOffFilled />}
                        onClick={togglePasswordView}
                      />
                    </Tooltip>
                  }
                />
              </div>
            </Field>
            <div className={datagirdCellClasses.buttonContainer}>
              <Button
                className={datagirdCellClasses.button}
                appearance="primary"
              >
                Save
              </Button>
              <Caption2 align="center" block>
                Click outside to close this popup
              </Caption2>
            </div>
          </PopoverSurface>
        </Popover>
      </TableCellActions>
    </TableCellLayout>
  );
};

const EventStatusCell: FC<CellProp> = ({ cellData }) => {
  const [statusValMarked, setstatusValMarked] = useState<
    Record<string, string[]>
  >({ status: [cellData.status.toString()] });

  const onChange: MenuProps["onCheckedValueChange"] = (
    e,
    { name, checkedItems }
  ) => {
    setstatusValMarked((s) => ({ ...s, [name]: checkedItems }));
  };
  const datagirdCellClasses = datagridCellStyles();

  return (
    <TableCellLayout className={datagirdCellClasses.justifyCenter}>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <div>
            {statusData
              .filter((stats) => stats.status === cellData.status)
              .map((stat) => {
                const Icon = stat.icon;
                return (
                  <Tooltip
                    positioning="below"
                    relationship="label"
                    content={stat.label}
                  >
                    <Icon
                      className={datagirdCellClasses.iconSize}
                      style={{
                        color: stat.color,
                      }}
                    />
                  </Tooltip>
                );
              })}
          </div>
        </MenuTrigger>
        <MenuPopover>
          <MenuList
            checkedValues={statusValMarked}
            onCheckedValueChange={onChange}
          >
            {statusData.map((stat) => {
              const Icon = stat.icon;

              return (
                <MenuItemRadio
                  name="status"
                  icon={<Icon style={{ color: stat.color }} />}
                  value={stat.status.toString()}
                >
                  {stat.label}
                </MenuItemRadio>
              );
            })}
          </MenuList>
        </MenuPopover>
      </Menu>
    </TableCellLayout>
  );
};

const EventActionCell: FC<CellProp> = ({ cellData }) => {
  const datagirdCellClasses = datagridCellStyles();
  const navigate = useNavigate();
  return (
    <TableCellLayout>
      <div className={datagirdCellClasses.actionContainer}>
        <Tooltip relationship="label" content="Registration Link">
          <Button icon={<OpenFilled />} />
        </Tooltip>
        <Tooltip relationship="label" content="Registration QR Link">
          <Button icon={<QrCodeFilled />} />
        </Tooltip>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Tooltip relationship="label" content="More">
              <Button
                appearance="transparent"
                icon={<MoreHorizontalFilled />}
              />
            </Tooltip>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {cellData.website && (
                <MenuItem
                  onClick={() =>
                    window.open(
                      cellData.website,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  icon={<GlobeFilled />}
                >
                  Website
                </MenuItem>
              )}
              {cellData.side_events && (
                <MenuItem icon={<AppsListDetailFilled />}>Side Events</MenuItem>
              )}
              {(cellData.website || cellData.side_events) && <MenuDivider />}
              <MenuGroup>
                <MenuGroupHeader>Editor</MenuGroupHeader>
                <MenuItem
                  onClick={() => navigate("/Dashboard/Editor")}
                  icon={<FormFilled />}
                >
                  Registration Form
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/Dashboard/Editor/CME")}
                  icon={<CertificateFilled />}
                >
                  CME Certificate
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/Dashboard/Editor/Badge")}
                  icon={<RibbonFilled />}
                >
                  Badge
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>Check Ins</MenuGroupHeader>
                <MenuItem
                  onClick={() => navigate("/Dashboard/Checkin")}
                  icon={<ArrowEnterFilled />}
                >
                  Main
                </MenuItem>
                {/* <MenuItem icon={<GroupListFilled />}>Sessions</MenuItem> */}
                <MenuItem
                  onClick={() => navigate("/Dashboard/CheckIn/Workshop")}
                  icon={<ToolboxFilled />}
                >
                  Workshops
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </TableCellLayout>
  );
};

const DatagridCell = () => {
  return true;
};

DatagridCell.Name = EventNameCell;
DatagridCell.Prefix = EventPrefixCell;
DatagridCell.Description = EventDescriptionCell;
DatagridCell.Fees = EventFeesCell;
DatagridCell.DurationStart = EventDurationStartCell;
DatagridCell.DurationEnd = EventDurationEndCell;
DatagridCell.Venue = EventVenueCell;
DatagridCell.Audience = EventAudienceCell;
DatagridCell.MailSender = EventMailSenderCell;
DatagridCell.Status = EventStatusCell;
DatagridCell.Action = EventActionCell;

export default DatagridCell;
