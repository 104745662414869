import {
  FluentProvider,
  makeStaticStyles,
  tokens,
} from "@fluentui/react-components";
import { Route, Routes } from "react-router-dom";
import {
  AudienceRegistration,
  Dashboard,
  Error,
  FreeSuccess,
  Login,
  Success,
} from "./pages";
import { CollapseProvider } from "./context";
import { EventForm } from "./components";
import { useTheme } from "./context/ThemeProvider";
import ErrorPage from "./pages/ErrorPage";

const baseStructure = makeStaticStyles({
  "*": {
    margin: 0,
    padding: 0,
    userSelect: "none",
  },
  "@font-face": {
    fontFamily: "Twemoji",
    src: "url(/assets/fonts/twemoji.ttf)",
  },
  ".twemoji": {
    fontFamily: "Twemoji",
  },
  body: {
    background: tokens.colorNeutralBackground5,
    padding:
      "env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px)",
  },

  "::selection": {
    background: tokens.colorBrandBackground2,
  },

  "::-webkit-scrollbar": {
    width: "0.5rem",
  },
  "::-webkit-scrollbar-track": {
    background: tokens.colorNeutralBackground5,
  },
  "::-webkit-scrollbar-thumb": {
    background: tokens.colorBrandBackground,
    border: `${tokens.strokeWidthThick} solid ${tokens.colorBrandBackground}`,
    transition: "background 0.3s",
    borderRadius: "5rem",
  },
  "::-webkit-scrollbar-thumb:hover": {
    background: tokens.colorBrandBackgroundStatic,
    border: `${tokens.strokeWidthThick} solid ${tokens.colorBrandBackgroundStatic}`,
  },
  ".sketch-picker": {
    background: "transparent !important",
    boxShadow: "none !important",
  },
  ".sketch-picker div div div label": {
    color: `${tokens.colorNeutralForeground1} !important`,
  },
});

const App = () => {
  const { theme } = useTheme();
  baseStructure();

  return (
    <FluentProvider theme={theme}>
      <CollapseProvider>
        <Routes>
          <Route index element={<Login />} />
          <Route path="Dashboard" element={<Dashboard />}>
            <Route index element={<Dashboard.Home />} />
            <Route path="EventCards" element={<Dashboard.Events />} />
            <Route path="EventList" element={<Dashboard.AdvancedEvents />} />
            <Route path="Settings" element={<Dashboard.Settings />} />
            <Route path="AudienceCards" element={<Dashboard.AudienceCards />} />
            <Route path="EventForm" element={<EventForm />} />
            <Route path="Editor" element={<Dashboard.Editor />}>
              {/* <Route index element={<Dashboard.Editor.Registration />} />
              <Route path="CME" element={<Dashboard.Editor.MainEditor />} /> */}
              <Route index element={<Dashboard.Editor.MainEditor />} />

              <Route
                path="Badge"
                element={<Dashboard.Editor.MainEditor badge />}
              />
            </Route>
            <Route path="CheckIn" element={<Dashboard.CheckIn />}>
              <Route index element={<Dashboard.CheckIn.Main />} />
              <Route path="Session" element={<Dashboard.CheckIn.Session />} />
              <Route path="Workshop" element={<Dashboard.CheckIn.Workshop />} />
            </Route>
            <Route path="Mailing" element={<Dashboard.Mailing />} />
            <Route path="SMS" element={<Dashboard.SMS />} />
          </Route>
          <Route path="/registration/:id" element={<AudienceRegistration />} />
          <Route path="/success" element={<Success />} />
          <Route path="/successful" element={<FreeSuccess />} />
          <Route path="/failed" element={<Error />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </CollapseProvider>
    </FluentProvider>
  );
};

export default App;
