import { Display } from "@fluentui/react-components";
import { useEffect } from "react";

const Settings = () => {
  useEffect(() => {
    document.title = "Appsvenue Events · Settings";
  }, []);
  return (
    <div>
      <Display>Settings</Display>
    </div>
  );
};

export default Settings;
