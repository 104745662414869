import { Button, makeStyles, tokens } from "@fluentui/react-components";
import { DeleteFilled } from "@fluentui/react-icons";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";

const inputFileStyle = makeStyles({
  root: {
    display: "flex",
    gap: tokens.spacingHorizontalS,
  },
  imageContainer: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: tokens.borderRadiusXLarge,
  },
  shadowInput: {
    opacity: 0,
    width: "100%",
    height: "100%",
  },
});

export interface ContentProps {
  dimension?: "banner" | "logo" | "upload";
  outline?: boolean;
  setInput?: any;
}

const InputFile: FC<ContentProps> = ({ dimension, outline, setInput }) => {
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputFileClass = inputFileStyle();

  const placeholderImg =
    dimension === "banner"
      ? "/assets/images/bannerImg.jpeg"
      : dimension === "logo"
      ? "/assets/images/logoImg.png"
      : dimension === "upload"
      ? "/assets/images/images.png"
      : "/assets/images/logoImg.png";

  useEffect(() => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  }, [file]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    setInput(selectedFile);
  };

  const resetFileChange = () => {
    setFile(null);
    setPreviewUrl(null);
    if (inputRef.current) inputRef.current.value = "";
  };
  return (
    <div className={inputFileClass.root}>
      <div
        className={inputFileClass.imageContainer}
        style={{
          outline: outline
            ? `${tokens.colorNeutralForeground1} dashed 0.1rem`
            : "none",
          height:
            dimension === "banner"
              ? "15rem"
              : dimension === "logo"
              ? "15rem"
              : dimension === "upload"
              ? "10rem"
              : "15rem",
          backgroundImage: `url(${previewUrl || placeholderImg})`,
          aspectRatio:
            dimension === "banner"
              ? "4/1"
              : dimension === "logo"
              ? "1/1"
              : dimension === "upload"
              ? "4/3"
              : "1/1",
        }}
      >
        <input
          type="file"
          ref={inputRef}
          className={inputFileClass.shadowInput}
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      {previewUrl && (
        <Button
          appearance="transparent"
          icon={<DeleteFilled color={tokens.colorPaletteRedForeground1} />}
          onClick={resetFileChange}
        />
      )}
    </div>
  );
};

export default InputFile;
