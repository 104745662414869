import {
  Button,
  makeStyles,
  Text,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import { NavigationRegular, SignOutFilled } from "@fluentui/react-icons";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export interface ContentProps {
  classProps: any;
  menuButtonAction: any;
}

const headerDesign = makeStyles({
  button: {
    color: tokens.colorNeutralForegroundStaticInverted,
  },
  signout: {
    backgroundColor: tokens.colorPaletteRedBackground3,
    border: tokens.colorPaletteRedForeground1,
    color: tokens.colorBrandBackgroundInverted,
    marginLeft: "auto",
  },
});

const Header: FC<ContentProps> = (props) => {
  const headerClass = headerDesign();
  const navigate = useNavigate();

  return (
    <header className={props.classProps}>
      <Button
        appearance="subtle"
        size="large"
        onClick={props.menuButtonAction}
        className={headerClass.button}
        icon={<NavigationRegular />}
      />
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => navigate("")}
      >
        <img
          src="/assets/images/appsvenueLogo.svg"
          style={{ height: "3rem" }}
          alt="svg"
        />
        <Text
          style={{ fontSize: tokens.fontSizeBase600, margin: "0 0 0.25rem 0" }}
        >
          Events
        </Text>
      </div>
      <Tooltip content="Sign Out" relationship="label">
        <Button
          icon={<SignOutFilled />}
          className={headerClass.signout}
          onClick={() => navigate("/")}
        />
      </Tooltip>
    </header>
  );
};

export default Header;
