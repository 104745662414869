import {
  Button,
  Checkbox,
  Field,
  Input,
  makeStyles,
  Title1,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../lottie/LoginIcon.json";
import { EyeFilled, EyeOffFilled } from "@fluentui/react-icons";
import axios from "axios";
import { loginResponse } from "../utils/auth";

const loginDesign = makeStyles({
  container: {
    background: "url(/assets/images/loginBG.svg) center/cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100dvh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: tokens.colorNeutralBackground2,
    padding: tokens.spacingVerticalXL,
    borderRadius: tokens.borderRadiusXLarge,
    width: "20rem",
    gap: tokens.spacingVerticalXL,
    boxShadow: tokens.shadow64,
  },
  title: {
    color: tokens.colorBrandForeground1,
    fontWeight: tokens.fontWeightSemibold,
  },
  passLabelCotnainer: {
    display: "flex",
  },
  forgetPassLink: {
    marginLeft: "auto",
    textDecoration: "none",
    color: tokens.colorBrandForeground1,
  },
});

const Login = () => {
  const loginClass = loginDesign();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const togglePasswordView = () => {
    setShowPass(!showPass);
  };

  const onLogin = async () => {
    try {
      const response = await loginResponse(username, password);
      console.log(response.status);

      switch (response.status) {
        case 200:
          const jsonToken = response.data.token;
          const token = JSON.parse(jsonToken).token;
          const utype = JSON.parse(jsonToken).utype;
          localStorage.setItem("token", token);
          localStorage.setItem("utype", utype);
          localStorage.setItem("uname", username);
          navigate("/Dashboard");
          break;
        case 401:
          alert(response.data?.userMsg);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        switch (error.response.status) {
          case 401:
            alert("Incorrect Username or Password");
            break;
          case 500:
            alert("Server Error, please try again later");
            break;
          default:
            alert(`An unexpected error occurred: ${error.response.status} `);
        }
      } else {
        alert("Network error or server is not responding");
      }
    }
  };

  const onEnterPress = async (e: any) => {
    if (e.key === "Enter") await onLogin();
  };

  useEffect(() => {
    document.title = "Appsvenue Events";
  }, []);

  return (
    <div className={loginClass.container}>
      <Lottie
        options={defaultOptions}
        width={"25rem"}
        height={"25rem"}
        style={{ margin: 0 }}
        isClickToPauseDisabled={true}
      />
      <div className={loginClass.form}>
        <Title1 className={loginClass.title} align="center">
          Login
        </Title1>
        <Field label="Username" size="large">
          <Input
            type="text"
            value={username}
            aria-label="Enter Username"
            placeholder="Enter Username"
            appearance="filled-lighter"
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={onEnterPress}
          />
        </Field>
        <Field
          label={
            <div className={loginClass.passLabelCotnainer}>
              <span>Password</span>
              <Link className={loginClass.forgetPassLink} to="">
                Forgot Password?
              </Link>
            </div>
          }
          size="large"
        >
          <Input
            type={showPass ? "text" : "password"}
            size="large"
            value={password}
            appearance="filled-lighter"
            aria-label="Enter Password"
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={onEnterPress}
            contentAfter={
              <Tooltip
                relationship="label"
                content={showPass ? "Hide Password" : "Show Password"}
              >
                <Button
                  appearance="transparent"
                  icon={showPass ? <EyeFilled /> : <EyeOffFilled />}
                  onClick={togglePasswordView}
                />
              </Tooltip>
            }
          />
        </Field>
        <Checkbox label="Remember Me" />
        <Button onClick={onLogin} appearance="primary" size="large">
          Sign In
        </Button>
      </div>
    </div>
  );
};

export default Login;
