import { createInstance } from "./api";

const instance = createInstance("/audience");

// POST

export const addAudience = async (
  audienceData: AudienceData,
  eventID: string,
  sideEventTitles: string[] = []
) => {
  const response = await instance.post("/add", {
    audienceData,
    eventID,
    sideEventTitles,
  });
  return response.data;
};
