import {
  Avatar,
  Button,
  CompoundButton,
  makeStyles,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import {
  SettingsFilled,
  SignOutFilled,
  WeatherMoonFilled,
  WeatherSunnyFilled,
} from "@fluentui/react-icons";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../context/ThemeProvider";

const userMenuStyles = makeStyles({
  root: {
    position: "relative",
    display: "inline-block",
    margin: "0",
  },

  rootCollapsed: {
    margin: "0 auto",
  },

  avatarCollapsedTooltipHeader: {
    display: "block",
    fontWeight: tokens.fontWeightRegular,
  },
  avatarTooltipSubtitle: {
    fontSize: tokens.fontSizeBase200,
  },
  avatarButton: {
    height: "4.5rem",
  },

  avatarButtonTexts: {
    color: tokens.colorNeutralForegroundStaticInverted,
  },

  userMenu: {
    display: "none",
    position: "absolute",
    flexDirection: "column",
    minWidth: "10rem",
    gap: tokens.spacingHorizontalS,
    padding: tokens.spacingHorizontalL,
    backgroundColor: tokens.colorNeutralBackground1,
    border: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralBackground1Hover}`,
    borderRadius: tokens.borderRadiusXLarge,
    boxShadow: tokens.shadow28,
    zIndex: 1,
    bottom: "0",
    left: "10rem",
  },
  userMenuOpen: {
    display: "block",
  },
  userMenuOpenCollapsed: {
    left: "3rem",
  },

  userMenuButton: {
    width: "100%",
    justifyContent: "start",
  },

  signOutButton: {
    color: tokens.colorPaletteRedForeground1,
    ":hover .fui-MenuItem__icon": {
      color: tokens.colorNeutralForegroundStaticInverted,
    },
    ":hover": {
      backgroundColor: tokens.colorPaletteRedBackground3,
      color: tokens.colorNeutralForegroundStaticInverted,
    },
  },
});

export interface ContentProps {
  sidenavStatus: boolean;
}

const UserMenu: FC<ContentProps> = ({ sidenavStatus }) => {
  const userMenuClasses = userMenuStyles();
  const [userMenuState, setUserMenuState] = useState<boolean>(false);
  const navigate = useNavigate();
  const userMenuRef = useRef<HTMLDivElement>(null);
  const { theme, toggleTheme } = useTheme();

  const themeData = {
    light: {
      icon: <WeatherSunnyFilled />,
      label: "Light",
    },
    dark: {
      icon: <WeatherMoonFilled />,
      label: "Dark",
    },
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      userMenuRef.current &&
      !userMenuRef.current.contains(event.target as Node)
    ) {
      setUserMenuState(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const signOutFromAccount = () => {
    navigate("/");
    localStorage.removeItem("token");
    localStorage.removeItem("uname");
    localStorage.removeItem("utype");
  };

  const toggleUserMenu = () => {
    setUserMenuState(!userMenuState);
  };

  return (
    <div
      className={mergeClasses(
        userMenuClasses.root,
        sidenavStatus && userMenuClasses.rootCollapsed
      )}
    >
      <Menu positioning="after-bottom" persistOnItemClick>
        <MenuTrigger disableButtonEnhancement>
          {sidenavStatus ? (
            <Button
              className={userMenuClasses.avatarButton}
              onClick={toggleUserMenu}
              appearance="transparent"
              icon={
                <Avatar
                  name={localStorage.getItem("uname")?.toString()}
                  size={28}
                />
              }
            />
          ) : (
            <CompoundButton
              onClick={toggleUserMenu}
              icon={<Avatar />}
              secondaryContent={
                <span className={userMenuClasses.avatarButtonTexts}>
                  {localStorage.getItem("utype") === "0"
                    ? "Admin"
                    : localStorage.getItem("utype") === "1"
                    ? "Tester"
                    : "User"}
                </span>
              }
              appearance="transparent"
            >
              <span className={userMenuClasses.avatarButtonTexts}>
                {localStorage.getItem("uname")}
              </span>
            </CompoundButton>
          )}
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem icon={<SettingsFilled />}>Settings</MenuItem>
            <MenuItem
              onClick={toggleTheme}
              icon={
                theme.colorBrandBackground === "#43625B"
                  ? themeData.light.icon
                  : themeData.dark.icon
              }
            >
              {theme.colorBrandBackground === "#43625B"
                ? themeData.light.label
                : themeData.dark.label}{" "}
              Mode
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={signOutFromAccount}
              className={mergeClasses(
                userMenuClasses.userMenuButton,
                userMenuClasses.signOutButton
              )}
              icon={<SignOutFilled />}
            >
              Sign Out
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};

export default UserMenu;
