import {
  Button,
  Caption1,
  makeStyles,
  Text,
  Title1,
  tokens,
} from "@fluentui/react-components";
const errorStyles = makeStyles({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    background: "url(/assets/images/loginBG.svg) center/cover",
  },
  container: {
    borderRadius: "20px",
    padding: tokens.spacingHorizontalXXXL,
    backgroundColor: tokens.colorNeutralBackground2,
  },
});

const Error = () => {
  const errorClasses = errorStyles();
  return (
    <div className={errorClasses.root}>
      <div className={errorClasses.container}>
        <Title1
          align="center"
          style={{
            color: tokens.colorBrandForeground1,
            marginBottom: tokens.spacingHorizontalL,
          }}
          block
        >
          Payment Failed
        </Title1>
        <Caption1 align="center" block>
          How unfortunate. Your payment has failed. Please try again.
        </Caption1>
        <Text
          size={300}
          style={{
            marginTop: tokens.spacingHorizontalS,
            textTransform: "uppercase",
          }}
          align="center"
          block
        >
          OR
        </Text>
        <a href="tel:22282340" style={{ textDecoration: "none" }}>
          <Button
            style={{ width: "100%", marginTop: tokens.spacingHorizontalS }}
            appearance="primary"
            shape="circular"
          >
            Contact Support
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Error;
