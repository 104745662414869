import { makeStyles, tokens } from "@fluentui/react-components";
import React, { FC } from "react";

const footerProps = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: tokens.spacingHorizontalXXXL,
    marginTop: "auto",
    padding: tokens.spacingHorizontalXXXL,
    backgroundColor: tokens.colorBrandBackground2,
    borderRadius: "15px",
    boxShadow: tokens.shadow2,
  },
  image: {
    height: "5rem",
    cursor: "pointer",
  },
});

export interface ContentProp {
  contentRef: any;
}

const Footer: FC<ContentProp> = ({ contentRef }) => {
  const footerClass = footerProps();
  return (
    <footer className={footerClass.root}>
      <img
        className={footerClass.image}
        src="/assets/images/footerIcon.svg"
        alt="footerIcon"
        onClick={() => {
          contentRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      />
    </footer>
  );
};

export default Footer;
