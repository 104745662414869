export type CountryCode = {
  iso: {
    two: string; //	ISO 3166-1 alpha-2 code
    three: string; //	ISO 3166-1 alpha-3 code
  };
  name: string; //	English name of the country
  phoneCode: string[];
  flag: string; // In unicode
  limit?: number;
};

export const countryCodes: CountryCode[] = [
  {
    iso: {
      two: "KW",
      three: "KWT",
    },
    name: "Kuwait",
    phoneCode: ["965"],
    flag: "🇰🇼",
    limit: 8,
  },
  {
    iso: {
      two: "BH",
      three: "BHR",
    },
    name: "Bahrain",
    phoneCode: ["973"],
    flag: "🇧🇭",
    limit: 8,
  },
  {
    iso: {
      two: "OM",
      three: "OMN",
    },
    name: "Oman",
    phoneCode: ["968"],
    flag: "🇴🇲",
    limit: 8,
  },
  {
    iso: {
      two: "SA",
      three: "SAU",
    },
    name: "Saudi Arabia",
    phoneCode: ["966"],
    flag: "🇸🇦",
    limit: 9,
  },
  {
    iso: {
      two: "AE",
      three: "ARE",
    },
    name: "United Arab Emirates",
    phoneCode: ["971"],
    flag: "🇦🇪",
    limit: 9,
  },
  {
    iso: {
      two: "QA",
      three: "QAT",
    },
    name: "Qatar",
    phoneCode: ["974"],
    flag: "🇶🇦",
    limit: 8,
  },
  {
    iso: {
      two: "AF",
      three: "AFG",
    },
    name: "Afghanistan",
    phoneCode: ["93"],
    flag: "🇦🇫",
    limit: 9,
  },
  {
    iso: {
      two: "AL",
      three: "ALB",
    },
    name: "Albania",
    phoneCode: ["355"],
    flag: "🇦🇱",
    limit: 9,
  },
  {
    iso: {
      two: "DZ",
      three: "DZA",
    },
    name: "Algeria",
    phoneCode: ["213"],
    flag: "🇩🇿",
    limit: 9,
  },
  {
    iso: {
      two: "AS",
      three: "ASM",
    },
    name: "American Samoa",
    phoneCode: ["1-684"],
    flag: "🇦🇸",
    limit: 10,
  },
  {
    iso: {
      two: "AD",
      three: "AND",
    },
    name: "Andorra",
    phoneCode: ["376"],
    flag: "🇦🇩",
    limit: 9,
  },
  {
    iso: {
      two: "AO",
      three: "AGO",
    },
    name: "Angola",
    phoneCode: ["244"],
    flag: "🇦🇴",
    limit: 9,
  },
  {
    iso: {
      two: "AI",
      three: "AIA",
    },
    name: "Anguilla",
    phoneCode: ["1-264"],
    flag: "🇦🇮",
    limit: 10,
  },
  {
    iso: {
      two: "AQ",
      three: "ATA",
    },
    name: "Antartica",
    phoneCode: ["672"],
    flag: "🇦🇶",
  },
  {
    iso: {
      two: "AG",
      three: "ATG",
    },
    name: "Antigua and Barbuda",
    phoneCode: ["1-268"],
    flag: "🇦🇬",
    limit: 10,
  },
  {
    iso: {
      two: "AR",
      three: "ARG",
    },
    name: "Argentina",
    phoneCode: ["54"],
    flag: "🇦🇷",
    limit: 10,
  },
  {
    iso: {
      two: "AM",
      three: "ARM",
    },
    name: "Armenia",
    phoneCode: ["374"],
    flag: "🇦🇲",
    limit: 8,
  },
  {
    iso: {
      two: "AW",
      three: "ABW",
    },
    name: "Aruba",
    phoneCode: ["297"],
    flag: "🇦🇼",
    limit: 7,
  },
  {
    iso: {
      two: "AU",
      three: "AUS",
    },
    name: "Australia",
    phoneCode: ["61"],
    flag: "🇦🇺",
    limit: 15,
  },
  {
    iso: {
      two: "AT",
      three: "AUT",
    },
    name: "Austria",
    phoneCode: ["43"],
    flag: "🇦🇹",
    limit: 13,
  },
  {
    iso: {
      two: "AZ",
      three: "AZE",
    },
    name: "Azerbaijan",
    phoneCode: ["994"],
    flag: "🇦🇿",
    limit: 9,
  },
  {
    iso: {
      two: "BS",
      three: "BHS",
    },
    name: "Bahamas",
    phoneCode: ["1-242"],
    flag: "🇧🇸",
    limit: 10,
  },
  {
    iso: {
      two: "BD",
      three: "BGD",
    },
    name: "Bangladesh",
    phoneCode: ["880"],
    flag: "🇧🇩",
    limit: 10,
  },
  {
    iso: {
      two: "BB",
      three: "BRB",
    },
    name: "Barbados",
    phoneCode: ["1-246"],
    flag: "🇧🇧",
    limit: 10,
  },
  {
    iso: {
      two: "BY",
      three: "BLR",
    },
    name: "Belarus",
    phoneCode: ["375"],
    flag: "🇧🇾",
    limit: 10,
  },
  {
    iso: {
      two: "BE",
      three: "BEL",
    },
    name: "Belgium",
    phoneCode: ["32"],
    flag: "🇧🇪",
    limit: 9,
  },
  {
    iso: {
      two: "BZ",
      three: "BLZ",
    },
    name: "Belize",
    phoneCode: ["501"],
    flag: "🇧🇿",
    limit: 7,
  },
  {
    iso: {
      two: "BJ",
      three: "BEN",
    },
    name: "Benin",
    phoneCode: ["229"],
    flag: "🇧🇯",
    limit: 8,
  },
  {
    iso: {
      two: "BM",
      three: "BMU",
    },
    name: "Bermuda",
    phoneCode: ["1-441"],
    flag: "🇧🇲",
    limit: 10,
  },
  {
    iso: {
      two: "BT",
      three: "BTN",
    },
    name: "Bhutan",
    phoneCode: ["975"],
    flag: "🇧🇹",
    limit: 8,
  },
  {
    iso: {
      two: "BO",
      three: "BOL",
    },
    name: "Bolivia",
    phoneCode: ["591"],
    flag: "🇧🇴",
    limit: 8,
  },
  {
    iso: {
      two: "BA",
      three: "BIH",
    },
    name: "Bosnia and Herzegovina",
    phoneCode: ["387"],
    flag: "🇧🇦",
    limit: 8,
  },
  {
    iso: {
      two: "BW",
      three: "BWA",
    },
    name: "Botswana",
    phoneCode: ["267"],
    flag: "🇧🇼",
    limit: 8,
  },
  {
    iso: {
      two: "BR",
      three: "BRA",
    },
    name: "Brazil",
    phoneCode: ["55"],
    flag: "🇧🇷",
    limit: 10,
  },
  {
    iso: {
      two: "IO",
      three: "IOT",
    },
    name: "British Indian Ocean Territory",
    phoneCode: ["246"],
    flag: "🇮🇴",
    limit: 10,
  },
  {
    iso: {
      two: "VG",
      three: "VGB",
    },
    name: "British Virgin Islands",
    phoneCode: ["1-284"],
    flag: "🇻🇬",
    limit: 10,
  },
  {
    iso: {
      two: "BN",
      three: "BRN",
    },
    name: "Brunei",
    phoneCode: ["673"],
    flag: "🇧🇳",
    limit: 7,
  },
  {
    iso: {
      two: "BG",
      three: "BGR",
    },
    name: "Bulgaria",
    phoneCode: ["359"],
    flag: "🇧🇬",
    limit: 9,
  },
  {
    iso: {
      two: "BF",
      three: "BFA",
    },
    name: "Burkina Faso",
    phoneCode: ["226"],
    flag: "🇧🇫",
    limit: 8,
  },
  {
    iso: {
      two: "BI",
      three: "BDI",
    },
    name: "Burundi",
    phoneCode: ["257"],
    flag: "🇧🇮",
    limit: 8,
  },
  {
    iso: {
      two: "KH",
      three: "KHM",
    },
    name: "Cambodia",
    phoneCode: ["855"],
    flag: "🇰🇭",
    limit: 8,
  },
  {
    iso: {
      two: "CM",
      three: "CMR",
    },
    name: "Cameroon",
    phoneCode: ["237"],
    flag: "🇨🇲",
    limit: 8,
  },
  {
    iso: {
      two: "CA",
      three: "CAN",
    },
    name: "Canada",
    phoneCode: ["1"],
    flag: "🇨🇦",
    limit: 10,
  },
  {
    iso: {
      two: "CV",
      three: "CPV",
    },
    name: "Cape Verde",
    phoneCode: ["238"],
    flag: "🇨🇻",
    limit: 7,
  },
  {
    iso: {
      two: "KY",
      three: "CYM",
    },
    name: "Cayman Islands",
    phoneCode: ["1-345"],
    flag: "🇰🇾",
    limit: 10,
  },
  {
    iso: {
      two: "CF",
      three: "CAF",
    },
    name: "Central African Republic",
    phoneCode: ["236"],
    flag: "🇨🇫",
    limit: 8,
  },
  {
    iso: {
      two: "TD",
      three: "TCD",
    },
    name: "Chad",
    phoneCode: ["235"],
    flag: "🇹🇩",
    limit: 8,
  },
  {
    iso: {
      two: "CL",
      three: "CHL",
    },
    name: "Chile",
    phoneCode: ["56"],
    flag: "🇨🇱",
    limit: 9,
  },
  {
    iso: {
      two: "CN",
      three: "CHN",
    },
    name: "China",
    phoneCode: ["86"],
    flag: "🇨🇳",
    limit: 12,
  },
  {
    iso: {
      two: "CX",
      three: "CXR",
    },
    name: "Christmas Island",
    phoneCode: ["61"],
    flag: "🇨🇽",
    limit: 15,
  },
  {
    iso: {
      two: "CC",
      three: "CCK",
    },
    name: "Cocos Islands",
    phoneCode: ["61"],
    flag: "🇨🇨",
    limit: 15,
  },
  {
    iso: {
      two: "CO",
      three: "COL",
    },
    name: "Colombia",
    phoneCode: ["57"],
    flag: "🇨🇴",
    limit: 10,
  },
  {
    iso: {
      two: "KM",
      three: "COM",
    },
    name: "Comoros",
    phoneCode: ["269"],
    flag: "🇰🇲",
    limit: 7,
  },
  {
    iso: {
      two: "CK",
      three: "COK",
    },
    name: "Cook Islands",
    phoneCode: ["682"],
    flag: "🇨🇰",
    limit: 5,
  },
  {
    iso: {
      two: "CR",
      three: "CRI",
    },
    name: "Costa Rica",
    phoneCode: ["506"],
    flag: "🇨🇷",
    limit: 8,
  },
  {
    iso: {
      two: "HR",
      three: "HRV",
    },
    name: "Croatia",
    phoneCode: ["385"],
    flag: "🇭🇷",
    limit: 12,
  },
  {
    iso: {
      two: "CU",
      three: "CUB",
    },
    name: "Cuba",
    phoneCode: ["53"],
    flag: "🇨🇺",
    limit: 8,
  },
  {
    iso: {
      two: "CW",
      three: "CUW",
    },
    name: "Curacao",
    phoneCode: ["599"],
    flag: "🇨🇼",
    limit: 8,
  },
  {
    iso: {
      two: "CY",
      three: "CYP",
    },
    name: "Cyprus",
    phoneCode: ["357"],
    flag: "🇨🇾",
    limit: 11,
  },
  {
    iso: {
      two: "CZ",
      three: "CZE",
    },
    name: "Czech Republic",
    phoneCode: ["420"],
    flag: "🇨🇿",
    limit: 12,
  },
  {
    iso: {
      two: "CD",
      three: "COD",
    },
    name: "Democratic Republic of the Congo",
    phoneCode: ["243"],
    flag: "🇨🇩",
    limit: 9,
  },
  {
    iso: {
      two: "DK",
      three: "DNK",
    },
    name: "Denmark",
    phoneCode: ["45"],
    flag: "🇩🇰",
    limit: 8,
  },
  {
    iso: {
      two: "DJ",
      three: "DJI",
    },
    name: "Djbouti",
    phoneCode: ["253"],
    flag: "🇩🇯",
    limit: 6,
  },
  {
    iso: {
      two: "DM",
      three: "DMA",
    },
    name: "Dominica",
    phoneCode: ["1-767"],
    flag: "🇩🇲",
    limit: 10,
  },
  {
    iso: {
      two: "DO",
      three: "DOM",
    },
    name: "Dominican Republic",
    phoneCode: ["1-809", "1-829", "1-849"],
    flag: "🇩🇴",
    limit: 10,
  },
  {
    iso: {
      two: "TL",
      three: "TLS",
    },
    name: "East Timor",
    phoneCode: ["670"],
    flag: "🇹🇱",
  },
  {
    iso: {
      two: "EC",
      three: "ECU",
    },
    name: "Ecudor",
    phoneCode: ["593"],
    flag: "🇪🇨",
    limit: 8,
  },
  {
    iso: {
      two: "EG",
      three: "EGY",
    },
    name: "Egypt",
    phoneCode: ["20"],
    flag: "🇪🇬",
    limit: 10,
  },
  {
    iso: {
      two: "SV",
      three: "SLV",
    },
    name: "El Salvador",
    phoneCode: ["503"],
    flag: "🇸🇻",
    limit: 11,
  },
  {
    iso: {
      two: "GQ",
      three: "GNQ",
    },
    name: "Equatorial Guinea",
    phoneCode: ["240"],
    flag: "🇬🇶",
    limit: 9,
  },
  {
    iso: {
      two: "ER",
      three: "ERI",
    },
    name: "Eritrea",
    phoneCode: ["291"],
    flag: "🇪🇷",
    limit: 7,
  },
  {
    iso: {
      two: "EE",
      three: "EST",
    },
    name: "Estonia",
    phoneCode: ["372"],
    flag: "🇪🇪",
    limit: 10,
  },
  {
    iso: {
      two: "ET",
      three: "ETH",
    },
    name: "Ethiopia",
    phoneCode: ["251"],
    flag: "🇪🇹",
    limit: 9,
  },
  {
    iso: {
      two: "FK",
      three: "FLK",
    },
    name: "Fakland Islands",
    phoneCode: ["500"],
    flag: "🇫🇰",
    limit: 5,
  },
  {
    iso: {
      two: "FO",
      three: "FRO",
    },
    name: "Faroe Islands",
    phoneCode: ["298"],
    flag: "🇫🇴",
    limit: 6,
  },
  {
    iso: {
      two: "FJ",
      three: "FJI",
    },
    name: "Fiji",
    phoneCode: ["679"],
    flag: "🇫🇯",
    limit: 7,
  },
  {
    iso: {
      two: "FI",
      three: "FIN",
    },
    name: "Finland",
    phoneCode: ["358"],
    flag: "🇫🇮",
    limit: 12,
  },
  {
    iso: {
      two: "FR",
      three: "FRA",
    },
    name: "France",
    phoneCode: ["33"],
    flag: "🇫🇷",
    limit: 9,
  },
  {
    iso: {
      two: "PF",
      three: "PYF",
    },
    name: "French Polynesia",
    phoneCode: ["689"],
    flag: "🇵🇫",
  },
  {
    iso: {
      two: "GA",
      three: "GAB",
    },
    name: "Gabon",
    phoneCode: ["241"],
    flag: "🇬🇦",
    limit: 7,
  },
  {
    iso: {
      two: "GM",
      three: "GMB",
    },
    name: "Gambia",
    phoneCode: ["220"],
    flag: "🇬🇲",
    limit: 7,
  },
  {
    iso: {
      two: "GE",
      three: "GEO",
    },
    name: "Georgia",
    phoneCode: ["995"],
    flag: "🇬🇪",
    limit: 9,
  },
  {
    iso: {
      two: "DE",
      three: "DEU",
    },
    name: "Germany",
    phoneCode: ["49"],
    flag: "🇩🇪",
    limit: 13,
  },
  {
    iso: {
      two: "GH",
      three: "GHA",
    },
    name: "Ghana",
    phoneCode: ["233"],
    flag: "🇬🇭",
    limit: 9,
  },
  {
    iso: {
      two: "GI",
      three: "GIB",
    },
    name: "Gibraltar",
    phoneCode: ["350"],
    flag: "🇬🇮",
    limit: 8,
  },
  {
    iso: {
      two: "GR",
      three: "GRC",
    },
    name: "Greece",
    phoneCode: ["30"],
    flag: "🇬🇷",
    limit: 10,
  },
  {
    iso: {
      two: "GL",
      three: "GRL",
    },
    name: "Greenland",
    phoneCode: ["299"],
    flag: "🇬🇱",
    limit: 6,
  },
  {
    iso: {
      two: "GD",
      three: "GRD",
    },
    name: "Grenada",
    phoneCode: ["1-473"],
    flag: "🇬🇩",
    limit: 10,
  },
  {
    iso: {
      two: "GU",
      three: "GUM",
    },
    name: "Guam",
    phoneCode: ["1-671"],
    flag: "🇬🇺",
    limit: 10,
  },
  {
    iso: {
      two: "GT",
      three: "GTM",
    },
    name: "Guatemala",
    phoneCode: ["502"],
    flag: "🇬🇹",
    limit: 8,
  },
  {
    iso: {
      two: "GG",
      three: "GGY",
    },
    name: "Guernsey",
    phoneCode: ["44-1481"],
    flag: "🇬🇬",
  },
  {
    iso: {
      two: "GN",
      three: "GIN",
    },
    name: "Guinea",
    phoneCode: ["224"],
    flag: "🇬🇳",
    limit: 8,
  },
  {
    iso: {
      two: "GW",
      three: "GNB",
    },
    name: "Guinea-Bissau",
    phoneCode: ["245"],
    flag: "🇬🇼",
  },
  {
    iso: {
      two: "GY",
      three: "GUY",
    },
    name: "Guyana",
    phoneCode: ["592"],
    flag: "🇬🇾",
    limit: 7,
  },
  {
    iso: {
      two: "HT",
      three: "HTI",
    },
    name: "Haiti",
    phoneCode: ["509"],
    flag: "🇭🇹",
    limit: 8,
  },
  {
    iso: {
      two: "HN",
      three: "HND",
    },
    name: "Honduras",
    phoneCode: ["504"],
    flag: "🇭🇳",
    limit: 8,
  },
  {
    iso: {
      two: "HK",
      three: "HKG",
    },
    name: "Hong Kong",
    phoneCode: ["852"],
    flag: "🇭🇰",
    limit: 9,
  },
  {
    iso: {
      two: "HU",
      three: "HUN",
    },
    name: "Hungary",
    phoneCode: ["36"],
    flag: "🇭🇺",
    limit: 9,
  },
  {
    iso: {
      two: "IS",
      three: "ISL",
    },
    name: "Iceland",
    phoneCode: ["354"],
    flag: "🇮🇸",
    limit: 9,
  },
  {
    iso: {
      two: "IN",
      three: "IND",
    },
    name: "India",
    phoneCode: ["91"],
    flag: "🇮🇳",
    limit: 10,
  },
  {
    iso: {
      two: "ID",
      three: "IDN",
    },
    name: "Indonesia",
    phoneCode: ["62"],
    flag: "🇮🇩",
    limit: 10,
  },
  {
    iso: {
      two: "IR",
      three: "IRN",
    },
    name: "Iran",
    phoneCode: ["98"],
    flag: "🇮🇷",
    limit: 10,
  },
  {
    iso: {
      two: "IQ",
      three: "IRQ",
    },
    name: "Iraq",
    phoneCode: ["964"],
    flag: "🇮🇶",
    limit: 10,
  },
  {
    iso: {
      two: "IE",
      three: "IRL",
    },
    name: "Ireland",
    phoneCode: ["353"],
    flag: "🇮🇪",
    limit: 11,
  },
  {
    iso: {
      two: "IM",
      three: "IMN",
    },
    name: "Isle of Man",
    phoneCode: ["44-1624"],
    flag: "🇮🇲",
  },
  {
    iso: {
      two: "IT",
      three: "ITA",
    },
    name: "Italy",
    phoneCode: ["39"],
    flag: "🇮🇹",
    limit: 11,
  },
  {
    iso: {
      two: "CI",
      three: "CIV",
    },
    name: "Ivory Coast",
    phoneCode: ["225"],
    flag: "🇨🇮",
    limit: 8,
  },
  {
    iso: {
      two: "JM",
      three: "JAM",
    },
    name: "Jamaica",
    phoneCode: ["1-876"],
    flag: "🇯🇲",
    limit: 10,
  },
  {
    iso: {
      two: "JP",
      three: "JPN",
    },
    name: "Japan",
    phoneCode: ["81"],
    flag: "🇯🇵",
    limit: 13,
  },
  {
    iso: {
      two: "JE",
      three: "JEY",
    },
    name: "Jersey",
    phoneCode: ["44-1534"],
    flag: "🇯🇪",
  },
  {
    iso: {
      two: "JO",
      three: "JOR",
    },
    name: "Jordan",
    phoneCode: ["962"],
    flag: "🇯🇴",
    limit: 9,
  },
  {
    iso: {
      two: "KZ",
      three: "KAZ",
    },
    name: "Kazakhstan",
    phoneCode: ["7"],
    flag: "🇰🇿",
    limit: 10,
  },
  {
    iso: {
      two: "KE",
      three: "KEN",
    },
    name: "Kenya",
    phoneCode: ["254"],
    flag: "🇰🇪",
    limit: 10,
  },
  {
    iso: {
      two: "KI",
      three: "KIR",
    },
    name: "Kirbaiti",
    phoneCode: ["686"],
    flag: "🇰🇮",
    limit: 5,
  },
  {
    iso: {
      two: "XK",
      three: "XKX",
    },
    name: "Kosovo",
    phoneCode: ["383"],
    flag: "🇽🇰",
  },
  {
    iso: {
      two: "KG",
      three: "KGZ",
    },
    name: "Kyrgyzstan",
    phoneCode: ["996"],
    flag: "🇰🇬",
    limit: 9,
  },
  {
    iso: {
      two: "LA",
      three: "LAO",
    },
    name: "Laos",
    phoneCode: ["856"],
    flag: "🇱🇦",
    limit: 10,
  },
  {
    iso: {
      two: "LV",
      three: "LVA",
    },
    name: "Latvia",
    phoneCode: ["371"],
    flag: "🇱🇻",
    limit: 8,
  },
  {
    iso: {
      two: "LB",
      three: "LBN",
    },
    name: "Lebanon",
    phoneCode: ["961"],
    flag: "🇱🇧",
    limit: 8,
  },
  {
    iso: {
      two: "LS",
      three: "LSO",
    },
    name: "Lesotho",
    phoneCode: ["266"],
    flag: "🇱🇸",
    limit: 8,
  },
  {
    iso: {
      two: "LR",
      three: "LBR",
    },
    name: "Liberia",
    phoneCode: ["231"],
    flag: "🇱🇷",
    limit: 8,
  },
  {
    iso: {
      two: "LY",
      three: "LBY",
    },
    name: "Libya",
    phoneCode: ["218"],
    flag: "🇱🇾",
    limit: 9,
  },
  {
    iso: {
      two: "LI",
      three: "LIE",
    },
    name: "Liechtenstein",
    phoneCode: ["423"],
    flag: "🇱🇮",
    limit: 9,
  },
  {
    iso: {
      two: "LT",
      three: "LTU",
    },
    name: "Lithuania",
    phoneCode: ["370"],
    flag: "🇱🇹",
    limit: 8,
  },
  {
    iso: {
      two: "LU",
      three: "LUX",
    },
    name: "Luxembourg",
    phoneCode: ["352"],
    flag: "🇱🇺",
    limit: 11,
  },
  {
    iso: {
      two: "MO",
      three: "MAC",
    },
    name: "Macau",
    phoneCode: ["853"],
    flag: "🇲🇴",
    limit: 8,
  },
  {
    iso: {
      two: "MK",
      three: "MKD",
    },
    name: "Macedonia",
    phoneCode: ["389"],
    flag: "🇲🇰",
  },
  {
    iso: {
      two: "MG",
      three: "MDG",
    },
    name: "Madagascar",
    phoneCode: ["261"],
    flag: "🇲🇬",
    limit: 10,
  },
  {
    iso: {
      two: "MW",
      three: "MWI",
    },
    name: "Malawi",
    phoneCode: ["265"],
    flag: "🇲🇼",
    limit: 8,
  },
  {
    iso: {
      two: "MY",
      three: "MYS",
    },
    name: "Malaysia",
    phoneCode: ["60"],
    flag: "🇲🇾",
    limit: 9,
  },
  {
    iso: {
      two: "MV",
      three: "MDV",
    },
    name: "Maldives",
    phoneCode: ["960"],
    flag: "🇲🇻",
    limit: 7,
  },
  {
    iso: {
      two: "ML",
      three: "MLI",
    },
    name: "Mali",
    phoneCode: ["223"],
    flag: "🇲🇱",
    limit: 8,
  },
  {
    iso: {
      two: "MT",
      three: "MLT",
    },
    name: "Malta",
    phoneCode: ["356"],
    flag: "🇲🇹",
    limit: 8,
  },
  {
    iso: {
      two: "MH",
      three: "MHL",
    },
    name: "Marshall Islands",
    phoneCode: ["692"],
    flag: "🇲🇭",
    limit: 7,
  },
  {
    iso: {
      two: "MR",
      three: "MRT",
    },
    name: "Mauritania",
    phoneCode: ["222"],
    flag: "🇲🇷",
    limit: 7,
  },
  {
    iso: {
      two: "MU",
      three: "MUS",
    },
    name: "Mauritius",
    phoneCode: ["230"],
    flag: "🇲🇺",
    limit: 7,
  },
  {
    iso: {
      two: "YT",
      three: "MYT",
    },
    name: "Mayotte",
    phoneCode: ["262"],
    flag: "🇾🇹",
  },
  {
    iso: {
      two: "MX",
      three: "MEX",
    },
    name: "Mexico",
    phoneCode: ["52"],
    flag: "🇲🇽",
    limit: 10,
  },
  {
    iso: {
      two: "FM",
      three: "FSM",
    },
    name: "Micronesia",
    phoneCode: ["691"],
    flag: "🇫🇲",
    limit: 7,
  },
  {
    iso: {
      two: "MD",
      three: "MDA",
    },
    name: "Moldova",
    phoneCode: ["373"],
    flag: "🇲🇩",
    limit: 8,
  },
  {
    iso: {
      two: "MC",
      three: "MCO",
    },
    name: "Monaco",
    phoneCode: ["377"],
    flag: "🇲🇨",
    limit: 9,
  },
  {
    iso: {
      two: "MN",
      three: "MNG",
    },
    name: "Mongolia",
    phoneCode: ["976"],
    flag: "🇲🇳",
    limit: 8,
  },
  {
    iso: {
      two: "MN",
      three: "MNE",
    },
    name: "Montenegro",
    phoneCode: ["382"],
    flag: "🇲🇪",
    limit: 12,
  },
  {
    iso: {
      two: "MS",
      three: "MSR",
    },
    name: "Montserrat",
    phoneCode: ["1-664"],
    flag: "🇲🇸",
    limit: 10,
  },
  {
    iso: {
      two: "MA",
      three: "MAR",
    },
    name: "Morocco",
    phoneCode: ["212"],
    flag: "🇲🇦",
    limit: 9,
  },
  {
    iso: {
      two: "MZ",
      three: "MOZ",
    },
    name: "Mozambique",
    phoneCode: ["258"],
    flag: "🇲🇿",
    limit: 9,
  },
  {
    iso: {
      two: "MM",
      three: "MMR",
    },
    name: "Myanmar",
    phoneCode: ["95"],
    flag: "🇲🇲",
    limit: 9,
  },
  {
    iso: {
      two: "NA",
      three: "NAM",
    },
    name: "Namibia",
    phoneCode: ["264"],
    flag: "🇳🇦",
    limit: 10,
  },
  {
    iso: {
      two: "NR",
      three: "NRU",
    },
    name: "Nauru",
    phoneCode: ["674"],
    flag: "🇳🇷",
    limit: 7,
  },
  {
    iso: {
      two: "NP",
      three: "NPL",
    },
    name: "Nepal",
    phoneCode: ["977"],
    flag: "🇳🇵",
    limit: 9,
  },
  {
    iso: {
      two: "NL",
      three: "NLD",
    },
    name: "Netherlands",
    phoneCode: ["31"],
    flag: "🇳🇱",
    limit: 9,
  },
  {
    iso: {
      two: "AN",
      three: "ANT",
    },
    name: "Netherlands Antilles",
    phoneCode: ["599"],
    flag: "🇧🇶",
  },
  {
    iso: {
      two: "NC",
      three: "NCL",
    },
    name: "New Caledonia",
    phoneCode: ["687"],
    flag: "🇳🇨",
    limit: 6,
  },
  {
    iso: {
      two: "NZ",
      three: "NZL",
    },
    name: "New Zealand",
    phoneCode: ["64"],
    flag: "🇳🇿",
    limit: 10,
  },
  {
    iso: {
      two: "NI",
      three: "NIC",
    },
    name: "Nicaragua",
    phoneCode: ["505"],
    flag: "🇳🇮",
    limit: 8,
  },
  {
    iso: {
      two: "NE",
      three: "NER",
    },
    name: "Niger",
    phoneCode: ["227"],
    flag: "🇳🇪",
    limit: 8,
  },
  {
    iso: {
      two: "NG",
      three: "NGA",
    },
    name: "Nigeria",
    phoneCode: ["234"],
    flag: "🇳🇬",
    limit: 10,
  },
  {
    iso: {
      two: "NU",
      three: "NIU",
    },
    name: "Niue",
    phoneCode: ["683"],
    flag: "🇳🇺",
    limit: 4,
  },
  {
    iso: {
      two: "KP",
      three: "PRK",
    },
    name: "North Korea",
    phoneCode: ["850"],
    flag: "🇰🇵",
    limit: 17,
  },
  {
    iso: {
      two: "MP",
      three: "MNP",
    },
    name: "Northern Mariana Islands",
    phoneCode: ["1-670"],
    flag: "🇲🇵",
  },
  {
    iso: {
      two: "NO",
      three: "NOR",
    },
    name: "Norway",
    phoneCode: ["47"],
    flag: "🇳🇴",
    limit: 8,
  },
  {
    iso: {
      two: "PK",
      three: "PAK",
    },
    name: "Pakistan",
    phoneCode: ["92"],
    flag: "🇵🇰",
    limit: 11,
  },
  {
    iso: {
      two: "PW",
      three: "PLW",
    },
    name: "Palau",
    phoneCode: ["680"],
    flag: "🇵🇼",
    limit: 7,
  },
  {
    iso: {
      two: "PS",
      three: "PSE",
    },
    name: "Palestine",
    phoneCode: ["970"],
    flag: "🇵🇸",
    limit: 9,
  },
  {
    iso: {
      two: "PA",
      three: "PAN",
    },
    name: "Panama",
    phoneCode: ["507"],
    flag: "🇵🇦",
    limit: 8,
  },
  {
    iso: {
      two: "PG",
      three: "PNG",
    },
    name: "Papua New Guinea",
    phoneCode: ["675"],
    flag: "🇵🇬",
    limit: 11,
  },
  {
    iso: {
      two: "PY",
      three: "PRY",
    },
    name: "Paraguay",
    phoneCode: ["595"],
    flag: "🇵🇾",
    limit: 9,
  },
  {
    iso: {
      two: "PE",
      three: "PER",
    },
    name: "Peru",
    phoneCode: ["51"],
    flag: "🇵🇪",
    limit: 11,
  },
  {
    iso: {
      two: "PH",
      three: "PHL",
    },
    name: "Philippines",
    phoneCode: ["63"],
    flag: "🇵🇭",
    limit: 10,
  },
  {
    iso: {
      two: "PN",
      three: "PCN",
    },
    name: "Pitcairn",
    phoneCode: ["64"],
    flag: "🇵🇳",
  },
  {
    iso: {
      two: "PO",
      three: "POL",
    },
    name: "Poland",
    phoneCode: ["48"],
    flag: "🇵🇱",
    limit: 9,
  },
  {
    iso: {
      two: "PT",
      three: "PRT",
    },
    name: "Portugal",
    phoneCode: ["351"],
    flag: "🇵🇹",
    limit: 11,
  },
  {
    iso: {
      two: "PR",
      three: "PRI",
    },
    name: "Puerto Rico",
    phoneCode: ["1-787", "1-939"],
    flag: "🇵🇷",
    limit: 10,
  },
  {
    iso: {
      two: "CG",
      three: "COG",
    },
    name: "Republic of the Congo",
    phoneCode: ["242"],
    flag: "🇨🇬",
  },
  {
    iso: {
      two: "RE",
      three: "REU",
    },
    name: "Reunion",
    phoneCode: ["262"],
    flag: "🇷🇪",
  },
  {
    iso: {
      two: "RO",
      three: "ROU",
    },
    name: "Romania",
    phoneCode: ["40"],
    flag: "🇷🇴",
    limit: 9,
  },
  {
    iso: {
      two: "RU",
      three: "RUS",
    },
    name: "Russia",
    phoneCode: ["7"],
    flag: "🇷🇺",
    limit: 10,
  },
  {
    iso: {
      two: "RW",
      three: "RWA",
    },
    name: "Rwanda",
    phoneCode: ["250"],
    flag: "🇷🇼",
    limit: 9,
  },
  {
    iso: {
      two: "BL",
      three: "BLM",
    },
    name: "Saint Barthelemy",
    phoneCode: ["590"],
    flag: "🇧🇱",
  },
  {
    iso: {
      two: "SH",
      three: "SHN",
    },
    name: "Saint Helena",
    phoneCode: ["290"],
    flag: "🇸🇭",
  },
  {
    iso: {
      two: "KN",
      three: "KNA",
    },
    name: "Saint Kitts and Nevis",
    phoneCode: ["1-869"],
    flag: "🇰🇳",
    limit: 10,
  },
  {
    iso: {
      two: "LC",
      three: "LCA",
    },
    name: "Saint Lucia",
    phoneCode: ["1-758"],
    flag: "🇱🇨",
    limit: 10,
  },
  {
    iso: {
      two: "MF",
      three: "MAF",
    },
    name: "Saint Martin",
    phoneCode: ["590"],
    flag: "🇲🇫",
  },
  {
    iso: {
      two: "PM",
      three: "SPM",
    },
    name: "Saint Pierre and Miquelon",
    phoneCode: ["508"],
    flag: "🇵🇲",
    limit: 6,
  },
  {
    iso: {
      two: "VC",
      three: "VCT",
    },
    name: "Saint Vincent and the Grenadines",
    phoneCode: ["1-784"],
    flag: "🇻🇨",
    limit: 10,
  },
  {
    iso: {
      two: "WS",
      three: "WSM",
    },
    name: "Samoa",
    phoneCode: ["685"],
    flag: "🇼🇸",
    limit: 7,
  },
  {
    iso: {
      two: "SM",
      three: "SMR",
    },
    name: "San Marino",
    phoneCode: ["378"],
    flag: "🇸🇲",
    limit: 10,
  },
  {
    iso: {
      two: "ST",
      three: "STP",
    },
    name: "Sao Tome and Principe",
    phoneCode: ["239"],
    flag: "🇸🇹",
    limit: 7,
  },
  {
    iso: {
      two: "SN",
      three: "SEN",
    },
    name: "Senegal",
    phoneCode: ["221"],
    flag: "🇸🇳",
    limit: 9,
  },
  {
    iso: {
      two: "RS",
      three: "SRB",
    },
    name: "Serbia",
    phoneCode: ["381"],
    flag: "🇷🇸",
    limit: 12,
  },
  {
    iso: {
      two: "SC",
      three: "SYC",
    },
    name: "Seychelles",
    phoneCode: ["248"],
    flag: "🇸🇨",
    limit: 7,
  },
  {
    iso: {
      two: "SL",
      three: "SLE",
    },
    name: "Sierra Leone",
    phoneCode: ["232"],
    flag: "🇸🇱",
    limit: 8,
  },
  {
    iso: {
      two: "SG",
      three: "SGP",
    },
    name: "Singapore",
    phoneCode: ["65"],
    flag: "🇸🇬",
    limit: 12,
  },
  {
    iso: {
      two: "SX",
      three: "SXM",
    },
    name: "Sint Maarten",
    phoneCode: ["1-721"],
    flag: "🇸🇽",
    limit: 10,
  },
  {
    iso: {
      two: "SK",
      three: "SVK",
    },
    name: "Slovakia",
    phoneCode: ["421"],
    flag: "🇸🇰",
    limit: 9,
  },
  {
    iso: {
      two: "SI",
      three: "SVN",
    },
    name: "Slovenia",
    phoneCode: ["386"],
    flag: "🇸🇮",
    limit: 8,
  },
  {
    iso: {
      two: "SB",
      three: "SLB",
    },
    name: "Solomon Islands",
    phoneCode: ["677"],
    flag: "🇸🇧",
    limit: 5,
  },
  {
    iso: {
      two: "SO",
      three: "SOM",
    },
    name: "Somalia",
    phoneCode: ["252"],
    flag: "🇸🇴",
    limit: 8,
  },
  {
    iso: {
      two: "ZA",
      three: "ZAF",
    },
    name: "South Africa",
    phoneCode: ["27"],
    flag: "🇿🇦",
    limit: 9,
  },
  {
    iso: {
      two: "KR",
      three: "KOR",
    },
    name: "South Korea",
    phoneCode: ["82"],
    flag: "🇰🇷",
    limit: 11,
  },
  {
    iso: {
      two: "SS",
      three: "SSD",
    },
    name: "South Sudan",
    phoneCode: ["211"],
    flag: "🇸🇸",
  },
  {
    iso: {
      two: "ES",
      three: "ESP",
    },
    name: "Spain",
    phoneCode: ["34"],
    flag: "🇪🇸",
    limit: 9,
  },
  {
    iso: {
      two: "LK",
      three: "LKA",
    },
    name: "Sri Lanka",
    phoneCode: ["94"],
    flag: "🇱🇰",
    limit: 9,
  },
  {
    iso: {
      two: "SD",
      three: "SDN",
    },
    name: "Sudan",
    phoneCode: ["249"],
    flag: "🇸🇩",
    limit: 9,
  },
  {
    iso: {
      two: "SR",
      three: "SUR",
    },
    name: "Suriname",
    phoneCode: ["597"],
    flag: "🇸🇷",
    limit: 7,
  },
  {
    iso: {
      two: "SJ",
      three: "SJM",
    },
    name: "Svalbard and Jan Mayen",
    phoneCode: ["47"],
    flag: "🇸🇯",
  },
  {
    iso: {
      two: "SZ",
      three: "SWZ",
    },
    name: "Swaziland",
    phoneCode: ["268"],
    flag: "🇸🇿",
  },
  {
    iso: {
      two: "SE",
      three: "SWE",
    },
    name: "Sweden",
    phoneCode: ["46"],
    flag: "🇸🇪",
    limit: 13,
  },
  {
    iso: {
      two: "CH",
      three: "CHE",
    },
    name: "Switzerland",
    phoneCode: ["41"],
    flag: "🇨🇭",
    limit: 12,
  },
  {
    iso: {
      two: "SY",
      three: "SYR",
    },
    name: "Syria",
    phoneCode: ["963"],
    flag: "🇸🇾",
    limit: 10,
  },
  {
    iso: {
      two: "TW",
      three: "TWN",
    },
    name: "Taiwan",
    phoneCode: ["886"],
    flag: "🇹🇼",
    limit: 9,
  },
  {
    iso: {
      two: "TJ",
      three: "TJK",
    },
    name: "Tajikistan",
    phoneCode: ["992"],
    flag: "🇹🇯",
    limit: 9,
  },
  {
    iso: {
      two: "TZ",
      three: "TZA",
    },
    name: "Tanzania",
    phoneCode: ["255"],
    flag: "🇹🇿",
    limit: 9,
  },
  {
    iso: {
      two: "TH",
      three: "THA",
    },
    name: "Thailand",
    phoneCode: ["66"],
    flag: "🇹🇭",
    limit: 9,
  },
  {
    iso: {
      two: "TG",
      three: "TGO",
    },
    name: "Togo",
    phoneCode: ["228"],
    flag: "🇹🇬",
    limit: 8,
  },
  {
    iso: {
      two: "TK",
      three: "TKL",
    },
    name: "Tokelau",
    phoneCode: ["690"],
    flag: "🇹🇰",
    limit: 4,
  },
  {
    iso: {
      two: "TO",
      three: "TON",
    },
    name: "Tonga",
    phoneCode: ["676"],
    flag: "🇹🇴",
    limit: 7,
  },
  {
    iso: {
      two: "TT",
      three: "TTO",
    },
    name: "Trinidad and Tobago",
    phoneCode: ["1-868"],
    flag: "🇹🇹",
    limit: 10,
  },
  {
    iso: {
      two: "TN",
      three: "TUN",
    },
    name: "Tunisia",
    phoneCode: ["216"],
    flag: "🇹🇳",
    limit: 8,
  },
  {
    iso: {
      two: "TR",
      three: "TUR",
    },
    name: "Turkey",
    phoneCode: ["90"],
    flag: "🇹🇷",
    limit: 10,
  },
  {
    iso: {
      two: "TM",
      three: "TKM",
    },
    name: "Turkmenistan",
    phoneCode: ["993"],
    flag: "🇹🇲",
    limit: 8,
  },
  {
    iso: {
      two: "TC",
      three: "TCA",
    },
    name: "Turks and Caicos Islands",
    phoneCode: ["1-649"],
    flag: "🇹🇨",
    limit: 10,
  },
  {
    iso: {
      two: "TV",
      three: "TUV",
    },
    name: "Tuvalu",
    phoneCode: ["688"],
    flag: "🇹🇻",
    limit: 6,
  },
  {
    iso: {
      two: "VI",
      three: "VIR",
    },
    name: "U.S. Virgin Islands",
    phoneCode: ["1-340"],
    flag: "🇻🇮",
  },
  {
    iso: {
      two: "UG",
      three: "UGA",
    },
    name: "Uganda",
    phoneCode: ["256"],
    flag: "🇺🇬",
    limit: 9,
  },
  {
    iso: {
      two: "UA",
      three: "UKR",
    },
    name: "Ukraine",
    phoneCode: ["380"],
    flag: "🇺🇦",
    limit: 9,
  },
  {
    iso: {
      two: "GB",
      three: "GBR",
    },
    name: "United Kingdom",
    phoneCode: ["44"],
    flag: "🇬🇧",
    limit: 10,
  },
  {
    iso: {
      two: "US",
      three: "USA",
    },
    name: "United States",
    phoneCode: ["1"],
    flag: "🇺🇸",
    limit: 10,
  },
  {
    iso: {
      two: "UY",
      three: "URY",
    },
    name: "Uruguay",
    phoneCode: ["598"],
    flag: "🇺🇾",
    limit: 11,
  },
  {
    iso: {
      two: "UZ",
      three: "UZB",
    },
    name: "Uzbekistan",
    phoneCode: ["998"],
    flag: "🇺🇿",
    limit: 9,
  },
  {
    iso: {
      two: "VU",
      three: "VUT",
    },
    name: "Vanuatu",
    phoneCode: ["678"],
    flag: "🇻🇺",
    limit: 7,
  },
  {
    iso: {
      two: "VA",
      three: "VAT",
    },
    name: "Vatican",
    phoneCode: ["379"],
    flag: "🇻🇦",
  },
  {
    iso: {
      two: "VE",
      three: "VEN",
    },
    name: "Venezuela",
    phoneCode: ["58"],
    flag: "🇻🇪",
    limit: 10,
  },
  {
    iso: {
      two: "VN",
      three: "VNM",
    },
    name: "Vietnam",
    phoneCode: ["84"],
    flag: "🇻🇳",
    limit: 10,
  },
  {
    iso: {
      two: "WF",
      three: "WLF",
    },
    name: "Wallis and Futuna",
    phoneCode: ["681"],
    flag: "🇼🇫",
    limit: 6,
  },
  {
    iso: {
      two: "EH",
      three: "ESH",
    },
    name: "Western Sahara",
    phoneCode: ["212"],
    flag: "🇪🇭",
  },
  {
    iso: {
      two: "YE",
      three: "YEM",
    },
    name: "Yemen",
    phoneCode: ["967"],
    flag: "🇾🇪",
    limit: 9,
  },
  {
    iso: {
      two: "ZM",
      three: "ZMB",
    },
    name: "Zambia",
    phoneCode: ["260"],
    flag: "🇿🇲",
    limit: 9,
  },
  {
    iso: {
      two: "ZW",
      three: "ZWE",
    },
    name: "Zimbabwe",
    phoneCode: ["263"],
    flag: "🇿🇼",
    limit: 10,
  },
];

// export default country_code;
