import {
  Display,
  makeStyles,
  mergeClasses,
  SelectTabData,
  SelectTabEvent,
  shorthands,
  Subtitle2,
  Tab,
  TabList,
  Text,
  tokens,
} from "@fluentui/react-components";

import { useCollapse } from "../../context/CollapseProvider";
import { EventCards } from "../../components";
import { useEffect, useState } from "react";
import { statusData } from "../../data";
import { AppsvenueEvent } from "../../data/eventData";
import { getEvents, getEventsByStatus } from "../../utils/event";
import {
  EmojiLaughFilled,
  EmojiMehFilled,
  EmojiSadSlightFilled,
  EmojiSurpriseFilled,
} from "@fluentui/react-icons";

const eventPageStyle = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: tokens.spacingVerticalXXL,
  },
  headerText: {
    display: "block",
  },
  subtitleText: {
    display: "block",
    color: tokens.colorNeutralForeground2,
  },
  addButton: {
    marginLeft: "auto",
  },
  main: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridGap: tokens.spacingVerticalL,
    padding: tokens.spacingVerticalL,
    transition: "all 0.3s",
  },
  mainExpanded: {
    gridTemplateColumns: "repeat(4,1fr)",
  },
  paginationToolbar: {
    position: "sticky",
    top: tokens.spacingHorizontalL,
    backgroundColor: tokens.colorNeutralBackground2,
    borderRadius: tokens.borderRadiusLarge,
    ...shorthands.margin(tokens.spacingHorizontalL),
    ...shorthands.padding(tokens.spacingHorizontalL),
    boxShadow: tokens.shadow8,
  },
  noEventContainer: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: tokens.spacingHorizontalL,
  },
  noEventIcon: {
    color: tokens.colorNeutralBackground1Selected,
    fontSize: "10rem",
  },
  noEventSubtitle: {
    color: tokens.colorNeutralForeground4,
  },
});

const Events = () => {
  const [events, setEvents] = useState<[AppsvenueEvent] | []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [contentLimit, setContentLimit] = useState(10);
  const [selectedTab, setSelectedTab] = useState<unknown>("All");

  useEffect(() => {
    fetchEvents(currentPage, contentLimit);
  }, [currentPage, contentLimit, selectedTab]);

  const fetchEvents = async (page: any, limit: any) => {
    try {
      let response;
      switch (selectedTab) {
        case "All":
          response = await getEvents(page, limit);
          setEvents(response.data.events);
          setTotalPages(response.data.totalPages);
          break;
        case 0:
          response = await getEventsByStatus(0);
          setEvents(response.data);
          break;
        case 1:
          response = await getEventsByStatus(1);
          setEvents(response.data);
          break;

        case 2:
          response = await getEventsByStatus(2);
          setEvents(response.data);
          break;
      }
    } catch (error) {
      console.log("Error Fetching Events", error);
    }
  };

  useEffect(() => {
    document.title = "Appsvenue Events · Event Cards";
  }, []);
  const eventPageClasses = eventPageStyle();
  const { collapse } = useCollapse();
  return (
    <>
      <div className={eventPageClasses.header}>
        <div className={eventPageClasses.headerText}>
          <Display>Event Cards</Display>
          <Subtitle2 className={eventPageClasses.subtitleText}>
            Manage Events
          </Subtitle2>
        </div>
      </div>
      <div>
        <TabList
          defaultSelectedValue={selectedTab}
          onTabSelect={(e: SelectTabEvent, data: SelectTabData) =>
            setSelectedTab(data.value)
          }
        >
          <Tab value={"All"}>All</Tab>
          {statusData.map((stats) => {
            const Icon = stats.icon;
            return (
              <Tab
                value={stats.status}
                icon={<Icon style={{ color: stats.color }} />}
              >
                {stats.label}
              </Tab>
            );
          })}
        </TabList>

        {events.length > 0 ? (
          <div
            className={mergeClasses(
              eventPageClasses.main,
              collapse && eventPageClasses.mainExpanded
            )}
          >
            {events?.map((event: AppsvenueEvent) => (
              <EventCards
                title={event.name.en}
                venue={event.venue}
                status={event.status}
                startDate={event.event_duration.start}
                endDate={event.event_duration.end}
                attended={event.audience.attended}
                registered={event.audience.registered}
                image={event.images.banner}
                website={event.website ? event.website : ""}
                registration={event.reglink}
                {...{ sideEvent: event.side_events }}
              />
            ))}
          </div>
        ) : selectedTab === "All" ? (
          <div className={eventPageClasses.noEventContainer}>
            <EmojiSadSlightFilled className={eventPageClasses.noEventIcon} />
            <Text block size={800}>
              Nothing to See Here Just Yet
            </Text>
            <Text block className={eventPageClasses.noEventSubtitle}>
              Be the First to Create an Exciting Event!
            </Text>
          </div>
        ) : selectedTab === 0 ? (
          <div className={eventPageClasses.noEventContainer}>
            <EmojiMehFilled className={eventPageClasses.noEventIcon} />
            <Text block size={800}>
              No Active Events Yet
            </Text>
            <Text block className={eventPageClasses.noEventSubtitle}>
              Perhaps relaxation seem a viable option?
            </Text>
          </div>
        ) : selectedTab === 1 ? (
          <div className={eventPageClasses.noEventContainer}>
            <EmojiLaughFilled className={eventPageClasses.noEventIcon} />
            <Text block size={800}>
              Nothing Left as Pending
            </Text>
            <Text block className={eventPageClasses.noEventSubtitle}>
              Great Job!!... unless you forgot to create a new event.
            </Text>
          </div>
        ) : (
          <div className={eventPageClasses.noEventContainer}>
            <EmojiSurpriseFilled className={eventPageClasses.noEventIcon} />
            <Text block size={800}>
              Nothing is Finished
            </Text>
            <Text block className={eventPageClasses.noEventSubtitle}>
              I wonder why...
            </Text>
          </div>
        )}
      </div>
    </>
  );
};

export default Events;
