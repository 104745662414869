import MainCheckIn from "./checkInPages/MainCheckIn";
import SessionCheckIn from "./checkInPages/SessionCheckIn";
import WorkshopCheckIn from "./checkInPages/WorkshopCheckIn";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Combobox,
  makeStyles,
  Option,
  SelectTabData,
  SelectTabEvent,
  shorthands,
  Tab,
  TabList,
  TabValue,
  tokens,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { ToolboxFilled, CalendarEditFilled } from "@fluentui/react-icons";
import CheckInWorkSpace from "./checkInPages/CheckInWorkSpace";
import { AppsvenueEvent } from "../../data/eventData";
import { getEventsByStatus } from "../../utils/event";

const checkInPageStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "3rem 1fr",
  },
  nav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  sub: {
    display: "grid",
    gridTemplateRows: "10rem 1fr",
    gridGap: tokens.spacingHorizontalL,
    ...shorthands.padding(tokens.spacingHorizontalL, 0),
  },
  outletContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    justifyContent: "end",
    padding: tokens.spacingVerticalXXXL,
    backgroundColor: tokens.colorNeutralBackground2,
  },
});

const CheckInTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedValue, setSelectedValue] = useState<TabValue>(
    location.pathname.substring(19) || ""
  );
  const tabs = [
    { path: "", icon: <CalendarEditFilled />, label: "Main" },
    // { path: "Session", icon: <GroupListFilled />, label: "Session" },
    { path: "Workshop", icon: <ToolboxFilled />, label: "Workshop" },
  ];

  useEffect(() => {
    const path = location.pathname.substring(19);
    if (["", "Session", "Workshop"].includes(path)) setSelectedValue(path);
    else setSelectedValue("Error");
  }, [location.pathname]);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
    navigate(`/Dashboard/CheckIn/${data.value}`);
  };

  return (
    <TabList onTabSelect={onTabSelect} defaultSelectedValue={selectedValue}>
      {tabs.map((tab) => (
        <Tab icon={tab.icon} value={tab.path}>
          {tab.label}
        </Tab>
      ))}
    </TabList>
  );
};

const CheckIn = () => {
  const [events, setEvents] = useState<[AppsvenueEvent] | []>([]);
  const checkInPageClasses = checkInPageStyles();
  useEffect(() => {
    document.title = "Appsvenue Events · Check In";
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [events]);

  const fetchEvents = async () => {
    try {
      const response = await getEventsByStatus(0);
      setEvents(response.data);
    } catch (e: any) {
      console.log("Error:" + e);
    }
  };

  return (
    <div className={checkInPageClasses.root}>
      <div className={checkInPageClasses.nav}>
        <CheckInTabs />
        <Combobox
          placeholder={
            events.length > 0 ? "Select Event" : "No Active Events Available"
          }
          disabled={events.length ? false : true}
        >
          {events.map((event) => (
            <Option value={event._id.$oid}>{event.name.en}</Option>
          ))}
        </Combobox>
      </div>
      <div className={checkInPageClasses.sub}>
        <div
          className={checkInPageClasses.outletContainer}
          style={{
            background: `
            url(https://api.appsvenueevents.com/dist/uploads/wsbg@4000x2250.png) center/cover no-repeat,
            linear-gradient(#00000000,#000)`,
            backgroundBlendMode: "soft-light",
          }}
        >
          <Outlet />
        </div>
        <CheckInWorkSpace />
      </div>
    </div>
  );
};

CheckIn.Main = MainCheckIn;
CheckIn.Session = SessionCheckIn;
CheckIn.Workshop = WorkshopCheckIn;

export default CheckIn;
