import {
  Combobox,
  makeStyles,
  Option,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
  tokens,
} from "@fluentui/react-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Registration from "./editorPages/Registration";
import { useEffect, useState } from "react";
import {
  CertificateFilled,
  FormFilled,
  RibbonFilled,
} from "@fluentui/react-icons";
import MainEditor from "./editorPages/MainEditor";
import { getEventsByStatus } from "../../utils/event";
import { AppsvenueEvent } from "../../data/eventData";

const editorStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "3rem 1fr",
    minheight: "80dvh",
    gridGap: tokens.spacingHorizontalL,
  },

  tabs: { gridArea: "1 / 1 / 2 / 2", display: "flex", alignItems: "center" },
  body: { gridArea: "2 / 1 / 3 / 2" },

  EventComboBox: {
    marginLeft: "auto",
  },
});

const EditorTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    // { path: "", icon: <FormFilled />, label: "Registration" },
    // { path: "CME", icon: <CertificateFilled />, label: "CME Certificate" },
    { path: "", icon: <CertificateFilled />, label: "CME Certificate" },

    { path: "Badge", icon: <RibbonFilled />, label: "Badge" },
  ];
  const [selectedValue, setSelectedValue] = useState<TabValue>(
    location.pathname.substring(18) || ""
  );

  useEffect(() => {
    const path = location.pathname.substring(18);
    if (["", "CME", "Badge"].includes(path)) setSelectedValue(path);
    else setSelectedValue("Error");
  }, [location.pathname]);

  useEffect(() => {
    document.title = "Appsvenue Events · Editor";
  }, []);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
    navigate(`/Dashboard/Editor/${data.value}`);
  };

  return (
    <TabList onTabSelect={onTabSelect} defaultSelectedValue={selectedValue}>
      {tabs.map((tab) => (
        <Tab icon={tab.icon} value={tab.path}>
          {tab.label}
        </Tab>
      ))}
    </TabList>
  );
};

const Editor = () => {
  const editorClass = editorStyles();
  const [events, setEvents] = useState<[AppsvenueEvent] | []>([]);

  useEffect(() => {
    fetchEvents();
  }, [events]);

  const fetchEvents = async () => {
    try {
      const response = await getEventsByStatus(0);
      setEvents(response.data);
    } catch (e: any) {
      console.log("Error:" + e);
    }
  };

  return (
    <div className={editorClass.root}>
      <div className={editorClass.tabs}>
        <EditorTabs />
        <Combobox
          placeholder={
            events.length > 0 ? "Select Event" : "No Active Events Available"
          }
          className={editorClass.EventComboBox}
          disabled={events.length > 0 ? false : true}
        >
          {events?.map((event) => (
            <Option value={event._id.$oid}>{event.name.en}</Option>
          ))}
        </Combobox>
      </div>
      <div className={editorClass.body}>
        <Outlet />
      </div>
    </div>
  );
};

Editor.Registration = Registration;
Editor.MainEditor = MainEditor;

export default Editor;
