import React, { createContext, useState, useContext, ReactNode } from "react";

interface CollapseContextType {
  collapse: boolean;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollapseContext = createContext<CollapseContextType | undefined>(
  undefined
);

export const useCollapse = (): CollapseContextType => {
  const context = useContext(CollapseContext);
  if (!context) {
    throw new Error("useCollapse must be used within a CollapseProvider");
  }
  return context;
};

interface CollapseProviderProps {
  children: ReactNode;
}

const CollapseProvider: React.FC<CollapseProviderProps> = ({ children }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <CollapseContext.Provider value={{ collapse, setCollapse }}>
      {children}
    </CollapseContext.Provider>
  );
};

export default CollapseProvider;
