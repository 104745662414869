import {
  Badge,
  Button,
  Divider,
  Field,
  makeStyles,
  mergeClasses,
  shorthands,
  Text,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import {
  ArrowExitFilled,
  CallFilled,
  CheckmarkCircleFilled,
  CheckmarkFilled,
  DismissCircleFilled,
  Edit16Filled,
  EditFilled,
  MailFilled,
  PrintFilled,
  QrCodeFilled,
} from "@fluentui/react-icons";
import { Children, cloneElement, FC, isValidElement, ReactNode } from "react";

const cardStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    boxShadow: tokens.shadow2,
    gap: tokens.spacingHorizontalL,
    padding: tokens.spacingHorizontalXL,
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.margin(tokens.spacingHorizontalL, 0),
    borderRadius: `calc(${tokens.spacingHorizontalXXXL} - 20px)`,
  },
  qrLabel: {
    letterSpacing: tokens.spacingVerticalXS,
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingHorizontalXS,
  },

  headerContainer: {
    marginTop: tokens.spacingHorizontalXXL,
  },

  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  jobTitleText: {
    color: tokens.colorNeutralForeground3,
  },
  workplaceText: {
    color: tokens.colorNeutralForeground4,
  },
  buttonContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: tokens.spacingVerticalS,
  },
  statusContainer: {
    textAlign: "end",
    letterSpacing: "0.05rem",
  },
  contactContainer: {
    display: "grid",
    gridTemplateColumns: "2rem 1fr 3rem",
    alignItems: "center",
    color: tokens.colorNeutralForeground2,
  },
  allSelector: {
    userSelect: "all",
  },
  attendanceContainer: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingHorizontalS,
    padding: tokens.spacingHorizontalM,
    backgroundColor: tokens.colorNeutralBackground2Hover,
    borderRadius: `calc(20px - ${tokens.spacingHorizontalM})`,
  },
  sideEventDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sideEventIcon: { fontSize: tokens.fontSizeBase400 },
  sideEventCheckedIn: {
    color: tokens.colorStatusSuccessForeground3,
  },
  sideEventNotCheckedIn: {
    color: tokens.colorStatusDangerForeground3,
  },
  sessionDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const CheckInInfoCard = () => {
  const cardClasses = cardStyles();
  const sampleName = "Title. Full Name ";
  return (
    <div className={cardClasses.root}>
      <Badge
        className={cardClasses.qrLabel}
        icon={<QrCodeFilled />}
        size="extra-large"
      >
        QRCODE-1
      </Badge>
      <div
        className={mergeClasses(
          cardClasses.textContainer,
          cardClasses.headerContainer
        )}
      >
        <div className={cardClasses.nameContainer}>
          <Tooltip
            relationship="label"
            content={
              <div className={cardClasses.allSelector}>{sampleName}</div>
            }
          >
            <Text weight="bold" size={600} block>
              {sampleName.length <= 32
                ? sampleName
                : sampleName.slice(0, 30) + " ..."}
            </Text>
          </Tooltip>
          <Tooltip relationship="label" content="Edit Name" positioning="after">
            <Button appearance="subtle" icon={<EditFilled />} />
          </Tooltip>
        </div>
        <Text
          className={cardClasses.jobTitleText}
          weight="regular"
          size={500}
          block
        >
          Job Title
        </Text>
        <Text
          className={cardClasses.workplaceText}
          weight="regular"
          size={400}
          block
        >
          Workplace
        </Text>
      </div>
      <div className={cardClasses.textContainer}>
        <div className={cardClasses.contactContainer}>
          <CallFilled />
          <Text className={cardClasses.allSelector} block>
            +000 0000 0000
          </Text>
          <Tooltip relationship="label" content="Edit Phone Number">
            <Button icon={<Edit16Filled />} size="small" appearance="subtle" />
          </Tooltip>
        </div>
        <div className={cardClasses.contactContainer}>
          <MailFilled />
          <Text className={cardClasses.allSelector} block>
            username@mailservice.com
          </Text>
          <Tooltip
            relationship="label"
            positioning="below"
            content="Edit Mail Address"
          >
            <Button icon={<Edit16Filled />} size="small" appearance="subtle" />
          </Tooltip>
        </div>
      </div>
      <Divider />

      <Field
        hint={
          <div className={cardClasses.statusContainer}>
            Checked in at DD/MM/YYYY, HH:MM AA
          </div>
        }
      >
        <div className={cardClasses.buttonContainer}>
          <Button size="large" icon={<PrintFilled />} appearance="secondary">
            Print Badge
          </Button>
          <Button
            size="large"
            icon={
              <>
                <CheckmarkFilled />
                {/* <ArrowExitFilled /> */}
              </>
            }
            appearance="primary"
          >
            Check In
            {/* Check Out */}
          </Button>
        </div>
      </Field>
      <AttendanceSection>
        <AttendenceContent name="SideEvent Name 1" checkedIn />
        <AttendenceContent name="SideEvent Name 2" />
      </AttendanceSection>

      {/* <AttendanceSection contentTypes="session">
        <AttendenceContent name="SideEvent Name 1" checkedIn />
        <AttendenceContent name="SideEvent Name 2" />
      </AttendanceSection> */}
    </div>
  );
};

declare type SubType = "side" | "session";

interface AttendanceSectionProps {
  contentTypes?: SubType;
  children: ReactNode;
}

interface AttendenceContentProps {
  name?: string;
  contentTypes?: SubType;
  checkedIn?: boolean;
  time?: string;
}

const AttendanceSection: FC<AttendanceSectionProps> = ({
  children,
  contentTypes = "side",
}) => {
  const { attendanceContainer } = cardStyles();
  return (
    <div className={attendanceContainer}>
      <Divider alignContent="start">
        {contentTypes === "side" ? "Side Events" : "Session Log"}
      </Divider>
      {Children.map(children, (child) => {
        if (isValidElement<AttendenceContentProps>(child)) {
          return cloneElement<AttendenceContentProps>(child, { contentTypes });
        }
        return child;
      })}
    </div>
  );
};

const AttendenceContent: FC<AttendenceContentProps> = ({
  name,
  contentTypes = "side",
  checkedIn,
  time,
}) => {
  const {
    sideEventDisplay,
    sideEventIcon,
    sideEventCheckedIn,
    sideEventNotCheckedIn,
    sessionDisplay,
  } = cardStyles();

  switch (contentTypes) {
    case "side":
      return (
        <div className={sideEventDisplay}>
          <Text>{name}</Text>
          <Tooltip
            relationship="label"
            positioning="before"
            content={checkedIn ? "Checked In" : "Not Checked In"}
          >
            {checkedIn ? (
              <CheckmarkCircleFilled
                className={mergeClasses(sideEventIcon, sideEventCheckedIn)}
              />
            ) : (
              <DismissCircleFilled
                className={mergeClasses(sideEventIcon, sideEventNotCheckedIn)}
              />
            )}
          </Tooltip>
        </div>
      );
    case "session":
      return (
        <div className={sessionDisplay}>
          <Text>
            {checkedIn ? (
              <Text style={{ color: tokens.colorStatusSuccessForeground3 }}>
                Checked In
              </Text>
            ) : (
              <Text style={{ color: tokens.colorStatusDangerForeground3 }}>
                Checked Out
              </Text>
            )}
          </Text>
          <Text>HH:MM AA</Text>
        </div>
      );
  }
};

export default CheckInInfoCard;
