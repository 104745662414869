import {
  Button,
  Label,
  makeStyles,
  Select,
  tokens,
} from "@fluentui/react-components";
import { MoreHorizontalFilled } from "@fluentui/react-icons";
import { FC } from "react";

const paginationStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subRoot: {
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingHorizontalS,
  },
  numberContent: {
    textAlign: "center",
    fontSize: tokens.fontSizeBase200,
    fontFamily: tokens.fontFamilyNumeric,
    fontWeight: tokens.fontWeightBold,
  },
});

const Pagination: FC<PaginationProps> = (props) => {
  const paginationClasses = paginationStyles();

  const handleFirstPage = () => props.onPageChange(1);
  const handlePreviousPage = () => props.onPageChange(props.currentPage - 1);
  const handleNextPage = () => props.onPageChange(props.currentPage + 1);
  const handleLastPage = () => props.onPageChange(props.totalPages);

  const renderButtons = () => {
    const buttons = [];
    buttons.push(
      <Button
        key="1"
        shape="circular"
        onClick={() => props.onPageChange(1)}
        appearance={props.currentPage === 1 ? "primary" : "secondary"}
        icon={<span className={paginationClasses.numberContent}>1</span>}
        // disabled={props.currentPage === 1}
      />
    );
    if (props.currentPage > 3)
      buttons.push(
        <Button
          key="morePrev"
          shape="circular"
          appearance="subtle"
          icon={<MoreHorizontalFilled />}
          disabled
        />
      );

    const startPage = Math.max(2, props.currentPage - 1);
    const endPage = Math.min(props.totalPages - 1, props.currentPage + 1);
    for (let page = startPage; page <= endPage; page++)
      buttons.push(
        <Button
          key={page}
          shape="circular"
          onClick={() => props.currentPage !== page && props.onPageChange(page)}
          appearance={props.currentPage === page ? "primary" : "secondary"}
          icon={<span className={paginationClasses.numberContent}>{page}</span>}
          // disabled={props.currentPage === page}
        />
      );

    if (props.currentPage < props.totalPages - 2)
      buttons.push(
        <Button
          key="moreAfter"
          shape="circular"
          appearance="subtle"
          icon={<MoreHorizontalFilled />}
          disabled
        />
      );

    if (props.totalPages > 1)
      buttons.push(
        <Button
          key={props.totalPages}
          shape="circular"
          onClick={() => props.onPageChange(props.totalPages)}
          appearance={
            props.currentPage === props.totalPages ? "primary" : "secondary"
          }
          icon={
            <span className={paginationClasses.numberContent}>
              {props.totalPages}
            </span>
          }
          // disabled={props.currentPage === props.totalPages}
        />
      );

    return buttons;
  };

  return (
    <div className={paginationClasses.root}>
      <div className={paginationClasses.subRoot}>
        <Label>Number of pages visible per page:</Label>
        <Select
          onChange={(e) => props.setContentLimit(parseInt(e.target.value))}
          value={props.contentLimit}
          appearance="filled-lighter"
          disabled={props.eventLength < 10}
        >
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Select>
      </div>
      <div className={paginationClasses.subRoot}>
        <Button onClick={handleFirstPage} disabled={props.currentPage === 1}>
          First
        </Button>
        <Button onClick={handlePreviousPage} disabled={props.currentPage === 1}>
          Previous
        </Button>
        {renderButtons()}
        <Button
          onClick={handleNextPage}
          disabled={props.currentPage === props.totalPages}
        >
          Next
        </Button>
        <Button
          onClick={handleLastPage}
          disabled={props.currentPage === props.totalPages}
        >
          Last
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
