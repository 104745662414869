import {
  Caption1,
  makeStyles,
  Text,
  Title1,
  tokens,
} from "@fluentui/react-components";

const successStyles = makeStyles({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    background: "url(/assets/images/loginBG.svg) center/cover",
  },
  container: {
    borderRadius: "20px",
    padding: tokens.spacingHorizontalXXXL,
    backgroundColor: tokens.colorNeutralBackground2,
  },
});

const FreeSuccess = () => {
  const successClasses = successStyles();

  return (
    <div className={successClasses.root}>
      <div className={successClasses.container}>
        <Title1
          align="center"
          style={{
            color: tokens.colorBrandForeground1,
            marginBottom: tokens.spacingHorizontalL,
          }}
          block
        >
          Registration Successful
        </Title1>
        <Caption1 align="center" block>
          Check your email for your registered ID with QR.
        </Caption1>
        <Text
          size={300}
          style={{
            marginTop: tokens.spacingHorizontalS,
            textTransform: "uppercase",
          }}
          align="center"
          block
        >
          See you at the event
        </Text>
      </div>
    </div>
  );
};

export default FreeSuccess;
