import axios from "axios";
// import dotenv from "dotenv";

// dotenv.config();

export const baseURL = "https://api.appsvenueevents.com";

export const token = localStorage.getItem("token");

export const createInstance = (path: any) => {
  const instance = axios.create({
    baseURL: baseURL + path,
  });
  // console.log(instance);
  return instance;
};
