import {
  Caption1,
  Caption2,
  makeStyles,
  Text,
  Title1,
  tokens,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addAudience, checkPay } from "../../utils";
import { payData } from "../../data";

const successStyles = makeStyles({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    background: "url(/assets/images/loginBG.svg) center/cover",
  },
  container: {
    borderRadius: "20px",
    padding: tokens.spacingHorizontalXXXL,
    backgroundColor: tokens.colorNeutralBackground2,
  },
});

const Success = () => {
  const successClasses = successStyles();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get("paymentId");

  useEffect(() => {
    const verifyPayment = async () => {
      if (!paymentId) {
        setError("Payment ID is missing.");
        setLoading(false);
        return;
      }

      try {
        const response = await checkPay(paymentId);
        const data = await response.data;

        if (data.message === "Payment is successful") {
          setPaymentStatus("Payment Successful");
          const regData = localStorage.getItem("audienceData");
          const payMode = localStorage.getItem("paymentMode");
          const id = localStorage.getItem("id");
          const optedSE = localStorage.getItem("optedSideEvent");

          if (optedSE) {
            if (regData !== null && id !== null && optedSE !== null) {
              const response = await addAudience(
                JSON.parse(regData),
                id,
                JSON.parse(optedSE)
              );
            }
          } else {
            if (regData !== null && id !== null) {
              const response = await addAudience(JSON.parse(regData), id);
            }
          }

          console.log("success");
        } else {
          setPaymentStatus("Payment Failed");
          navigate("/failed");
        }
      } catch (err) {
        setError("Error verifying payment.");
      } finally {
        setLoading(false);
      }
    };

    verifyPayment();
  }, []);

  return (
    <div className={successClasses.root}>
      <div className={successClasses.container}>
        <Title1
          align="center"
          style={{
            color: tokens.colorBrandForeground1,
            marginBottom: tokens.spacingHorizontalL,
          }}
          block
        >
          Payment Successful
        </Title1>
        <Caption1 align="center" block>
          Check your email for invoice and your registered ID with QR.
        </Caption1>
        <Text
          size={300}
          style={{
            marginTop: tokens.spacingHorizontalS,
            textTransform: "uppercase",
          }}
          align="center"
          block
        >
          See you at the event
        </Text>
      </div>
    </div>
  );
};

export default Success;
