import {
  Badge,
  Button,
  Checkbox,
  Combobox,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Display,
  Divider,
  Input,
  Label,
  Link,
  makeStyles,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  SearchBox,
  Spinner,
  Subtitle2,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnSizingOptions,
  Textarea,
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  ToastTrigger,
  tokens,
  Toolbar,
  ToolbarButton,
  Tooltip,
  useToastController,
} from "@fluentui/react-components";
import {
  ArrowExportUpFilled,
  CheckmarkCircleFilled,
  CheckmarkFilled,
  DismissCircleFilled,
  DismissFilled,
  FilterFilled,
  SendFilled,
} from "@fluentui/react-icons";
import { useId, useState } from "react";

const sampleAudienceData: AudienceData[] = [
  {
    _id: { $oid: "21345wtrgfvdsadfw4qwv" },
    title: "Dr.",
    name: { first: "Mahmoud Sobhi Fares", last: "Elmansi" },
    regID: { label: "", image: "" },
    contact: { phone: { did: "96958421" }, email: "Mahelmansy@yahoo.com" },
    workplace: "Maternity Hospital",
    jobTitle: "Doctor",
    attendance: { status: false },
    mailingStatus: false,
  },
  {
    _id: { $oid: "asdsasdasdeqwewsdas" },
    title: "Prof.",
    name: { first: "Tais", last: "Mohammed" },
    regID: { label: "", image: "" },
    contact: {
      phone: { did: "65508404" },
      email: "taiseerabdulnaser@gmail.com",
    },
    workplace: "Farwanyh",
    jobTitle: "Professor",
    attendance: { status: true, date: "2024-11-29T10:30" },
    mailingStatus: true,
  },
];

const columns: TableColumnDefinition<AudienceData>[] = [
  createTableColumn<AudienceData>({
    columnId: "name",
    renderHeaderCell: () => {
      return "Name";
    },
    compare: (a, b) => {
      return a.name.first.localeCompare(b.name.first);
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {`${item.title} ${item.name.first} ${item.name.last}`}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<AudienceData>({
    columnId: "phone",
    renderHeaderCell: () => {
      return "Phone";
    },
    compare: (a, b) => {
      var compareA = a.contact.phone.country_code || "+965";
      var compareB = b.contact.phone.country_code || "+965";
      return compareA.localeCompare(compareB);
    },
    renderCell: (item) => {
      return `${item.contact.phone.country_code || "+965"} ${
        item.contact.phone.did
      }`;
    },
  }),
  createTableColumn<AudienceData>({
    columnId: "attendance",
    renderHeaderCell: () => {
      return "Attendance";
    },

    renderCell: (item) => {
      return (
        <TableCellLayout>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: tokens.spacingVerticalS,
            }}
          >
            {item.attendance.status ? (
              <CheckmarkFilled
                style={{ color: tokens.colorStatusSuccessForeground1 }}
              />
            ) : (
              <DismissFilled
                style={{ color: tokens.colorStatusDangerForeground1 }}
              />
            )}
            {item.attendance.date
              ? `${item.attendance.date
                  .split("T")[0]
                  .slice(8, 10)}/${item.attendance.date
                  .split("T")[0]
                  .slice(5, 7)}/${item.attendance.date
                  .split("T")[0]
                  .slice(0, 4)} ${item.attendance.date.split("T")[1]}`
              : "Not Attended"}
          </div>
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<AudienceData>({
    columnId: "mailStatus",
    renderHeaderCell: () => {
      return "Mail Status";
    },
    // compare: (a, b) => {
    //   return a.mailingStatus.localeCompare(b.mailingStatus);
    // },
    renderCell: (item) => {
      return (
        <TableCellLayout>
          {item.mailingStatus ? (
            <Badge
              style={{ width: "6rem", justifyContent: "left" }}
              size="extra-large"
              color="brand"
              icon={<CheckmarkCircleFilled />}
            >
              Sent
            </Badge>
          ) : (
            <Badge
              style={{ width: "6rem", justifyContent: "left" }}
              size="extra-large"
              color="informative"
              icon={<DismissCircleFilled />}
            >
              Not Sent
            </Badge>
          )}
        </TableCellLayout>
      );
    },
  }),
];

const columnSizingOptions: TableColumnSizingOptions = {
  name: { idealWidth: 300, minWidth: 200 },
  phone: { idealWidth: 275, minWidth: 275 },
  attendance: { idealWidth: 150, minWidth: 150 },
  mailStatus: { idealWidth: 200, minWidth: 200 },
};

const smsStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: tokens.spacingVerticalXXL,
  },
  headerText: { display: "block" },
  subTitleText: { color: tokens.colorNeutralForeground2 },
  main: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridGap: tokens.spacingHorizontalL,
  },
  smsForm: {
    display: "grid",
    position: "sticky",
    top: tokens.spacingHorizontalL,
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: tokens.spacingHorizontalM,
    boxShadow: tokens.shadow4,
    padding: tokens.spacingHorizontalL,
    borderRadius: tokens.spacingHorizontalL,
    backgroundColor: tokens.colorNeutralBackground2,
  },
  fieldSpan: {
    gridColumn: "span 2",
  },
  submitBadgeNumber: {
    marginLeft: tokens.spacingVerticalS,
    fontFamily: tokens.fontFamilyNumeric,
  },
  audienceContent: {
    borderRadius: "20px",
    boxShadow: tokens.shadow4,
    height: "fit-content",
  },
  audienceGridToolbar: {
    borderRadius: "20px 20px 0 0",
    padding: tokens.spacingHorizontalL,
    justifyContent: "space-between",
    backgroundColor: tokens.colorNeutralBackground2,
  },
  bottomToolbar: {
    borderRadius: "0 0 20px 20px",
  },
});

const SMS = () => {
  const smsClasses = smsStyles();
  const [open, setOpen] = useState(false);
  const toasterId = useId();
  const { dispatchToast } = useToastController(toasterId);

  const notify = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          media={<Spinner size="tiny" />}
          action={
            <ToastTrigger>
              <Link>Cancel</Link>
            </ToastTrigger>
          }
        >
          Exporting as Spreadsheet
        </ToastTitle>
        <ToastBody>This will take few minute...</ToastBody>
      </Toast>
    );

  const handleOpenChange = (e: any, data: { open: boolean }) => {
    if (!data.open) {
      e.preventDefault();
    } else {
      setOpen(data.open);
    }
  };
  return (
    <>
      <Toaster toasterId={toasterId} position="top-end" />
      <div className={smsClasses.header}>
        <div className={smsClasses.headerText}>
          <Display block>SMS</Display>
          <Subtitle2 block className={smsClasses.subTitleText}>
            Sending SMS to an event's audience.
          </Subtitle2>
        </div>
      </div>

      <div className={smsClasses.main}>
        <div className={smsClasses.smsForm}>
          <Input
            size="large"
            placeholder="Sender's Name"
            className={smsClasses.fieldSpan}
          />
          <Textarea
            placeholder="Content"
            rows={10}
            size="large"
            className={smsClasses.fieldSpan}
          />
          <Button
            size="large"
            appearance="primary"
            className={smsClasses.fieldSpan}
            icon={<SendFilled />}
          >
            Send{" "}
            <Badge
              size="large"
              color="subtle"
              appearance="tint"
              className={smsClasses.submitBadgeNumber}
            >
              0
            </Badge>
          </Button>
        </div>
        <div className={smsClasses.audienceContent}>
          <Toolbar className={smsClasses.audienceGridToolbar}>
            <SearchBox
              appearance="filled-lighter"
              placeholder="Search Audience"
              style={{ width: "15rem" }}
            />
            <div>
              <Tooltip relationship="label" content="Export">
                <ToolbarButton
                  onClick={notify}
                  icon={<ArrowExportUpFilled />}
                />
              </Tooltip>
              <Popover
                open={open}
                onOpenChange={handleOpenChange}
                positioning="below"
                closeOnIframeFocus={false}
              >
                <PopoverTrigger disableButtonEnhancement>
                  <Tooltip relationship="label" content="Filter">
                    <ToolbarButton
                      icon={<FilterFilled />}
                      onClick={() => setOpen(!open)}
                    />
                  </Tooltip>
                </PopoverTrigger>
                <PopoverSurface>
                  <Subtitle2>Filter By</Subtitle2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: tokens.spacingVerticalL,
                      padding: `${tokens.spacingVerticalL} 0`,
                    }}
                  >
                    <Label>Mail Status</Label>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        gap: tokens.spacingHorizontalM,
                      }}
                    >
                      <Checkbox name="fees" label="Free" />
                      <Checkbox name="fees" label="Paid" />
                    </div>
                    <Label>Status</Label>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        gap: tokens.spacingHorizontalM,
                      }}
                    >
                      <Checkbox name="status" label={"Attended"} />
                      <Checkbox name="status" label={"Not Attended"} />
                    </div>
                  </div>
                  <Divider
                    style={{ padding: `${tokens.spacingHorizontalS} 0` }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: tokens.spacingHorizontalS,
                    }}
                  >
                    <Button appearance="primary">Apply Filter</Button>
                    <Button>Reset</Button>
                  </div>
                </PopoverSurface>
              </Popover>
            </div>
            <Combobox placeholder="Select Event" appearance="filled-lighter" />
          </Toolbar>
          <DataGrid
            columns={columns}
            columnSizingOptions={columnSizingOptions}
            items={sampleAudienceData}
            sortable
            resizableColumns
            selectionAppearance="neutral"
            selectionMode="multiselect"
            getRowId={(item) => item._id.$oid}
            resizableColumnsOptions={{ autoFitColumns: true }}
            focusMode="composite"
            // className={eventPageClasses.datagridWidth}
          >
            <DataGridHeader
              style={{ backgroundColor: tokens.colorNeutralBackground2 }}
              // className={eventPageClasses.datagridHeader}
            >
              <DataGridRow
                selectionCell={{
                  checkboxIndicator: { "aria-label": "Select all rows" },
                }}
              >
                {({ renderHeaderCell, columnId }) => (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )}
              </DataGridRow>
            </DataGridHeader>
            <DataGridBody<AudienceData>
              style={{ backgroundColor: tokens.colorNeutralBackground1 }}
            >
              {({ item, rowId }) => (
                <DataGridRow<AudienceData>
                  key={rowId}
                  selectionCell={{
                    checkboxIndicator: { "aria-label": "Select row" },
                  }}
                >
                  {({ renderCell }) => (
                    <DataGridCell>{renderCell(item)}</DataGridCell>
                  )}
                </DataGridRow>
              )}
            </DataGridBody>
          </DataGrid>
          <Toolbar
            className={mergeClasses(
              smsClasses.audienceGridToolbar,
              smsClasses.bottomToolbar
            )}
          ></Toolbar>
        </div>
      </div>
    </>
  );
};

export default SMS;
