import {
  Display,
  Divider,
  Field,
  makeStyles,
  Select,
  Subtitle2,
  Title1,
  tokens,
} from "@fluentui/react-components";

import { useEffect } from "react";
import { statusData } from "../../data";
import { StatusCard } from "../../components";
import { eventStatus } from "../../data/statusData";

const homeStyle = makeStyles({
  headerText: {
    display: "block",
  },
  subtitleText: {
    display: "block",
    color: tokens.colorNeutralForeground2,
  },
  mainGrid: {
    margin: tokens.spacingVerticalXXXL,
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridGap: tokens.spacingHorizontalL,
  },
  eventStatusGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    padding: `${tokens.spacingVerticalXXXL} ${tokens.spacingHorizontalXS}`,
    gridGap: tokens.spacingHorizontalL,
  },
  homeDividers: {
    paddingTop: tokens.spacingVerticalXXXL,
  },
});

const Home = () => {
  const homeClasses = homeStyle();

  const regCardData = eventStatus.registrationStatus;
  const audienceData = eventStatus.audience;

  useEffect(() => {
    document.title = "Appsvenue Events · Home";
  }, []);
  return (
    <div>
      <Display className={homeClasses.headerText}>Home</Display>
      <Subtitle2 className={homeClasses.subtitleText}>In A Nutshell</Subtitle2>

      {/* Main Status Section */}
      <div className={homeClasses.mainGrid}>
        {statusData.map((stats) => (
          <StatusCard
            title={stats.label}
            color={stats.color}
            count={stats.status}
            icon={stats.icon}
          />
        ))}
      </div>

      {/* Event Status Section */}
      <Divider alignContent="start" className={homeClasses.homeDividers}>
        <Title1>Event Status</Title1>
      </Divider>
      <div style={{ padding: tokens.spacingHorizontalXXL }}>
        <Field label="Event Name" size="large">
          <Select>
            <option>Event 1</option>
            <option>Event 2</option>
            <option>Event 3</option>
            <option>Event 4</option>
            <option>Event 5</option>
          </Select>
        </Field>
        <div className={homeClasses.eventStatusGrid}>
          <StatusCard
            title={regCardData.label}
            icon={regCardData.icon}
            count={regCardData.status.active.label}
            statusIcon={regCardData.status.active.icon}
            statusColor={regCardData.status.active.color}
            bigIcon
          />
          <StatusCard
            title={audienceData.expectedAudience.label}
            icon={audienceData.expectedAudience.icon}
            count={audienceData.expectedAudience.count}
            bigIcon
          />
          <StatusCard
            title={audienceData.attendedAudience.label}
            icon={audienceData.attendedAudience.icon}
            count={audienceData.attendedAudience.count}
            bigIcon
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
