import {
  CompoundButton,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Aside, Footer } from "../components";
import { Outlet, useNavigate } from "react-router-dom";
import Home from "./dbPages/Home";
import Events from "./dbPages/Events";
import Settings from "./dbPages/Settings";
import { useCollapse } from "../context/CollapseProvider";
import { AddFilled } from "@fluentui/react-icons";
import { useRef } from "react";
import AdvancedEvents from "./dbPages/AdvancedEvents";
import AudienceCards from "./dbPages/AudienceCards";
import Editor from "./dbPages/Editor";
import CheckIn from "./dbPages/CheckIn";
import Mailing from "./dbPages/Mailing";
import SMS from "./dbPages/SMS";

const dashboardDesign = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "15rem 1fr",
    height: "100dvh",
    maxHeight: "100dvh",
    backgroundColor: tokens.colorNeutralBackground3,
    overflow: "none",
    transition: "all 0.3s",
  },

  containerShrinked: {
    gridTemplateColumns: "5rem 1fr",
  },

  // header: {
  //   display: "flex",
  //   alignItems: "center",
  //   boxShadow: tokens.shadow28,
  //   gap: tokens.spacingVerticalL,
  //   zIndex: "3",
  //   backgroundColor: tokens.colorBrandBackground3Static,
  //   color: tokens.colorNeutralForegroundStaticInverted,
  //   ...shorthands.gridArea(1, 1, 2, 3),
  //   ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
  // },
  aside: {
    display: "flex",
    flexDirection: "column",
    boxShadow: tokens.shadow28,
    zIndex: "2",
    backgroundColor: tokens.colorBrandStroke2Pressed,
    color: tokens.colorNeutralForegroundStaticInverted,
    ...shorthands.gridArea(1, 1, 2, 2),
    ...shorthands.padding(
      tokens.spacingVerticalXXL,
      0,
      tokens.spacingVerticalXXL,
      0
    ),
    margin: tokens.spacingHorizontalL,
    borderRadius: "15px",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gridArea(1, 2, 2, 3),
    overflowY: "auto",
  },
});

const Dashboard = () => {
  const navigate = useNavigate();
  const dashboardClass = dashboardDesign();
  const mainRef = useRef(null);
  const { collapse, setCollapse } = useCollapse();
  const toggleSidenav = () => {
    setCollapse(!collapse);
  };
  return (
    <div
      className={mergeClasses(
        dashboardClass.container,
        collapse && dashboardClass.containerShrinked
      )}
    >
      {/* <Header
        classProps={dashboardClass.header}
        menuButtonAction={() => toggleSidenav()}
      /> */}
      <Aside
        menuButtonAction={toggleSidenav}
        classProps={dashboardClass.aside}
        sidenavStatus={collapse}
      />
      <main ref={mainRef} className={dashboardClass.main}>
        <div style={{ padding: tokens.spacingVerticalXXXL }}>
          <Outlet />
        </div>
        <Footer contentRef={mainRef} />
      </main>
      <CompoundButton
        onClick={() => navigate("EventForm")}
        appearance="primary"
        icon={<AddFilled />}
        style={{
          position: "fixed",
          bottom: tokens.spacingVerticalXXL,
          right: tokens.spacingHorizontalXXXL,
          boxShadow: tokens.shadow64,
          borderRadius: "15px",
        }}
        size="large"
        secondaryContent="Create new event"
      >
        New Event
      </CompoundButton>
    </div>
  );
};

Dashboard.Home = Home;
Dashboard.Events = Events;
Dashboard.Settings = Settings;
Dashboard.AdvancedEvents = AdvancedEvents;
Dashboard.AudienceCards = AudienceCards;
Dashboard.Editor = Editor;
Dashboard.CheckIn = CheckIn;
Dashboard.Mailing = Mailing;
Dashboard.SMS = SMS;

export default Dashboard;
