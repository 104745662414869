import { Combobox, Option } from "@fluentui/react-components";

const SessionCheckIn = () => {
  return (
    <Combobox
      appearance="filled-lighter"
      defaultSelectedOptions={["1"]}
      defaultValue={"Session I : Session Name"}
    >
      <Option value="1">Session I : Session Name</Option>
      <Option value="2">Session II : Session Name</Option>
      <Option value="3">Session III : Session Name</Option>
    </Combobox>
  );
};

export default SessionCheckIn;
