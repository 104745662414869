import {
  Input,
  makeStyles,
  SearchBox,
  shorthands,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSelectionCell,
  Text,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import { CheckInInfoCard } from "../../../components";
import {
  CheckmarkCircleFilled,
  DismissCircleFilled,
  PeopleSearchFilled,
} from "@fluentui/react-icons";

const workspaceStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    minHeight: "calc(100vh - 17rem)",
    gridGap: tokens.spacingVerticalL,
  },
  qrSection: {
    display: "flex",
    position: "sticky",
    height: "fit-content",
    top: tokens.spacingHorizontalL,
    borderRadius: "20px",
    flexDirection: "column",
    boxShadow: tokens.shadow4,
    backgroundColor: tokens.colorNeutralBackground2,
    gap: tokens.spacingHorizontalM,
    ...shorthands.padding(
      tokens.spacingHorizontalL,
      tokens.spacingHorizontalXXXL
    ),
  },

  statusCell: {
    textAlign: "center",
    alignItems: "center",
  },

  searchSection: {
    display: "flex",
    borderRadius: "20px",
    flexDirection: "column",
    boxShadow: tokens.shadow4,
    ...shorthands.padding(
      tokens.spacingHorizontalL,
      tokens.spacingHorizontalXXXL
    ),
    gap: tokens.spacingHorizontalL,
    padding: tokens.spacingHorizontalL,
    backgroundColor: tokens.colorNeutralBackground2,
  },

  sideEventCheckedIn: {
    color: tokens.colorStatusSuccessForeground3,
  },
  sideEventNotCheckedIn: {
    color: tokens.colorStatusDangerForeground3,
  },
  notSearchedMsgContainer: {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: tokens.spacingHorizontalM,
  },

  notSearchedMsgIcon: {
    fontSize: "10rem",
    color: tokens.colorNeutralBackground1Selected,
  },

  notSearchedMsgSubtitle: {
    color: tokens.colorNeutralForeground3,
  },
});

const CheckInWorkSpace = () => {
  const workspaceClasses = workspaceStyles();
  return (
    <div className={workspaceClasses.root}>
      <div className={workspaceClasses.qrSection}>
        <Input
          type="text"
          size="large"
          appearance="underline"
          placeholder="Registration Code"
        />
        <CheckInInfoCard />
      </div>
      <div className={workspaceClasses.searchSection}>
        <SearchBox
          size="large"
          placeholder="Search"
          appearance="filled-lighter"
        />
        {/* <Table>
          <TableHeader>
            <TableRow>
              <TableSelectionCell type="radio" invisible />
              <TableHeaderCell>QR Code</TableHeaderCell>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Phone</TableHeaderCell>
              <TableHeaderCell>E-mail</TableHeaderCell>
              <TableHeaderCell className={workspaceClasses.statusCell}>
                Last Status
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableSelectionCell
                type="radio"
                radioIndicator={{ "aria-label": "Select row" }}
              />
              <TableCell>QRC-1</TableCell>
              <TableCell>Title. Full Name</TableCell>
              <TableCell>+000 0000 0000</TableCell>
              <TableCell>username@mailservice.com</TableCell>
              <TableCell>
                <TableCellLayout
                  className={workspaceClasses.statusCell}
                  media={
                    <Tooltip relationship="label" content="Checked In">
                      <CheckmarkCircleFilled
                        className={workspaceClasses.sideEventCheckedIn}
                      />
                    </Tooltip>
                  }
                >
                  HH:MM AA
                </TableCellLayout>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableSelectionCell
                type="radio"
                radioIndicator={{ "aria-label": "Select row" }}
              />
              <TableCell>QRC-2</TableCell>
              <TableCell>Title. Full Name 2</TableCell>
              <TableCell>+000 0000 0000</TableCell>
              <TableCell>username2@mailservice.com</TableCell>
              <TableCell>
                <TableCellLayout
                  className={workspaceClasses.statusCell}
                  media={
                    <Tooltip relationship="label" content="Not Checked In">
                      <DismissCircleFilled
                        className={workspaceClasses.sideEventNotCheckedIn}
                      />
                    </Tooltip>
                  }
                >
                  HH:MM AA
                </TableCellLayout>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableSelectionCell
                type="radio"
                radioIndicator={{ "aria-label": "Select row" }}
              />
              <TableCell>QRC-3</TableCell>
              <TableCell>Title. Full Name 3</TableCell>
              <TableCell>+000 0000 0000</TableCell>
              <TableCell>username3@mailservice.com</TableCell>
              <TableCell>
                <TableCellLayout
                  className={workspaceClasses.statusCell}
                  media={
                    <Tooltip relationship="label" content="Not Checked In">
                      <DismissCircleFilled
                        className={workspaceClasses.sideEventNotCheckedIn}
                      />
                    </Tooltip>
                  }
                >
                  Not Attended
                </TableCellLayout>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table> */}
        <div className={workspaceClasses.notSearchedMsgContainer}>
          <PeopleSearchFilled className={workspaceClasses.notSearchedMsgIcon} />
          <Text size={600}>Search For Audience</Text>
          <Text size={300} className={workspaceClasses.notSearchedMsgSubtitle}>
            Enter name, email, and phone.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default CheckInWorkSpace;
