import { tokens } from "@fluentui/react-components";

const KNETIcon = () => {
  return (
    <svg
      id="Layer_8"
      style={{
        fill: tokens.colorBrandBackground,
        width: tokens.fontSizeHero900,
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 202.39 148.75"
    >
      <path d="M191.46,18.82v39.38c0,4.95-4.01,8.96-8.96,8.96H19.89c-4.95,0-8.96-4.01-8.96-8.96V18.82c0-4.95,4.01-8.96,8.96-8.96h162.61c4.95,0,8.96,4.01,8.96,8.96ZM78.43,13.78h-43.17v46.31h43.17v-21.98l27.86,21.19h60.83l-32.95-24.72,30.6-20.02h-56.91c-2.66,0-24.51,16.7-29.43,18.05V13.78Z" />
      <path d="M191.46,75.77v21.23c0,3.46-2.8,6.26-6.26,6.26H17.19c-3.46,0-6.26-2.8-6.26-6.26v-21.23c0-3.46,2.8-6.26,6.26-6.26h168c3.46,0,6.26,2.8,6.26,6.26ZM19.96,96.2h68.68v-11.77c0-.08-1.36.33-1.58-.51-.3-1.15.32-4.68.02-4.99-.19-.19-5.31-.19-5.5,0-.31.31.32,5.21.01,5.5-.8.76-4.28-2.13-3.15,3.92-2.79.76-1.06-2.63-1.56-3.14-.23-.24-5.8-.16-6.61,0-1,.19-.22,3.16-.45,3.16H25.84c1.2-3.05,4.24-7.48,4.72-10.6.25-1.61-.15-1.02-1.16-1.17-2.19-.33-4.95-.34-6.76,1.11-.55.44-7.83,9.92-8.21,10.68-.57,1.12,4.71,7.6,5.53,7.83ZM178.12,76.57c-1.66-1.05-22.82.79-26.69,0,.16,1.64,7.61,9.42,8.24,9.42h16.09v1.57h-16.09c-.59,0-4.07-4.86-5.66-5.33-2.82-.84-7.36.26-10.44-.16v10.99h-17.27v-2.35h14.91v-9.42h-26.69v17.27h36.11c.35,0-.27-6.19,0-7.06,1.31.81,1.77,1.4,3.48,1.62,3.27.42,26.63.35,28.66-.47.69-.28,5.21-4.02,5.3-4.67.17-1.22-5.27-5.11-5.67-5.11h-16.09v-.78h14.13c.38-.76-2.1-5.33-2.35-5.49ZM38.41,78.92h-6.29v5.5h6.29v-5.5ZM44.69,78.92h-5.5v5.5h5.5v-5.5ZM106.69,86.78h-14.13v5.49h14.13v-5.49ZM159.28,94.62h-4.72v3.93h4.72v-3.93ZM167.92,94.62h-3.93v3.93h3.93v-3.93Z" />
      <path d="M191.46,113.38v18.22c0,4.29-3.48,7.76-7.76,7.76H18.7c-4.29,0-7.76-3.48-7.76-7.76v-18.22c0-4.29,3.48-7.76,7.76-7.76h165c4.29,0,7.76,3.48,7.76,7.76ZM160.85,118.17c.45.41,0,10.42,0,12.17,0,3.78,8.29,4.32,10.99,2.74-.51-.92-.64-4.53-.79-4.71-.14-.16-3.59,1.11-3.96-.5-.19-.84-.28-9.33.07-9.67.67-.64,5.24,1.15,3.9-4.74-6.83,1.03-4.38-1.65-4.72-7.06-1.34,1.04-4.52,1.73-5.5,2.73-1.4,1.43-.38,3.78-.87,4.28-.29.3-1.88-.17-2.16.1-.18.17-.27,4.5-.1,4.66.28.27,2.88-.26,3.15,0ZM31.34,112.68v20.41h6.28v-12.95c0-3.37,7.06-3.28,7.06-.78v13.74h7.06c-2.71-5.81,2.89-18.94-5.93-20.36-3.53-.57-6.3.01-8.97,2.31.83-3.83-2.6-2.03-5.5-2.36ZM110.61,125.24c2.12-15.86-21.29-17.49-21.2-1.95.07,11.45,18.03,14.15,20.42,3.53-1.77.59-4.51-1.03-5.77-.85-.54.08-4.15,7.61-7.57-.73h14.13Z" />
      <path d="M103.34,118.39c.43.4,1.37,2.57.99,2.92h-7.84c.83-3.13,4.09-5.49,6.86-2.92Z" />
    </svg>
  );
};

const VMIcon = () => {
  // VISA / MASTERCARD
  return (
    <svg
      id="Layer_8"
      style={{
        fill: tokens.colorBrandBackground,
        width: tokens.fontSizeHero900,
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 202.39 148.75"
    >
      <path d="M136.68,131.26c17.77-14.13,17.76-41.87,0-55.99,24-19.03,59.08-.47,57.42,30.13-1.54,28.39-35.23,43.79-57.42,25.86Z" />
      <path d="M136.4,75.28c-17.89,14.14-17.89,41.85,0,55.99-19.98,16.13-50.54,5.4-56.47-19.45-7.87-33,29.94-57.33,56.47-36.54Z" />
      <path
        style={{ fill: tokens.colorBrandBackground2 }}
        d="M136.68,131.26h-.28c-17.89-14.14-17.89-41.84,0-55.99h.28c17.76,14.12,17.77,41.86,0,55.99Z"
      />
      <path d="M4.04,21.66h17.14c1.85.22,3.27,1.79,3.64,3.56l4.13,20.93,9.65-24.48h10.08l-15.69,36.89c-1.43-.25-9.31.38-9.81-.3l-8.09-30.8c-.56-1.17-2.19-1.86-3.37-2.38-2.43-1.07-5.08-1.73-7.68-2.25v-1.15Z" />
      <path d="M87.57,58.53l14.06-33.9c1.87-4.88,8.2-2.35,12.26-2.99l7.66,36.88h-8.5c-.77-.62-.95-5.12-1.32-5.44l-12.18-.05-1.9,5.49h-10.08ZM110.03,45.28l-2.74-13.54-4.75,13.54h7.49Z" />
      <path d="M89.87,22.53c-.39,1.04-1.33,7.64-1.89,7.75-.22.05-3.57-1.2-4.38-1.35-2.21-.41-5.52-.52-7.48.73-5.21,3.33,5.81,7.39,7.83,9,3.85,3.06,5.19,7.06,3.46,11.8-3.22,8.82-15.43,9.62-23.24,7.7-1.33-.33-3.2-.44-3.09-2.03.14-2.18,1.41-4.8,1.42-7.1,3.29,1.69,12.73,3.96,15.11.28,2.61-4.04-5.99-6.61-8.46-8.51-8.06-6.19-4.02-15.8,4.52-18.76,5.2-1.8,11.07-1.34,16.19.51Z" />
      <polygon points="62.22 21.66 54.45 58.53 44.94 58.53 52.72 21.66 62.22 21.66" />
    </svg>
  );
};

const payData = [
  {
    id: "1",
    label: "KNET",
    icon: KNETIcon,
  },
  {
    id: "2",
    label: "VISA / MASTERCARD",
    icon: VMIcon,
  },
];

export default payData;
