import { createInstance, token } from "./api";

const instance = createInstance("/event");

// Post

export const createEvent = async (formData: FormData) => {
  const response = await instance.post("/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// Patch
export const editEventName = async (en: any, ar: any, eventID: any) => {
  const response = await instance.patch(
    `/edit/name/${eventID}`,
    {
      en,
      ar,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};

export const editEventFee = async (editedFee: any, eventID: any) => {
  const response = await instance.patch(
    `/edit/fee/${eventID}`,
    {
      editedFee,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};

// Get

export const getEvents = async (page: any, limit: any) => {
  const response = await instance.get(
    `/get/all?page=${page}&contentLimit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getEventsByStatus = async (status: any) => {
  const response = await instance.get(`/get/allByStatus?status=${status}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};

export const getEventByID = async (eventID: any) => {
  const response = await instance.get(`/get/byId?eventID=${eventID}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};
