import {
  Badge,
  Button,
  Display,
  FluentProvider,
  Image,
  makeStyles,
  Text,
  tokens,
} from "@fluentui/react-components";
import { ArrowLeftFilled } from "@fluentui/react-icons";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../context/ThemeProvider";

const errorPageStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100dvh",
    background: "url(/assets/images/bg404.svg) 0%/cover fixed no-repeat",

    boxShadow: tokens.shadow8,
    gap: tokens.spacingVerticalS,
  },
  fourO4img: {
    width: "20rem",
    height: "20rem",
  },
  logoImg: {
    width: "10rem",
    height: "10rem",
  },
  fourO4Message: {
    fontFamily: tokens.fontFamilyMonospace,
    textTransform: "uppercase",
    color: tokens.colorBrandForeground2Hover,
    marginTop: "-7.5rem",
  },
  fourO4url: {
    fontSize: tokens.fontSizeBase500,
  },
  fourO4btn: {
    marginTop: tokens.spacingVerticalXXXL,
  },
});

const ErrorPage = () => {
  const { darkTheme } = useTheme();
  const errorPageClasses = errorPageStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const pathURL = location.pathname;

  useEffect(() => {}, []);

  return (
    <FluentProvider theme={darkTheme}>
      <div className={errorPageClasses.root}>
        <Image
          className={errorPageClasses.fourO4img}
          fit="contain"
          src="/assets/images/404.svg"
        />
        <Display className={errorPageClasses.fourO4Message}>
          Page Not Found
        </Display>
        <Badge
          className={errorPageClasses.fourO4url}
          appearance="filled"
          size="extra-large"
          color="important"
        >
          {pathURL}
        </Badge>
        <Text align="center" size={600}>
          {" "}
          no longer exist or moved to another page.
        </Text>
        <Button
          onClick={() => navigate("/")}
          className={errorPageClasses.fourO4btn}
          size="large"
          appearance="secondary"
          icon={<ArrowLeftFilled />}
        >
          Head Back to Login
        </Button>
        <Image
          src="/assets/images/404LogoIcon.svg"
          className={errorPageClasses.logoImg}
        />
      </div>
    </FluentProvider>
  );
};

export default ErrorPage;
