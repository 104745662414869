import { Title1, tokens } from "@fluentui/react-components";

const MainCheckIn = () => {
  return (
    <Title1 style={{ color: tokens.colorNeutralForegroundStaticInverted }}>
      Main Event Check In
    </Title1>
  );
};

export default MainCheckIn;
