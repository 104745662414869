import { Combobox, Option } from "@fluentui/react-components";
import React from "react";

const WorkshopCheckIn = () => {
  return (
    <Combobox
      appearance="filled-lighter"
      defaultSelectedOptions={["1"]}
      defaultValue={"Side Event 1"}
    >
      <Option value="1">Side Event 1</Option>
      <Option value="2">Side Event 2</Option>
      <Option value="3">Side Event 3</Option>
    </Combobox>
  );
};

export default WorkshopCheckIn;
