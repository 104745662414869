import {
  Button,
  Checkbox,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  // Dialog,
  // DialogActions,
  // DialogBody,
  // DialogContent,
  // DialogSurface,
  // DialogTitle,
  // DialogTrigger,
  Display,
  Divider,
  Field,
  Label,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  SearchBox,
  shorthands,
  Spinner,
  Subtitle2,
  Switch,
  TableColumnDefinition,
  TableColumnId,
  TableColumnSizingOptions,
  Text,
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  ToastTrigger,
  tokens,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  Tooltip,
  useToastController,
} from "@fluentui/react-components";
import {
  ArrowExportUpFilled,
  CheckmarkCircleFilled,
  ClockFilled,
  ColumnEditFilled,
  DeleteFilled,
  EmojiSadSlightFilled,
  ErrorCircleFilled,
  EyeFilled,
  EyeOffFilled,
  FilterFilled,
  LiveFilled,
} from "@fluentui/react-icons";

import { useEffect, useId, useState } from "react";
import { AppsvenueEvent } from "../../data/eventData";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { DatagridCell, Pagination } from "../../components";
import { getEvents } from "../../utils/event";

const eventPageStyle = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: tokens.spacingVerticalXXL,
  },
  headerText: {
    display: "block",
  },
  subtitleText: {
    display: "block",
    color: tokens.colorNeutralForeground2,
  },
  main: {
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: tokens.shadow2,
    borderRadius: "15px",
  },
  toolbar: {
    padding: "1rem 20px",
    gap: tokens.spacingHorizontalXS,
    backgroundColor: tokens.colorNeutralBackground2,
    paddingBottom: tokens.spacingHorizontalM,
  },

  toolbarTop: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },

  toolbarBottom: {
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },

  toolbarButtonGap: {
    display: "flex",
    marginLeft: "auto",
    gap: tokens.spacingHorizontalXS,
  },
  deleteButton: {
    color: tokens.colorStatusDangerForeground1,
    backgroundColor: "transparent",
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground3,
      color: tokens.colorNeutralForegroundStaticInverted,
    },
  },
  paginationButton: {
    border: "none",
    backgroundColor: "transparent",
    color: tokens.colorNeutralForeground1,
    ...shorthands.padding(tokens.spacingHorizontalS, tokens.spacingHorizontalL),
    borderRadius: tokens.borderRadiusCircular,
    fontFamily: tokens.fontFamilyNumeric,
    fontSize: tokens.fontSizeBase200,
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground2Hover,
    },
  },
  paginationButtonActive: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundStaticInverted,
    fontWeight: tokens.fontWeightBold,
  },
  searchBox: {
    width: "20rem",
  },
  deleteDialogTitle: {
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingHorizontalXS,
  },
  datagridWidth: {
    width: "100%",
  },
  datagridHeader: { backgroundColor: tokens.colorNeutralBackground2 },
  noEventContainer: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: tokens.spacingHorizontalL,
  },
  noEventIcon: {
    color: tokens.colorNeutralBackground1Selected,
    fontSize: "10rem",
  },
  noEventSubtitle: {
    color: tokens.colorNeutralForeground4,
  },
});

const columns: TableColumnDefinition<AppsvenueEvent>[] = [
  createTableColumn<AppsvenueEvent>({
    columnId: "name",
    renderHeaderCell: () => {
      return "Event Name";
    },
    compare: (a, b) => {
      return a.name.en.localeCompare(b.name.en);
    },
    renderCell: (item) => {
      return <DatagridCell.Name cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "prefix",
    renderHeaderCell: () => {
      return "Event Prefix";
    },
    compare: (a, b) => {
      return a.prefix.localeCompare(b.prefix);
    },
    renderCell: (item) => {
      return <DatagridCell.Prefix cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "description",
    renderHeaderCell: () => {
      return "Description";
    },
    renderCell: (item) => {
      return <DatagridCell.Description cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "fees",
    renderHeaderCell: () => {
      return "Fees";
    },
    // compare: (a, b) => {
    //   return a.fee.toString().localeCompare(b.fee.toString());
    // },
    renderCell: (item) => {
      return <DatagridCell.Fees cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "event_duration_start",
    renderHeaderCell: () => {
      return "Event Starts At";
    },
    compare: (a, b) => {
      return a.event_duration.start.localeCompare(b.event_duration.start);
    },
    renderCell: (item) => {
      return <DatagridCell.DurationStart cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "event_duration_end",
    renderHeaderCell: () => {
      return "Event Ends At";
    },
    compare: (a, b) => {
      return a.event_duration.end.localeCompare(b.event_duration.end);
    },
    renderCell: (item) => {
      return <DatagridCell.DurationEnd cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "venue",
    compare: (a, b) => {
      return a.venue.name.localeCompare(b.venue.name);
    },
    renderHeaderCell: () => {
      return "Venue";
    },
    renderCell: (item) => {
      return <DatagridCell.Venue cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "audience",
    renderHeaderCell: () => {
      return "Audience";
    },
    compare: (a, b) => {
      return a.audience.registered
        .toString()
        .localeCompare(b.audience.registered.toString());
    },
    renderCell: (item) => {
      return <DatagridCell.Audience cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "mail_sender",
    renderHeaderCell: () => {
      return "Mail Agent";
    },
    compare: (a, b) => {
      return a.mail_sender.user.localeCompare(b.mail_sender.user);
    },
    renderCell: (item) => {
      return <DatagridCell.MailSender cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "status",
    renderHeaderCell: () => {
      return "Status";
    },
    compare: (a, b) => {
      return a.status.toString().localeCompare(b.status.toString());
    },
    renderCell: (item) => {
      return <DatagridCell.Status cellData={item} />;
    },
  }),
  createTableColumn<AppsvenueEvent>({
    columnId: "actions",
    renderHeaderCell: () => {
      return "Actions";
    },
    renderCell: (item) => {
      return <DatagridCell.Action cellData={item} />;
    },
  }),
];

const columnSizingOptions: TableColumnSizingOptions = {
  name: {
    idealWidth: 180,
    minWidth: 100,
  },
  prefix: {
    minWidth: 75,
    defaultWidth: 75,
  },
  description: {
    minWidth: 75,
    defaultWidth: 75,
  },
  fees: {
    minWidth: 100,
    defaultWidth: 100,
  },
  event_duration_start: {
    minWidth: 125,
    defaultWidth: 125,
  },
  event_duration_end: {
    minWidth: 125,
    defaultWidth: 125,
  },
  venue: {
    minWidth: 150,
    defaultWidth: 150,
  },
  audience: {
    minWidth: 75,
    defaultWidth: 75,
  },
  mail_sender: {
    minWidth: 175,
    defaultWidth: 175,
  },
  status: {
    minWidth: 50,
    defaultWidth: 50,
  },
  actions: {
    minWidth: 200,
    defaultWidth: 200,
  },
};

const AdvancedEvents = () => {
  const eventPageClasses = eventPageStyle();
  const toasterId = useId();
  const { dispatchToast } = useToastController(toasterId);
  const notify = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          media={<Spinner size="tiny" />}
          action={
            <ToastTrigger>
              <Link>Cancel</Link>
            </ToastTrigger>
          }
        >
          Exporting as Spreadsheet
        </ToastTitle>
        <ToastBody>This will take few minute...</ToastBody>
      </Toast>
    );
  const [open, setOpen] = useState(false);

  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [contentLimit, setContentLimit] = useState(10);

  const paginationProps = {
    totalPages,
    currentPage,
    onPageChange: setCurrentPage,
    contentLimit,
    setContentLimit,
    eventLength: events.length,
  };

  useEffect(() => {
    fetchEvents(currentPage, contentLimit);
  }, [currentPage, contentLimit]);

  const fetchEvents = async (page: any, limit: any) => {
    try {
      const response = await getEvents(page, limit);
      setEvents(response.data.events);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log("Error Fetching Events", error);
    }
  };

  const [visibleColumns, setVisibleColumns] = useState<TableColumnId[]>(
    columns.map((col) => col.columnId)
  );

  const handleCheckboxChange = (columnId: string) => {
    setVisibleColumns((prevState) =>
      prevState.includes(columnId)
        ? prevState.filter((id) => id !== columnId)
        : [...prevState, columnId]
    );
  };

  const handleOpenChange = (e: any, data: { open: boolean }) => {
    // Prevent closing when clicking outside
    if (!data.open) {
      e.preventDefault();
    } else {
      setOpen(data.open);
    }
  };

  useEffect(() => {
    document.title = "Appsvenue Events · Event List";
  }, []);

  return (
    <>
      <div className={eventPageClasses.header}>
        <div className={eventPageClasses.headerText}>
          <Display>Event List</Display>
          <Subtitle2 className={eventPageClasses.subtitleText}>
            Managing Events with Efficiency
          </Subtitle2>
        </div>
      </div>

      {events.length > 0 ? (
        <div className={eventPageClasses.main}>
          <Toolbar
            className={mergeClasses(
              eventPageClasses.toolbar,
              eventPageClasses.toolbarTop
            )}
          >
            {/* <SearchBox
              appearance="filled-lighter"
              placeholder="Search"
              className={eventPageClasses.searchBox}
            /> */}
            <div className={eventPageClasses.toolbarButtonGap}>
              <Toaster toasterId={toasterId} position="top-end" />
              <Tooltip relationship="label" content="Export">
                <ToolbarButton
                  onClick={notify}
                  icon={<ArrowExportUpFilled />}
                />
              </Tooltip>
              <ToolbarDivider />
              {/* <Popover
                open={open}
                onOpenChange={handleOpenChange}
                positioning="below-end"
                closeOnIframeFocus={false}
              >
                <PopoverTrigger disableButtonEnhancement>
                  <Tooltip relationship="label" content="Filter">
                    <ToolbarButton
                      icon={<FilterFilled />}
                      onClick={() => setOpen(!open)}
                    />
                  </Tooltip>
                </PopoverTrigger>
                <PopoverSurface>
                  <Subtitle2>Filter By</Subtitle2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: tokens.spacingVerticalL,
                      padding: `${tokens.spacingVerticalL} 0`,
                    }}
                  >
                    <Label>Fees</Label>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        gap: tokens.spacingHorizontalM,
                      }}
                    >
                      <Checkbox name="fees" label="Free" />
                      <Checkbox name="fees" label="Paid" />
                    </div>
                    <Label>Status</Label>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        gap: tokens.spacingHorizontalM,
                      }}
                    >
                      <Checkbox
                        name="status"
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: tokens.spacingHorizontalS,
                            }}
                          >
                            <LiveFilled
                              style={{
                                fontSize: tokens.fontSizeBase400,
                                color: tokens.colorPaletteGreenForeground1,
                              }}
                            />{" "}
                            Active
                          </div>
                        }
                      />
                      <Checkbox
                        name="status"
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: tokens.spacingHorizontalS,
                            }}
                          >
                            <ClockFilled
                              style={{
                                fontSize: tokens.fontSizeBase400,
                                color: tokens.colorPaletteMarigoldForeground1,
                              }}
                            />{" "}
                            Pending
                          </div>
                        }
                      />
                      <Checkbox
                        name="status"
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: tokens.spacingHorizontalS,
                            }}
                          >
                            <CheckmarkCircleFilled
                              style={{
                                fontSize: tokens.fontSizeBase400,
                                color: tokens.colorNeutralForeground3,
                              }}
                            />{" "}
                            Finished
                          </div>
                        }
                      />
                    </div>

                    <Field label="Event Start Range">
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          gap: tokens.spacingHorizontalM,
                        }}
                      >
                        <DatePicker placeholder="From" />
                        <DatePicker placeholder="To" />
                      </div>
                    </Field>
                    <Field label="Event End Range">
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          gap: tokens.spacingHorizontalM,
                        }}
                      >
                        <DatePicker placeholder="From" />
                        <DatePicker placeholder="To" />
                      </div>
                    </Field>

                    <Switch label="Show only events with side event" />
                    <Label>Events with it's Website</Label>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        gap: tokens.spacingHorizontalM,
                      }}
                    >
                      <Checkbox name="eventSite" label="Withoutz event site" />
                      <Checkbox name="eventSite" label="With event site" />
                    </div>
                  </div>
                  <Divider
                    style={{ padding: `${tokens.spacingHorizontalS} 0` }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: tokens.spacingHorizontalS,
                    }}
                  >
                    <Button appearance="primary">Apply Filter</Button>
                    <Button>Reset</Button>
                  </div>
                </PopoverSurface>
              </Popover> */}
              <Menu>
                <MenuTrigger>
                  <Tooltip relationship="label" content="Columns">
                    <ToolbarButton icon={<ColumnEditFilled />} />
                  </Tooltip>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    {columns.map((column) => (
                      <MenuItem
                        persistOnClick
                        defaultChecked={visibleColumns.includes(
                          column.columnId
                        )}
                        secondaryContent={
                          visibleColumns.includes(column.columnId) ? (
                            <Text size={400}>
                              <EyeFilled />
                            </Text>
                          ) : (
                            <Text size={400}>
                              <EyeOffFilled />
                            </Text>
                          )
                        }
                        key={column.columnId.toString()}
                        onClick={() => {
                          handleCheckboxChange(column.columnId.toString());
                        }}
                      >
                        {column.renderHeaderCell()}
                      </MenuItem>
                    ))}
                  </MenuList>
                </MenuPopover>
              </Menu>
              {/* <ToolbarDivider />
              <Dialog>
                <DialogTrigger disableButtonEnhancement>
                  <Tooltip relationship="label" content="Delete">
                    <ToolbarButton
                      appearance="primary"
                      className={eventPageClasses.deleteButton}
                      icon={<DeleteFilled />}
                    />
                  </Tooltip>
                </DialogTrigger>
                <DialogSurface>
                  <DialogBody>
                    <DialogTitle className={eventPageClasses.deleteDialogTitle}>
                      <ErrorCircleFilled
                        fontSize={30}
                        color={tokens.colorStatusDangerForeground1}
                      />{" "}
                      Deleting Event
                    </DialogTitle>
                    <DialogContent>
                      Are you sure you want to delete <b>[Event Name]</b>? All
                      contents will be permanently destroyed.
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger>
                        <Button appearance="primary">No</Button>
                      </DialogTrigger>
                      <Button>Delete</Button>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog> */}
            </div>
          </Toolbar>

          <div>
            <DataGrid
              sortable
              items={events}
              focusMode="cell"
              resizableColumns
              selectionMode="multiselect"
              selectionAppearance="neutral"
              getRowId={(item) => item._id.$oid}
              columnSizingOptions={columnSizingOptions}
              className={eventPageClasses.datagridWidth}
              resizableColumnsOptions={{ autoFitColumns: true }}
              columns={columns.filter((col) =>
                visibleColumns.includes(col.columnId.toString())
              )}
            >
              <DataGridHeader className={eventPageClasses.datagridHeader}>
                <DataGridRow
                  selectionCell={{
                    checkboxIndicator: { "aria-label": "Select all rows" },
                  }}
                >
                  {({ renderHeaderCell, columnId }) => (
                    <DataGridHeaderCell>
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  )}
                </DataGridRow>
              </DataGridHeader>
              <DataGridBody<AppsvenueEvent>>
                {({ item, rowId }) => (
                  <DataGridRow<AppsvenueEvent>
                    key={rowId}
                    selectionCell={{
                      checkboxIndicator: { "aria-label": "Select row" },
                    }}
                  >
                    {({ renderCell }) => (
                      <DataGridCell>{renderCell(item)}</DataGridCell>
                    )}
                  </DataGridRow>
                )}
              </DataGridBody>
            </DataGrid>
          </div>

          <Toolbar
            className={mergeClasses(
              eventPageClasses.toolbar,
              eventPageClasses.toolbarBottom
            )}
          >
            <Pagination {...paginationProps} />
          </Toolbar>
        </div>
      ) : (
        <div className={eventPageClasses.noEventContainer}>
          <EmojiSadSlightFilled className={eventPageClasses.noEventIcon} />
          <Text block size={800}>
            Nothing to See Here Just Yet
          </Text>
          <Text block className={eventPageClasses.noEventSubtitle}>
            Be the First to Create an Exciting Event!
          </Text>
        </div>
      )}
    </>
  );
};

export default AdvancedEvents;
