import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  Field,
  Input,
  makeStyles,
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Select,
  SplitButton,
  Switch,
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  tokens,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  Tooltip,
} from "@fluentui/react-components";
import {
  AlignBottomFilled,
  AlignCenterHorizontalFilled,
  AlignCenterVerticalFilled,
  AlignLeftFilled,
  AlignRightFilled,
  AlignTopFilled,
  ArrowUploadFilled,
  ChevronDownFilled,
  ImageCircleRegular,
  QrCodeFilled,
  TextBoldFilled,
  TextFieldRegular,
  TextItalicFilled,
  TextUnderlineFilled,
} from "@fluentui/react-icons";
import { FC, useState } from "react";
import { InputColor, InputFile } from "../../../components";

const mainEditorStyles = makeStyles({
  root: {
    height: "75vh",
    display: "grid",
    gridTemplateRows: "5rem 1fr",
    gridTemplateColumns: "1fr 4fr",
    gridGap: tokens.spacingHorizontalL,
  },
  toolbar: {
    top: "1rem",
    position: "sticky",
    zIndex: 1,
    borderRadius: "20px",
    gridArea: "1 / 1 / 2 / 3",
    boxShadow: tokens.shadow16,
    backgroundColor: tokens.colorNeutralBackground2,
  },
  main: {
    borderRadius: "20px",
    gridArea: "2 / 2 / 3 / 3",
    boxShadow: tokens.shadow8,
    backgroundColor: tokens.colorNeutralBackground1,
  },
  properties: {
    display: "flex",
    overflowY: "scroll",
    overflowX: "hidden",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    flexDirection: "column",
    gridArea: "2 / 1 / 3 / 2",
    boxShadow: tokens.shadow8,
    gap: tokens.spacingVerticalL,
    padding: tokens.spacingHorizontalXL,
    backgroundColor: tokens.colorNeutralBackground2,
    "::-webkit-scrollbar-track": {
      borderRadius: "20px",
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },
  ExcecutionContainer: {
    marginLeft: "auto",
  },
});

export interface MainEditorProps {
  badge?: boolean;
}

const MainEditor: FC<MainEditorProps> = ({ badge }) => {
  const [toolActive, setToolActive] = useState<"bg" | "tf" | "qr" | undefined>(
    "bg"
  );
  const { root, main, properties } = mainEditorStyles();
  return (
    <div className={root}>
      <EditorHeader
        badge={badge}
        toolActive={toolActive}
        setToolActive={setToolActive}
      />
      <div className={main}></div>
      <div className={properties}>
        <PropertyContent toolActive={toolActive} />
      </div>
    </div>
  );
};

const PropertyContentStyles = makeStyles({
  grid2: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: tokens.spacingHorizontalL,
  },
  field: {
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingVerticalL,
    fontFamily: tokens.fontFamilyMonospace,
  },
  input: {
    width: "5rem",
  },
  panel: {
    padding: tokens.spacingHorizontalL,
  },
  switch: {
    margin: `${tokens.spacingHorizontalM} 0`,
  },
  alignButtonContainer: {
    display: "flex",
    gap: tokens.spacingVerticalS,
    margin: `${tokens.spacingHorizontalL} 0`,
  },
  select: {
    margin: `${tokens.spacingHorizontalL} 0`,
  },
  fontContainer: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    width: "100%",
    gap: tokens.spacingVerticalS,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    margin: `0 0 ${tokens.spacingVerticalL} 0`,
  },
  inputFontFamily: {
    width: "9rem",
  },
  inputFontSize: {
    width: "5rem",
  },
  textPropContainer: {
    display: "flex",
    gap: tokens.spacingHorizontalS,
    margin: `${tokens.spacingVerticalL} 0`,
  },
});

interface PropertyContentProps {
  toolActive: "bg" | "tf" | "qr" | undefined;
}

const PropertyContent: FC<PropertyContentProps> = ({ toolActive }) => {
  const PropertyContentClass = PropertyContentStyles();
  return (
    <>
      <Accordion collapsible multiple>
        {(toolActive === "tf" ||
          toolActive === "qr" ||
          toolActive === undefined) && (
          <AccordionItem value="dimension">
            <AccordionHeader size="large" expandIconPosition="end">
              DIMENSION
            </AccordionHeader>
            <AccordionPanel className={PropertyContentClass.panel}>
              <div className={PropertyContentClass.grid2}>
                <div className={PropertyContentClass.field}>
                  <label htmlFor="X">X</label>
                  <Input
                    appearance="filled-lighter"
                    type="number"
                    defaultValue="0"
                    id="X"
                    className={PropertyContentClass.input}
                  />
                </div>
                <div className={PropertyContentClass.field}>
                  <label htmlFor="Y">Y</label>
                  <Input
                    appearance="filled-lighter"
                    type="number"
                    defaultValue="0"
                    id="Y"
                    className={PropertyContentClass.input}
                  />
                </div>
                {toolActive === "tf" && (
                  <>
                    <div className={PropertyContentClass.field}>
                      <Tooltip relationship="label" content="Width">
                        <label htmlFor="W">W</label>
                      </Tooltip>
                      <Input
                        appearance="filled-lighter"
                        type="number"
                        defaultValue="0"
                        id="W"
                        className={PropertyContentClass.input}
                      />
                    </div>
                    <div className={PropertyContentClass.field}>
                      <Tooltip relationship="label" content="Height">
                        <label htmlFor="H">H</label>
                      </Tooltip>
                      <Input
                        appearance="filled-lighter"
                        type="number"
                        defaultValue="0"
                        id="H"
                        className={PropertyContentClass.input}
                      />
                    </div>
                  </>
                )}
                {toolActive === "qr" && (
                  <div className={PropertyContentClass.field}>
                    <Tooltip relationship="label" content="Size">
                      <label htmlFor="S">S</label>
                    </Tooltip>
                    <Input
                      appearance="filled-lighter"
                      type="number"
                      defaultValue="0"
                      id="S"
                      className={PropertyContentClass.input}
                    />
                  </div>
                )}
              </div>
              {toolActive === "tf" && (
                <Switch
                  label="Lock Aspect Ratio"
                  className={PropertyContentClass.switch}
                />
              )}
              <div className={PropertyContentClass.alignButtonContainer}>
                <Tooltip
                  relationship="label"
                  positioning="below"
                  content="Align Top"
                >
                  <Button icon={<AlignTopFilled />} />
                </Tooltip>
                <Tooltip
                  relationship="label"
                  positioning="below"
                  content="Align Middle"
                >
                  <Button icon={<AlignCenterHorizontalFilled />} />
                </Tooltip>
                <Tooltip
                  relationship="label"
                  positioning="below"
                  content="Align Bottom"
                >
                  <Button icon={<AlignBottomFilled />} />
                </Tooltip>
                <Tooltip
                  relationship="label"
                  positioning="below"
                  content="Align Left"
                >
                  <Button icon={<AlignLeftFilled />} />
                </Tooltip>
                <Tooltip
                  relationship="label"
                  positioning="below"
                  content="Align Center"
                >
                  <Button icon={<AlignCenterVerticalFilled />} />
                </Tooltip>
                <Tooltip
                  relationship="label"
                  positioning="below"
                  content="Align Right"
                >
                  <Button icon={<AlignRightFilled />} />
                </Tooltip>
              </div>
            </AccordionPanel>
          </AccordionItem>
        )}
        {toolActive === "bg" && (
          <AccordionItem value="background">
            <AccordionHeader size="large" expandIconPosition="end">
              BACKGROUND
            </AccordionHeader>
            <AccordionPanel className={PropertyContentClass.panel}>
              <InputFile dimension="upload" />
              <Select
                appearance="filled-lighter"
                className={PropertyContentClass.select}
              >
                <option>Cover</option>
                <option>Fit</option>
              </Select>
            </AccordionPanel>
          </AccordionItem>
        )}
        {(toolActive === "tf" || toolActive === undefined) && (
          <>
            <AccordionItem value="parameters">
              <AccordionHeader size="large" expandIconPosition="end">
                PARAMETERS
              </AccordionHeader>
              <AccordionPanel className={PropertyContentClass.panel}>
                <Field label={"Field Parameters"}>
                  <TagPicker appearance="filled-lighter">
                    <TagPickerControl style={{ minWidth: "12rem" }}>
                      <TagPickerGroup aria-label="Selected Employees">
                        <Tag
                          key={"option"}
                          shape="rounded"
                          media={""}
                          value={"option"}
                        ></Tag>
                      </TagPickerGroup>
                      <TagPickerInput aria-label="Select Employees" />
                    </TagPickerControl>
                    <TagPickerList>
                      <TagPickerOption
                        secondaryContent="Parameter"
                        media={""}
                        value={"option"}
                        key={"option"}
                      >
                        {"option"}
                      </TagPickerOption>
                    </TagPickerList>
                  </TagPicker>
                </Field>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="typography">
              <AccordionHeader size="large" expandIconPosition="end">
                TYPOGRAPHY
              </AccordionHeader>
              <AccordionPanel className={PropertyContentClass.panel}>
                <div className={PropertyContentClass.buttonContainer}>
                  <Button icon={<ArrowUploadFilled />} size="large">
                    Upload Font
                  </Button>
                </div>
                <div className={PropertyContentClass.fontContainer}>
                  <Input
                    appearance="filled-lighter"
                    className={PropertyContentClass.inputFontFamily}
                    contentAfter={
                      <Menu positioning="below-end">
                        <MenuTrigger>
                          <Button
                            appearance="transparent"
                            icon={<ChevronDownFilled />}
                          />
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem>Font 1</MenuItem>
                            <MenuItem>Font 2</MenuItem>
                            <MenuItem>Font 3</MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    }
                  />
                  <Input
                    appearance="filled-lighter"
                    className={PropertyContentClass.inputFontSize}
                    contentAfter={
                      <Menu positioning="below-end">
                        <MenuTrigger>
                          <Button
                            appearance="transparent"
                            icon={<ChevronDownFilled />}
                          />
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem>8</MenuItem>
                            <MenuItem>9</MenuItem>
                            <MenuItem>10</MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    }
                  />
                </div>
                <div className={PropertyContentClass.textPropContainer}>
                  <Tooltip relationship="label" content="Bold">
                    <Button appearance="subtle" icon={<TextBoldFilled />} />
                  </Tooltip>
                  <Tooltip relationship="label" content="Italic">
                    <Button appearance="subtle" icon={<TextItalicFilled />} />
                  </Tooltip>
                  <Tooltip relationship="label" content="Underline">
                    <Button
                      appearance="subtle"
                      icon={<TextUnderlineFilled />}
                    />
                  </Tooltip>
                  <InputColor />
                </div>
              </AccordionPanel>
            </AccordionItem>
          </>
        )}
      </Accordion>
    </>
  );
};

export interface EditorHeaderProps {
  badge?: boolean;
  toolActive: "bg" | "tf" | "qr" | undefined;
  setToolActive: any;
}

const EditorHeader: FC<EditorHeaderProps> = ({
  badge,
  toolActive,
  setToolActive,
}) => {
  const { toolbar, ExcecutionContainer } = mainEditorStyles();
  const setActive = (selectedTool: "bg" | "tf" | "qr" | undefined) => {
    setToolActive(selectedTool);
  };
  return (
    <Toolbar size="large" className={toolbar}>
      <ToolbarButton
        appearance={toolActive === "bg" ? "primary" : "subtle"}
        icon={<ImageCircleRegular />}
        vertical
        onClick={() => {
          setActive("bg");
        }}
      >
        Background
      </ToolbarButton>
      <ToolbarButton
        appearance={toolActive === "tf" ? "primary" : "subtle"}
        icon={<TextFieldRegular />}
        vertical
        onClick={() => {
          setActive("tf");
        }}
      >
        Text Field
      </ToolbarButton>
      {badge && (
        <>
          <ToolbarDivider />
          <ToolbarButton
            appearance={toolActive === "qr" ? "primary" : "subtle"}
            icon={<QrCodeFilled />}
            vertical
            onClick={() => {
              setActive("qr");
            }}
          >
            QR Code
          </ToolbarButton>
        </>
      )}
      <Tooltip
        relationship="label"
        content={`Deselect all toolbar button when individual text is focused in the container
           | undefined is a tentative value which will be modified based on active 
           valueset added in future based on editor interface requirement`}
      >
        <Button
          onClick={() => {
            setActive(undefined);
          }}
        >
          Undefined (Test Button)
        </Button>
      </Tooltip>
      <div className={ExcecutionContainer}>
        <Menu positioning="below-end">
          <MenuTrigger disableButtonEnhancement>
            {(triggerProps: MenuButtonProps) => (
              <SplitButton appearance="primary" menuButton={triggerProps}>
                Publish
              </SplitButton>
            )}
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem>Save Draft</MenuItem>
              <MenuItem>Discard</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </Toolbar>
  );
};

export default MainEditor;
