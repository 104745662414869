import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  makeStaticStyles,
  makeStyles,
  mergeClasses,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
  Text,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserMenu from "./UserMenu";
import { navData } from "../data";

export interface ContentProps {
  classProps: any;
  sidenavStatus: boolean;
  menuButtonAction: any;
}

const AsideStyles = makeStaticStyles({
  ".aside .fui-Tab__icon, .fui-AccordionHeader__icon,.aside .fui-AccordionHeader__expandIcon":
    {
      color: `${tokens.colorNeutralForegroundStaticInverted} !important`,
    },
  ".hidden .fui-AccordionHeader__expandIcon": {
    display: "none",
  },
  ".fui-AccordionPanel": {
    margin: 0,
    display: "block",
    overflowX: "hidden",
  },

  ".fui-Tab::after": {
    backgroundColor: tokens.colorBrandForegroundInverted,
  },
});

const spanProps = makeStyles({
  root: {
    opacity: 1,
    display: "inline",
    color: tokens.colorNeutralForegroundStaticInverted,
    transition: "all 0.3s",
  },
  hidden: {
    opacity: 0,
    display: "none",
  },
});

const Aside: FC<ContentProps> = (props) => {
  AsideStyles();
  const spanClass = spanProps();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedValue, setSelectedValue] = useState<TabValue>(
    location.pathname.substring(11) || ""
  );

  useEffect(() => {
    const path = location.pathname.substring(11);
    if (["", "Events"].includes(path)) setSelectedValue(path);
    else setSelectedValue("Other");
  }, [location.pathname]);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
    navigate(`/Dashboard/${data.value}`);
  };

  return (
    <aside className={`${props.classProps} aside`}>
      <div onClick={props.menuButtonAction}>
        {props.sidenavStatus ? (
          <Tooltip relationship="label" positioning="after" content="Expand">
            <div
              style={{
                height: "7.75rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/assets/images/appsvenueLogoCollapsed.svg"
                style={{ height: "2rem" }}
                alt="toggle"
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip relationship="label" positioning="after" content="Collapse">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <img
                src="/assets/images/appsvenueLogo.svg"
                style={{ height: "8rem" }}
                alt="svg"
              />
              <Text
                style={{
                  fontSize: tokens.fontSizeBase600,
                  margin: "-1.5rem 0 0 0",
                  textAlign: "center",
                }}
              >
                Events
              </Text>
            </div>
          </Tooltip>
        )}
      </div>
      <hr
        style={{
          margin: tokens.spacingHorizontalL,
          border: "none",
          borderBottom: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralForegroundStaticInverted}`,
        }}
      />
      <TabList
        vertical={true}
        onTabSelect={onTabSelect}
        defaultSelectedValue={selectedValue}
        size="large"
      >
        {navData.map((nav) => {
          if (nav.type === "link")
            return (
              <Tooltip
                content={nav.label}
                relationship="label"
                positioning="after"
              >
                <Tab value={nav.path} icon={nav.icon}>
                  <span
                    className={mergeClasses(
                      spanClass.root,
                      props.sidenavStatus && spanClass.hidden
                    )}
                  >
                    {nav.label}
                  </span>
                </Tab>
              </Tooltip>
            );
          else
            return (
              <Accordion collapsible>
                <AccordionItem value="1">
                  <Tooltip
                    relationship="label"
                    content={nav.label}
                    positioning="after"
                  >
                    <AccordionHeader
                      size="large"
                      className={props.sidenavStatus ? "hidden" : ""}
                      expandIconPosition="end"
                      icon={nav.icon}
                    >
                      <span
                        className={mergeClasses(
                          spanClass.root,
                          props.sidenavStatus && spanClass.hidden
                        )}
                      >
                        {nav.label}
                      </span>
                    </AccordionHeader>
                  </Tooltip>

                  <AccordionPanel>
                    {nav.content?.map((content) => (
                      <Tooltip
                        relationship="label"
                        content={content.label}
                        positioning="after"
                      >
                        <Tab value={content.path} icon={content.icon}>
                          <span
                            className={mergeClasses(
                              spanClass.root,
                              props.sidenavStatus && spanClass.hidden
                            )}
                          >
                            {content.label}
                          </span>
                        </Tab>
                      </Tooltip>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            );
        })}
      </TabList>

      <hr
        style={{
          border: "none",
          borderBottom: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralForegroundStaticInverted}`,
          margin: tokens.spacingHorizontalL,
          marginTop: "auto",
        }}
      />
      <UserMenu sidenavStatus={props.sidenavStatus} />
    </aside>
  );
};

export default Aside;
