import { createInstance } from "./api";
// import dotenv from "dotenv";

// dotenv.config();

const instance = createInstance("/auth");

export const loginResponse = async (username: string, password: string) => {
  const response = await instance.post("/login", { username, password });
  return response;
};

export const registerResponse = async (
  uname: string,
  pass: string,
  utype: number,
  rmail: string
) => {
  const response = await instance.post("/register", {
    uname,
    pass,
    utype,
    rmail,
  });
  return response;
};
