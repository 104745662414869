import {
  Button,
  Combobox,
  Field,
  Input,
  makeStyles,
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  Option,
  shorthands,
  SplitButton,
  Switch,
  Text,
  tokens,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  ToolbarGroup,
  ToolbarProps,
  ToolbarToggleButton,
  Tooltip,
} from "@fluentui/react-components";
import {
  AddFilled,
  BriefcaseFilled,
  CallFilled,
  CheckboxCheckedRegular,
  ChevronCircleDownRegular,
  ChevronUpDownFilled,
  ContactCardFilled,
  DeleteFilled,
  DismissFilled,
  IconsFilled,
  MailFilled,
  RadioButtonFilled,
  RenameRegular,
  SettingsFilled,
} from "@fluentui/react-icons";
import { FC, useState } from "react";
// import { InputColor } from "../../../components";
// import PhoneInput from "../../components/PhoneInput";

const registrationStyles = makeStyles({
  root: {
    display: "grid",
    // gridTemplateColumns: "2fr 1fr",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "5rem 1fr",
    minHeight: "100dvh",
  },
  toolbar: {
    backgroundColor: tokens.colorNeutralBackground2,
    boxShadow: tokens.shadow16,
    gridArea: "1 / 1 / 2 / 3",
    position: "sticky",
    top: "1rem",
    zIndex: "1",
    borderRadius: "20px",
  },
  editor: {
    backgroundColor: tokens.colorNeutralBackground2,
    boxShadow: tokens.shadow4,
    gridArea: "2 / 1 / 3 / 2",
    ...shorthands.margin(tokens.spacingVerticalL, 0, 0, 0),
    borderRadius: "20px",
  },

  previewContainer: {
    gridArea: "2 / 2 / 3 / 3",

    ...shorthands.padding(
      tokens.spacingVerticalL,
      0,
      0,
      tokens.spacingVerticalL
    ),
  },
  preview: {
    backgroundColor: tokens.colorNeutralBackground2,
    padding: tokens.spacingHorizontalL,
    boxShadow: tokens.shadow4,
    height: "80vh",
    position: "sticky",
    top: "7rem",
    borderRadius: "20px",
  },
  ExcecutionContainer: {
    display: "flex",
    gap: tokens.spacingHorizontalL,
    marginLeft: "auto",
  },
});

const EditorCardStyle = makeStyles({
  cui_EditorCard: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    padding: tokens.spacingHorizontalXXXL,
    backgroundColor: tokens.colorNeutralBackground1,
    borderBottom: `${tokens.colorNeutralBackground2Hover} solid ${tokens.strokeWidthThin}`,
    ":first-child": {
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
    },
    ":last-child": {
      borderBottomLeftRadius: "20px",
      borderBottomRightRadius: "20px",
      borderBottom: "none",
    },
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },
  },
  cui_EditorCard__StatusDanger: {
    backgroundColor: tokens.colorStatusDangerBackground1,
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground2,
    },
  },
  cui_EditorCard__ListAlign: {
    alignItems: "start",
  },
  removeButton: {
    color: tokens.colorStatusDangerForeground1,
    border: "none",
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground3,
      color: tokens.colorNeutralForegroundStaticInverted,
    },
  },

  removeButton_start: {
    position: "relative",
    top: `-0.5rem`,
  },

  dragger: {
    cursor: "grab",
    position: "relative",
    fontSize: tokens.fontSizeBase400,
    color: tokens.colorNeutralForeground3,
    // margin: "auto 0",
  },
  message: {
    display: "block",
  },

  fieldLabel: {
    width: "185px",
    display: "flex",
    alignItems: "center",
    gap: tokens.spacingVerticalS,
  },

  presetDetail: {
    color: tokens.colorNeutralForeground3,
    width: "250px",
    // textAlign: "center",
  },
  parameterContainer: {
    display: "flex",
    gap: tokens.spacingVerticalL,
    alignItems: "center",
  },
  parameterFieldWidth: {
    minWidth: "200px",
  },
  requiredSwitchAlign: {
    position: "relative",
    top: tokens.spacingHorizontalM,
  },
  requiredSwitchAlignList: {
    position: "relative",
    top: tokens.spacingHorizontalXXL,
  },
  choiceList: {
    width: "250px",
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
  },
  dangerDismiss: {
    ":hover": {
      color: tokens.colorStatusDangerForeground1,
    },
  },
});

export interface EditorCardPresetProps {
  type?: "preset";
  preset: "name" | "phone" | "mail" | "workspace";
  multiType?: never;
}

export interface EditorCardRegProps {
  type?: "inputType";
  preset?: never;
  multiType?: never;
}

export interface EditorCardMultiProps {
  type?: "multiChoice";
  preset?: never;
  multiType?: "droplist" | "mcq" | "checklist";
}

export type EditorCardProps =
  | EditorCardPresetProps
  | EditorCardRegProps
  | EditorCardMultiProps;

const EditorCard: FC<EditorCardProps> = ({
  type = "preset",
  preset,
  multiType = "checklist",
}) => {
  const EditorCardClass = EditorCardStyle();
  return (
    <div
      className={mergeClasses(
        EditorCardClass.cui_EditorCard,
        // EditorCardClass.cui_EditorCard__StatusDanger,
        type === "multiChoice" && EditorCardClass.cui_EditorCard__ListAlign
      )}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: tokens.spacingHorizontalXXXL,
        }}
      >
        <Tooltip relationship="label" content="Move">
          <ChevronUpDownFilled className={EditorCardClass.dragger} />
        </Tooltip>
        {type !== "preset" && (
          <Tooltip relationship="label" content="Remove">
            <Button
              className={mergeClasses(
                EditorCardClass.removeButton,
                type === "multiChoice" && EditorCardClass.removeButton_start
              )}
              style={{ marginLeft: "-0.5rem" }}
              icon={<DeleteFilled />}
            />
          </Tooltip>
        )}
      </div>
      {type === "preset" ? (
        <Text
          className={mergeClasses(
            EditorCardClass.message,
            EditorCardClass.fieldLabel
          )}
        >
          {preset === "name" ? (
            <Text className={EditorCardClass.fieldLabel}>
              <ContactCardFilled /> Name
            </Text>
          ) : preset === "phone" ? (
            <Text className={EditorCardClass.fieldLabel}>
              <CallFilled />
              Phone Number
            </Text>
          ) : preset === "mail" ? (
            <Text className={EditorCardClass.fieldLabel}>
              <MailFilled />
              Email
            </Text>
          ) : (
            <Text className={EditorCardClass.fieldLabel}>
              <BriefcaseFilled />
              Workplace
            </Text>
          )}
        </Text>
      ) : type === "multiChoice" ? (
        <Field label="Field Name" required>
          <Input defaultValue="Custom Field" placeholder="Enter field name" />
        </Field>
      ) : (
        <Field label="Field Name" required>
          <Input defaultValue="Custom Field" placeholder="Enter field name" />
        </Field>
      )}
      {type === "preset" ? (
        <Text
          className={mergeClasses(
            EditorCardClass.message,
            EditorCardClass.presetDetail
          )}
        >
          {preset === "name"
            ? "Standard Name field. Includes title, firstname and last name fields"
            : preset === "phone"
            ? "Standard phone number field. Comes with country code dropdown and phone number input"
            : preset === "mail"
            ? "Provides mail field with a dropdown for popular services"
            : "Workspace field provides both inputs for workplace and job title"}
        </Text>
      ) : type === "multiChoice" ? (
        <Field label="List">
          <div className={EditorCardClass.choiceList}>
            <Input
              contentAfter={
                <Tooltip
                  relationship="label"
                  content="Remove"
                  positioning="after"
                >
                  <Button
                    className={EditorCardClass.dangerDismiss}
                    icon={<DismissFilled />}
                    appearance="transparent"
                  />
                </Tooltip>
              }
              placeholder="Enter choice Name"
            />
            <Input
              contentAfter={
                <Tooltip
                  relationship="label"
                  content="Remove"
                  positioning="after"
                >
                  <Button
                    className={EditorCardClass.dangerDismiss}
                    icon={<DismissFilled />}
                    appearance="transparent"
                  />
                </Tooltip>
              }
              placeholder="Enter choice Name"
            />
            <Input
              contentAfter={
                <Tooltip
                  relationship="label"
                  content="Remove"
                  positioning="after"
                >
                  <Button
                    className={EditorCardClass.dangerDismiss}
                    icon={<DismissFilled />}
                    appearance="transparent"
                  />
                </Tooltip>
              }
              placeholder="Enter choice Name"
            />
            <Button appearance="secondary" icon={<AddFilled />}>
              Add More Choice
            </Button>
          </div>
        </Field>
      ) : (
        <Field label="Parameters">
          <div className={EditorCardClass.parameterContainer}>
            <Combobox
              className={EditorCardClass.parameterFieldWidth}
              placeholder="Select / type a parameter"
            >
              <Option>Option 1</Option>
              <Option>Option 2</Option>
              <Option>Option 3</Option>
            </Combobox>
            <Tooltip relationship="label" content="Configure">
              <Button icon={<SettingsFilled />} disabled />
            </Tooltip>
          </div>
        </Field>
      )}
      {type !== "preset" ? (
        <Switch
          className={
            type === "inputType"
              ? EditorCardClass.requiredSwitchAlign
              : type === "multiChoice"
              ? EditorCardClass.requiredSwitchAlignList
              : ""
          }
          label="Make this Field Required"
          labelPosition="before"
        />
      ) : (
        <Text>Preset fields are required by default</Text>
      )}
    </div>
  );
};

const RegFormMaker = () => {
  const registrationClass = registrationStyles();

  const [checkedValues, setCheckedValues] = useState<Record<string, string[]>>(
    {}
  );
  const onChange: ToolbarProps["onCheckedValueChange"] = (
    e,
    { name, checkedItems }
  ) => {
    setCheckedValues((s) => {
      return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems };
    });
  };

  console.log(checkedValues);

  return (
    <div className={registrationClass.root}>
      <Toolbar
        size="large"
        className={registrationClass.toolbar}
        checkedValues={checkedValues}
        onCheckedValueChange={onChange}
      >
        {/* <Tooltip relationship="label" content="Form Palette">
          <InputColor type="button" />
        </Tooltip>
        <ToolbarDivider /> */}
        <Tooltip
          relationship="label"
          content={
            checkedValues.logo?.length > 0 ? "Disable Logo" : "Enable Logo"
          }
        >
          <ToolbarToggleButton
            icon={<IconsFilled />}
            name="logo"
            value="logo"
            disabled={checkedValues.logo?.length > 0 ? false : true} // Tentative condition
          />
        </Tooltip>

        {/* <ToolbarDivider />
        <ToolbarGroup>
          <ToolbarButton vertical icon={<ContactCardFilled />}>
            Name
          </ToolbarButton>
          <ToolbarButton vertical icon={<CallFilled />}>
            Phone
          </ToolbarButton>
          <ToolbarButton vertical icon={<MailFilled />}>
            Mail
          </ToolbarButton>
          <ToolbarButton vertical icon={<BriefcaseFilled />}>
            Workplace
          </ToolbarButton>
        </ToolbarGroup> */}
        <ToolbarDivider />
        <ToolbarButton vertical icon={<RenameRegular />}>
          Text Field
        </ToolbarButton>
        <ToolbarButton vertical icon={<CheckboxCheckedRegular />}>
          CheckList
        </ToolbarButton>
        <Tooltip
          relationship="label"
          content="Multiple Choice Question"
          positioning="below"
        >
          <ToolbarButton vertical icon={<RadioButtonFilled />}>
            MCQ
          </ToolbarButton>
        </Tooltip>
        <ToolbarButton vertical icon={<ChevronCircleDownRegular />}>
          Droplist
        </ToolbarButton>
        {/* <ToolbarDivider /> */}
        <div className={registrationClass.ExcecutionContainer}>
          <Menu positioning="below-end">
            <MenuTrigger disableButtonEnhancement>
              {(triggerProps: MenuButtonProps) => (
                <SplitButton appearance="primary" menuButton={triggerProps}>
                  Publish
                </SplitButton>
              )}
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem>Save Draft</MenuItem>
                <MenuItem>Discard</MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      </Toolbar>
      <div className={registrationClass.editor}>
        <EditorCard preset="name" />
        <EditorCard preset="phone" />
        <EditorCard preset="mail" />
        <EditorCard preset="workspace" />
        <EditorCard type="multiChoice" />
        <EditorCard type="inputType" />
      </div>
      {/* <div className={registrationClass.previewContainer}> */}
      {/* <div className={registrationClass.preview}> */}
      {/* <PhoneInput /> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default RegFormMaker;
