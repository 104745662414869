import {
  Button,
  Input,
  makeStyles,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  tokens,
} from "@fluentui/react-components";
import { FC, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";

const InputColorStyles = makeStyles({
  root: {
    width: "7rem",
  },
  colorPreview: {
    width: "1.25rem",
    aspectRatio: "1/1",
    borderRadius: tokens.borderRadiusMedium,
    border: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralForegroundDisabled}`,
  },
});

export interface ContentProps {
  type?: "button" | "input";
}

const InputColor: FC<ContentProps> = ({ type = "input" }) => {
  const [color, setColor] = useState<string>("#88CFC1");

  const handleChangeComplete = (color: ColorResult) => {
    setColor(color.hex);
  };

  const InputColorClasses = InputColorStyles();

  if (type === "input")
    return (
      <Input
        appearance="filled-lighter"
        type="text"
        className={InputColorClasses.root}
        value={color}
        onChange={(e) => setColor(e.target.value)}
        contentBefore={
          <Popover positioning="after">
            <PopoverTrigger>
              <Button
                appearance="transparent"
                icon={
                  <div
                    className={InputColorClasses.colorPreview}
                    style={{ backgroundColor: color }}
                  />
                }
              />
            </PopoverTrigger>
            <PopoverSurface>
              <SketchPicker
                color={color}
                onChangeComplete={handleChangeComplete}
              />
            </PopoverSurface>
          </Popover>
        }
      />
    );
  else
    return (
      <Popover positioning="below-start">
        <PopoverTrigger>
          <Button
            appearance="transparent"
            icon={
              <div
                className={InputColorClasses.colorPreview}
                style={{ backgroundColor: color }}
              />
            }
          />
        </PopoverTrigger>
        <PopoverSurface>
          <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
        </PopoverSurface>
      </Popover>
    );
};

export default InputColor;
