// src/ThemeContext.tsx
import {
  BrandVariants,
  createDarkTheme,
  createLightTheme,
  Theme,
} from "@fluentui/react-components";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

const appsvenue: BrandVariants = {
  10: "#030403",
  20: "#151A19",
  30: "#212C2A",
  40: "#293935",
  50: "#314642",
  60: "#3A544E",
  70: "#43625B",
  80: "#4C7069",
  90: "#557F76",
  100: "#5F8E84",
  110: "#699D92",
  120: "#72ACA1",
  130: "#7CBCB0",
  140: "#86CCBE",
  150: "#A1D9CE",
  160: "#BFE5DD",
};

const lightTheme: Theme = {
  ...createLightTheme(appsvenue),
};

const darkTheme: Theme = {
  ...createDarkTheme(appsvenue),
};

darkTheme.colorBrandForeground1 = appsvenue[110];
darkTheme.colorBrandForeground2 = appsvenue[120];

interface ThemeContextProps {
  theme: Theme;
  toggleTheme: () => void;
  lightTheme: Theme;
  darkTheme: Theme;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "dark" ? darkTheme : lightTheme;
  });

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === lightTheme ? darkTheme : lightTheme;
      localStorage.setItem("theme", newTheme === darkTheme ? "dark" : "light");
      return newTheme;
    });
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e: MediaQueryListEvent) => {
      const newTheme = e.matches ? darkTheme : lightTheme;
      setTheme(newTheme);
      localStorage.setItem("theme", newTheme === darkTheme ? "dark" : "light");
    };

    theme === darkTheme
      ? document
          .querySelector('meta[name="theme-color"]')
          ?.setAttribute("content", "#141414")
      : document
          .querySelector('meta[name="theme-color"]')
          ?.setAttribute("content", "#f5f5f5");

    mediaQuery.addEventListener("change", handleChange);
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, lightTheme, darkTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export default ThemeProvider;
