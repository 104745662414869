import {
  Badge,
  Body1,
  Button,
  Caption1,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Dialog,
  DialogBody,
  DialogSurface,
  DialogTrigger,
  Field,
  Image,
  makeStyles,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  ProgressBar,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import {
  AppsListDetailFilled,
  ArchiveFilled,
  ArrowEnterFilled,
  CertificateFilled,
  CheckmarkCircleFilled,
  ClockFilled,
  FormFilled,
  GlobeFilled,
  GroupListFilled,
  LiveFilled,
  MoreHorizontalFilled,
  OpenFilled,
  PeopleFilled,
  QrCodeFilled,
  RibbonFilled,
  ToolboxFilled,
} from "@fluentui/react-icons";
import { FC } from "react";
import { Venue } from "../data/eventData";
import { useNavigate } from "react-router-dom";

const eventCardStyles = makeStyles({
  root: {
    borderRadius: "20px",
    backgroundColor: tokens.colorNeutralBackground2,
  },
  headerContainer: {
    display: "block",
    marginBottom: tokens.spacingHorizontalL,
  },
  header: {
    paddingBottom: tokens.spacingHorizontalM,
  },
  badge: {
    display: "flex",
    marginTop: tokens.spacingHorizontalXS,
  },
});

export interface ContentProps {
  title: string;
  status?: 0 | 1 | 2;
  startDate: string;
  endDate: string;
  venue: Venue;
  attended?: any;
  registered?: any;
  image: any;
  website: string;
  sideEvent: any;
  registration: any;
}

const EventCards: FC<ContentProps> = ({
  title,
  status = 0,
  startDate,
  endDate,
  venue,
  attended,
  registered,
  image,
  website,
  sideEvent,
  registration,
}) => {
  const eventCardClasses = eventCardStyles();

  const navigate = useNavigate();

  return (
    <Card className={eventCardClasses.root}>
      <CardPreview>
        <img
          src={
            image
              ? "https://api.appsvenueevents.com" + image
              : "/assets/images/bannerImg.jpeg"
          }
          alt={title ? title : "bannerImg"}
          style={{
            height: "126px",
            objectFit: "cover",
          }}
        />
      </CardPreview>
      <CardHeader
        header={
          <div className={eventCardClasses.headerContainer}>
            <Body1 className={eventCardClasses.header}>
              <b>{title}</b>
            </Body1>
            <Badge
              appearance="outline"
              color={
                status === 0
                  ? "success"
                  : status === 1
                  ? "warning"
                  : status === 2
                  ? "informative"
                  : "danger"
              }
              shape="rounded"
              size="large"
              className={eventCardClasses.badge}
              icon={
                status === 0 ? (
                  <LiveFilled />
                ) : status === 1 ? (
                  <ClockFilled />
                ) : status === 2 ? (
                  <CheckmarkCircleFilled />
                ) : (
                  "danger"
                )
              }
            >
              {status === 0
                ? "Active"
                : status === 1
                ? "Pending"
                : status === 2
                ? "Finished"
                : "Failed"}
            </Badge>
          </div>
        }
        description={
          <div style={{ display: "block" }}>
            <Caption1 style={{ display: "block" }}>
              {startDate.substring(8, 10)}-{startDate.substring(5, 7)}-
              {startDate.substring(0, 4)} to {endDate.substring(8, 10)}-
              {endDate.substring(5, 7)}-{endDate.substring(0, 4)}
            </Caption1>
            <Caption1 style={{ display: "block" }}>
              {parseInt(startDate.substring(11, 13)) > 10
                ? (parseInt(startDate.substring(11, 13)) - 12).toString()
                : parseInt(startDate.substring(11, 13)) > 12
                ? "0" + (parseInt(startDate.substring(11, 13)) - 12).toString()
                : startDate.substring(11, 13)}
              {" : "}
              {startDate.substring(14, 16)}{" "}
              {parseInt(startDate.substring(11, 13)) > 12 ? "PM" : "AM"} to{" "}
              {parseInt(endDate.substring(11, 13)) > 10
                ? (parseInt(endDate.substring(11, 13)) - 12).toString()
                : parseInt(endDate.substring(11, 13)) > 12
                ? "0" + (parseInt(endDate.substring(11, 13)) - 12).toString()
                : endDate.substring(11, 13)}
              {" : "}
              {endDate.substring(14, 16)}{" "}
              {parseInt(endDate.substring(11, 13)) > 12 ? "PM" : "AM"}
            </Caption1>
            <Caption1 style={{ display: "block" }}>{venue.name}</Caption1>
          </div>
        }
        action={
          <div
            style={{
              display: "flex",
              gap: tokens.spacingVerticalL,
            }}
          >
            <Tooltip content="Registration Form" relationship="label">
              <Button
                appearance="secondary"
                onClick={() =>
                  window.open(`https://www.appsvenueevents.com/${registration.link}`)
                }
                icon={<OpenFilled />}
              />
            </Tooltip>
            <Tooltip relationship="label" content="Registration Link QR Code">
              <Dialog>
                <DialogTrigger disableButtonEnhancement>
                  <Button icon={<QrCodeFilled />} />
                </DialogTrigger>
                <DialogSurface style={{ maxWidth: "10rem" }}>
                  <DialogBody
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Image src={registration.qr} />
                    <DialogTrigger disableButtonEnhancement>
                      <Button>Close</Button>
                    </DialogTrigger>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            </Tooltip>
            <Menu>
              <MenuTrigger>
                <Tooltip content="More" relationship="label">
                  <Button
                    appearance="transparent"
                    icon={<MoreHorizontalFilled />}
                  />
                </Tooltip>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  {website && (
                    <MenuItem
                      onClick={() =>
                        window.open(website, "_blank", "noopener,noreferrer")
                      }
                      icon={<GlobeFilled />}
                    >
                      Website
                    </MenuItem>
                  )}
                  {sideEvent && (
                    <MenuItem icon={<AppsListDetailFilled />}>
                      Side Events
                    </MenuItem>
                  )}
                  {(website || sideEvent) && <MenuDivider />}
                  <MenuGroup>
                    <MenuGroupHeader>Editor</MenuGroupHeader>
                    {/* <MenuItem
                      icon={<FormFilled />}
                      onClick={() => navigate("/Dashboard/Editor")}
                    >
                      Registration Form
                    </MenuItem> */}
                    <MenuItem
                      icon={<CertificateFilled />}
                      onClick={() => navigate("/Dashboard/Editor/CME")}
                    >
                      CME Certificate
                    </MenuItem>
                    <MenuItem
                      icon={<RibbonFilled />}
                      onClick={() => navigate("/Dashboard/Editor/Badge")}
                    >
                      Badge
                    </MenuItem>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup>
                    <MenuGroupHeader>Check Ins</MenuGroupHeader>
                    <MenuItem
                      icon={<ArrowEnterFilled />}
                      onClick={() => navigate("/Dashboard/CheckIn")}
                    >
                      Main
                    </MenuItem>
                    {/* <MenuItem
                      icon={<GroupListFilled />}
                      onClick={() => navigate("/Dashboard/CheckIn/Session")}
                    >
                      Sessions
                    </MenuItem> */}
                    <MenuItem
                      icon={<ToolboxFilled />}
                      onClick={() => navigate("/Dashboard/CheckIn/Workshop")}
                    >
                      Workshops
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
        }
      />
      <CardFooter style={{ display: "block" }}>
        <Field
          label={
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: tokens.spacingHorizontalXS,
              }}
            >
              <PeopleFilled />
              <span style={{ marginLeft: "auto" }}>
                {attended} / {registered}
              </span>
            </p>
          }
        >
          <ProgressBar
            thickness="large"
            shape="rounded"
            value={attended}
            max={registered}
          />
        </Field>
      </CardFooter>
    </Card>
  );
};

export default EventCards;
