import {
  Button,
  Checkbox,
  CheckboxProps,
  Divider,
  Field,
  Input,
  makeStyles,
  mergeClasses,
  Select,
  Switch,
  Textarea,
  Title1,
  Title3,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
  AddFilled,
  ArrowResetFilled,
  ClipboardPasteFilled,
  DismissFilled,
  EditSettingsFilled,
  EyeFilled,
  EyeOffFilled,
  GlobeFilled,
  LinkFilled,
  SaveFilled,
} from "@fluentui/react-icons";

import { ChangeEvent, FC, useEffect, useState } from "react";
import InputFile from "./InputFile";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { createEvent } from "../utils/event";

const formatDate = (date: any) => {
  const parts = date.split(" ");
  const month = parts[1];
  const day = parts[2];
  const year = parts[3];

  const monthMap: any = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  const monthNum = monthMap[month];
  return `${year}-${monthNum}-${day}`;
};

const formStyles = makeStyles({
  root: {
    padding: tokens.spacingVerticalXL,
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalL,
  },
  title: {
    textAlign: "center",
  },

  mainGrid1: {
    display: "grid",
    gridGap: tokens.spacingHorizontalXXXL,
    gridTemplateColumns: "auto 1fr",
    alignSelf: "center",
  },

  dateWidth: {
    width: "100%",
  },

  timeInput: {
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      display: "none",
    },
  },

  dateTimeFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: tokens.spacingVerticalL,
  },

  inputX2Grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: tokens.spacingVerticalL,
  },

  inputX3Grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridGap: tokens.spacingVerticalL,
  },

  inputArabic: {
    direction: "rtl",
  },

  buttonGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: tokens.spacingHorizontalL,
  },

  sideEventCards: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalL,
    padding: tokens.spacingHorizontalXXL,
    backgroundColor: tokens.colorNeutralBackground1,
    border: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralBackground4}`,
    borderRadius: "20px",
    boxShadow: tokens.shadow8,
  },
  sideEventGrid1: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridGap: tokens.spacingHorizontalL,
  },
  sideEventGrid2: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridGap: tokens.spacingHorizontalL,
  },
  dangerButton: {
    backgroundColor: tokens.colorStatusDangerBackground3,
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground2,
    },
    ":active": {
      backgroundColor: tokens.colorStatusDangerBackground1,
    },
  },
});

const EventForm = () => {
  const formClasses = formStyles();
  const formData = new FormData();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [vLocation, setVLocation] = useState("");
  const [eLocation, setELocation] = useState("");

  const [enableSideEvent, setEnableSideEvent] = useState(false);
  const [sideEvent, setSideEvent] = useState<any>([]);

  // Event Content
  const [enTitle, setEnTitle] = useState("");
  const [arTitle, setArTitle] = useState("");
  const [prefix, setPrefix] = useState("");
  const [status, setStatus] = useState("0");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [venue, setVenue] = useState("");
  const [description, setDescription] = useState("");
  const [mailUName, setMailUName] = useState("");
  const [mailPass, setMailPass] = useState("");
  const [regStartDate, setRegStartDate] = useState<any>(new Date());
  const [regEndDate, setRegEndDate] = useState<any>(new Date());
  const [regStartTime, setRegStartTime] = useState<string>("");
  const [regEndTime, setRegEndTime] = useState<string>("");
  const [feeAmount, setFeeAmount] = useState("0");
  const [feeCurrency, setFeeCurrency] = useState("");
  const [responsible, setResponsible] = useState("");
  const [organiser, setOrganiser] = useState("");
  const [logo, setLogo] = useState<File | null>(null);
  const [banner, setBanner] = useState<File | null>(null);

  // onInput Functions
  const onStartInput = (ev: ChangeEvent<HTMLInputElement>) => {
    setStartTime(ev.target.value);
  };

  const onEndInput = (ev: ChangeEvent<HTMLInputElement>) => {
    setEndTime(ev.target.value);
  };

  const onRegStartInput = (ev: ChangeEvent<HTMLInputElement>) => {
    setRegStartTime(ev.target.value);
  };

  const onRegEndInput = (ev: ChangeEvent<HTMLInputElement>) => {
    setRegEndTime(ev.target.value);
  };

  //Form Data
  const submitAVEvent = () => {
    formData.append("status", status.toString());
    if (
      banner !== null &&
      enTitle !== "" &&
      venue !== "" &&
      prefix !== "" &&
      mailUName !== "" &&
      mailPass !== "" &&
      startTime !== "" &&
      endTime !== "" &&
      regStartTime !== "" &&
      regEndTime !== ""
    ) {
      if (
        window.confirm(`Event "${enTitle}" is about to be created`) === false
      ) {
        return;
      }
      formData.append("banner", banner);
      formData.append("enTitle", enTitle);
      formData.append("prefix", prefix);
      formData.append("venue", venue);
      formData.append("mailUName", mailUName);
      formData.append("mailPass", mailPass);
      formData.append(
        "duration_start",
        `${formatDate(startDate.toString())}T${startTime}`
      );
      formData.append(
        "duration_end",
        `${formatDate(endDate.toString())}T${endTime}`
      );
      formData.append(
        "reg_duration_start",
        `${formatDate(regStartDate.toString())}T${regStartTime}`
      );
      formData.append(
        "reg_duration_end",
        `${formatDate(regEndDate.toString())}T${regEndTime}`
      );

      formData.append("feeAmount", feeAmount);
      formData.append("feeCurrency", feeCurrency);

      // Optional Field
      logo !== null && logo !== undefined && formData.append("logo", logo);
      arTitle !== "" && formData.append("arTitle", arTitle);
      description !== "" && formData.append("description", description);
      eLocation !== "" && formData.append("website", eLocation);
      responsible !== "" && formData.append("responsible", responsible);
      organiser !== "" && formData.append("organiser", organiser);
      vLocation !== "" && formData.append("venueURL", vLocation);
      sideEvent.length !== 0 &&
        formData.append("sideEvent", JSON.stringify(sideEvent));
      const response = createEvent(formData);
      console.log(response);

      navigate("/Dashboard/Editor");
    } else {
      alert("Required fields not entered");
    }
  };

  // Side Event Content
  const addSideEvent = () => {
    const newID = uuidv4();
    setSideEvent([
      ...sideEvent,
      {
        id: newID,
        se_type: "Workshop",
        title: "",
        se_duration: { start: "", end: "" },
        venue: "",
        attendees: {
          limit: 0,
          list: [],
        },
      },
    ]);
  };

  const enableSideEventToggle = () => {
    if (enableSideEvent) {
      if (
        window.confirm("This may remove side event entries. Proceed?") === false
      ) {
        return;
      }
    }
    const newID = uuidv4();
    setEnableSideEvent(!enableSideEvent);
    enableSideEvent
      ? setSideEvent([])
      : setSideEvent([
          ...sideEvent,
          {
            id: newID,
            se_type: "Workshop",
            title: "",
            se_duration: { start: "", end: "" },
            venue: "",
            attendees: {
              limit: 0,
              list: [],
            },
          },
        ]);
  };

  const deleteSideEvent = (id: any) => {
    console.log("Deleting event with id:", id);
    setSideEvent(sideEvent.filter((event: any) => event.id !== id));
  };

  const handleSideEventChange = (index: any, data: any) => {
    const newSideEventData = sideEvent.map((event: any) =>
      event.id === index ? { ...event, ...data } : event
    );
    setSideEvent(newSideEventData);
  };

  useEffect(() => {
    document.title = "Appsvenue Events · New Event";
  }, []);

  const togglePasswordView = () => {
    setShowPass(!showPass);
  };

  const handleVLocChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVLocation(event.target.value);
  };

  const handleELocChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    setELocation(event.target.value);
  };

  const pasteText = async () => {
    try {
      setVLocation(await navigator.clipboard.readText());
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  };

  const pasteEText = async () => {
    try {
      setELocation(await navigator.clipboard.readText());
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  };

  const handleGeneratePrefix = () => {
    // Split the event name into words, take the first letter of each word and join them
    const generatedPrefix = enTitle
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
    setPrefix(generatedPrefix);
  };

  return (
    <div className={formClasses.root}>
      <Title1 className={formClasses.title}>Create New Event</Title1>
      <div className={formClasses.mainGrid1}>
        <Field
          label="Upload Logo"
          hint={
            <>
              Recommends <b>vector</b> for scalable use
            </>
          }
        >
          <InputFile outline setInput={setLogo} />
        </Field>
        <Field
          label="Upload Banner"
          required
          hint={
            <>
              <b>Minimum |</b> <b>W:</b> 1000, <b>H:</b> 250,{" "}
              <b>Aspect Ratio:</b> 4:1
            </>
          }
        >
          <InputFile dimension="banner" setInput={setBanner} />
        </Field>
      </div>
      <div className={formClasses.inputX3Grid}>
        <Field label="Event Name" required>
          <Input
            type="text"
            size="large"
            placeholder="Enter Event Name"
            value={enTitle}
            onChange={(e) => setEnTitle(e.target.value)}
          />
        </Field>

        <Field label="Event Name (In Arabic)">
          <Input
            type="text"
            size="large"
            className={formClasses.inputArabic}
            value={arTitle}
            onChange={(e) => setArTitle(e.target.value)}
            placeholder="أدخل اسم الحدث "
          />
        </Field>
        <Field label="Event Prefix" required>
          <Input
            size="large"
            type="text"
            value={prefix}
            contentAfter={
              <Tooltip relationship="label" content="Generate Prefix">
                <Button
                  appearance="subtle"
                  onClick={handleGeneratePrefix}
                  icon={<EditSettingsFilled />}
                />
              </Tooltip>
            }
            onChange={(e) => setPrefix(e.target.value)}
            placeholder="Enter Event Prefix"
          />
        </Field>
      </div>
      <div className={formClasses.inputX2Grid}>
        <Field label="Event Website">
          <Input
            type="url"
            size="large"
            value={eLocation}
            onChange={handleELocChanges}
            contentBefore={<GlobeFilled />}
            contentAfter={
              <Button
                appearance="transparent"
                onClick={pasteEText}
                icon={<ClipboardPasteFilled />}
              />
            }
            placeholder="Enter Website URL Here"
          />
        </Field>

        <Field label="Event Status" required>
          <Select
            size="large"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="0">Active</option>
            <option value="1">Pending</option>
            <option value="2">Finished</option>
          </Select>
        </Field>
      </div>
      <Field label="Event Starts at" required>
        <div className={formClasses.dateTimeFieldContainer}>
          <DatePicker
            size="large"
            placeholder="Select Date"
            className={formClasses.dateWidth}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <Input
            type="time"
            className={mergeClasses(
              formClasses.dateWidth,
              formClasses.timeInput
            )}
            placeholder="Select Time"
            value={startTime}
            onChange={onStartInput}
          />
        </div>
      </Field>
      <Field label="Event Ends at" required>
        <div className={formClasses.dateTimeFieldContainer}>
          <DatePicker
            className={formClasses.dateWidth}
            placeholder="Select Date"
            size="large"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <Input
            type="time"
            className={mergeClasses(
              formClasses.dateWidth,
              formClasses.timeInput
            )}
            placeholder="Select Time"
            value={endTime}
            onChange={onEndInput}
          />
        </div>
      </Field>
      {/* <Field label="Event Title">
        <Input type="text" placeholder="Enter Event Title" />
        <Checkbox label="Use Event Name as Event Title" />
      </Field> */}
      <div className={formClasses.inputX2Grid}>
        <Field label="Venue" required>
          <Input
            type="text"
            size="large"
            placeholder="Enter Venue Name"
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
          />
        </Field>
        <Field label="Venue Location (Google Maps URL)">
          <Input
            size="large"
            type="url"
            value={vLocation}
            onChange={handleVLocChanges}
            contentBefore={<LinkFilled />}
            contentAfter={
              <Button
                appearance="transparent"
                onClick={pasteText}
                icon={<ClipboardPasteFilled />}
              />
            }
            placeholder="Example: https://maps.app.goo.gl/5ENFVTNv1aEo92g78"
          />
        </Field>
      </div>
      <Field label="Event Description">
        <Textarea
          placeholder="Enter Event Description"
          size="large"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={4}
        />
      </Field>
      <Divider alignContent="start">
        <Title3>Mailing</Title3>
      </Divider>
      <div className={formClasses.inputX2Grid}>
        <Field label="Send From Mail" required>
          <Input
            placeholder="Enter Mail ID"
            size="large"
            type="email"
            value={mailUName}
            onChange={(e) => setMailUName(e.target.value)}
          />
        </Field>
        <Field label="Password" required>
          <Input
            size="large"
            type={showPass ? "text" : "password"}
            placeholder="Enter Password"
            value={mailPass}
            onChange={(e) => setMailPass(e.target.value)}
            contentAfter={
              <Tooltip
                relationship="label"
                content={showPass ? "Hide Password" : "Show Password"}
              >
                <Button
                  appearance="transparent"
                  icon={showPass ? <EyeFilled /> : <EyeOffFilled />}
                  onClick={togglePasswordView}
                />
              </Tooltip>
            }
          />
        </Field>
      </div>

      <Divider alignContent="start">
        <Title3>Registration</Title3>
      </Divider>
      <Field label="Registration Starts at" required>
        <div className={formClasses.dateTimeFieldContainer}>
          <DatePicker
            size="large"
            placeholder="Select Date"
            className={formClasses.dateWidth}
            value={regStartDate}
            onChange={(e) => setRegStartDate(e.target.value)}
          />
          <Input
            type="time"
            className={mergeClasses(
              formClasses.dateWidth,
              formClasses.timeInput
            )}
            placeholder="Select Time"
            value={regStartTime}
            onChange={onRegStartInput}
          />
        </div>
      </Field>
      <Field label="Registration Ends at" required>
        <div className={formClasses.dateTimeFieldContainer}>
          <DatePicker
            size="large"
            placeholder="Select Date"
            className={formClasses.dateWidth}
            value={regEndDate}
            onChange={(e) => setRegEndDate(e.target.value)}
          />
          <Input
            type="time"
            className={mergeClasses(
              formClasses.dateWidth,
              formClasses.timeInput
            )}
            placeholder="Select Time"
            value={regEndTime}
            onChange={onRegEndInput}
          />
        </div>
      </Field>
      <Divider />

      <div className={formClasses.inputX3Grid}>
        <Field label="Fee">
          <Input
            size="large"
            placeholder="0.000"
            value={feeAmount}
            onChange={(e) => setFeeAmount(e.target.value)}
            contentAfter={
              <Select
                appearance="underline"
                value={feeCurrency}
                onChange={(e) => setFeeCurrency(e.target.value)}
              >
                <option value={"KWD"}>KWD</option>
                <option value={"USD"}>USD</option>
              </Select>
            }
          />
        </Field>
        <Field label="Responsible">
          <Input
            size="large"
            type="text"
            placeholder="Responsible"
            value={responsible}
            onChange={(e) => setResponsible(e.target.value)}
          />
        </Field>
        <Field label="Organizer">
          <Input
            size="large"
            type="text"
            placeholder="Organizer"
            value={organiser}
            onChange={(e) => setOrganiser(e.target.value)}
          />
        </Field>
      </div>
      <Divider alignContent="start">
        <Title3>Side Events</Title3>
      </Divider>
      <Switch
        label="Enable Side Events"
        checked={enableSideEvent}
        onChange={enableSideEventToggle}
      />

      {enableSideEvent && (
        <>
          {sideEvent.map((sideevent: any) => (
            <SideEventSection
              event={sideevent}
              onDelete={deleteSideEvent}
              onChange={handleSideEventChange}
            />
          ))}

          <Button
            appearance="subtle"
            icon={<AddFilled />}
            size="large"
            onClick={addSideEvent}
          >
            Add Side Events
          </Button>
        </>
      )}

      <Divider />
      <div className={formClasses.buttonGrid}>
        <Button size="large" appearance="outline" icon={<ArrowResetFilled />}>
          Reset
        </Button>
        {/* <Button size="large" appearance="secondary" icon={<DraftsFilled />}>
          Save Draft
        </Button> */}
        <Button
          size="large"
          appearance="primary"
          onClick={submitAVEvent}
          icon={<SaveFilled />}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

interface SideEventProps {
  event: SideEvent;
  onDelete: any;
  onChange: any;
}

const SideEventSection: FC<SideEventProps> = ({
  event,
  onDelete,
  onChange,
}) => {
  const formClasses = formStyles();

  const [attendeesLimit, setAttendeesLimit] =
    useState<CheckboxProps["checked"]>(false);

  const [seType, setSeType] = useState("Workshop");
  const [title, setTitle] = useState("");
  const [seStartDate, setSeStartDate] = useState<any>(new Date());
  const [seEndDate, setSeEndDate] = useState<any>(new Date());
  const [seStartTime, setSeStartTime] = useState<string>("");
  const [seEndTime, setSeEndTime] = useState<string>("");
  const [venue, setVenue] = useState("");
  const [currency, setCurrency] = useState("KWD");
  const [amount, setAmount] = useState("0");
  const [audienceLimit, setAudienceLimit] = useState("0");

  const onStartInput = (ev: ChangeEvent<HTMLInputElement>) => {
    setSeStartTime(ev.target.value);
  };

  const onEndInput = (ev: ChangeEvent<HTMLInputElement>) => {
    setSeEndTime(ev.target.value);
  };

  const sideEventData: SideEvent = {
    id: event.id,
    se_type: seType,
    title,
    se_duration: {
      start: `${formatDate(seStartDate.toString())}T${seStartTime}`,
      end: `${formatDate(seEndDate.toString())}T${seEndTime}`,
    },
    venue,
    attendees: {
      limit: Number(audienceLimit),
    },
  };

  if (attendeesLimit === true) sideEventData.limit = parseInt(audienceLimit);

  sideEventData.fee = { amount: parseInt(amount), currency };

  useEffect(
    () => {
      onChange(event.id, sideEventData);
      console.log(
        formatDate(seStartDate.toString()),
        "+",
        formatDate(seEndDate.toString())
      );
    },
    // eslint-disable-next-line
    [
      seType,
      title,
      seStartDate,
      seEndDate,
      seStartTime,
      seEndTime,
      venue,
      amount,
      currency,
      audienceLimit,
      event.id,
      onChange,
    ]
  );

  const handleFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  return (
    <div className={formClasses.sideEventCards}>
      <Button
        appearance="primary"
        className={formClasses.dangerButton}
        icon={<DismissFilled />}
        onClick={() => onDelete(event.id)}
      />
      <div className={formClasses.sideEventGrid1}>
        <Field label="Side Event Type">
          <Select value={seType} onChange={(e) => setSeType(e.target.value)}>
            <option value={event.se_type}>{event.se_type}</option>
          </Select>
        </Field>
        <Field label="Side Event Name">
          <Input
            placeholder="Enter Side Event Name / Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Field>
      </div>
      <Field label="Side Event Starts at">
        <div className={formClasses.dateTimeFieldContainer}>
          <DatePicker
            placeholder="Select Date"
            className={formClasses.dateWidth}
            value={seStartDate}
            onChange={(e) => setSeStartDate(e.target.value)}
          />
          <Input
            type="time"
            className={mergeClasses(
              formClasses.dateWidth,
              formClasses.timeInput
            )}
            placeholder="Select Time"
            value={seStartTime}
            onChange={onStartInput}
          />
        </div>
      </Field>
      <Field label="Side Event Ends at">
        <div className={formClasses.dateTimeFieldContainer}>
          <DatePicker
            placeholder="Select Date"
            className={formClasses.dateWidth}
            value={seEndDate}
            onChange={(e) => setSeEndDate(e.target.value)}
          />
          <Input
            type="time"
            className={mergeClasses(
              formClasses.dateWidth,
              formClasses.timeInput
            )}
            placeholder="Select Time"
            value={seEndTime}
            onChange={onEndInput}
          />
        </div>
      </Field>
      <div className={formClasses.sideEventGrid2}>
        <Field label="Venue">
          <Input
            placeholder="Enter Venue"
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
          />
        </Field>
        <Field label="Fee">
          <Input
            placeholder={currency === "KWD" ? "0.000" : "0.00"}
            value={amount}
            onChange={handleFeeChange}
            contentAfter={
              <Select
                appearance="underline"
                value={currency}
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
              >
                <option value="KWD">KWD</option>
                <option value="USD">USD</option>
              </Select>
            }
          />
        </Field>
        <Field label="Attendees Limit">
          <Input
            placeholder="0"
            value={audienceLimit}
            onChange={(e: any) => setAudienceLimit(e.target.value)}
            contentBefore={
              <>
                <Checkbox
                  label="Set Attendees Limit"
                  checked={attendeesLimit}
                  onChange={() => setAttendeesLimit(!attendeesLimit)}
                />
              </>
            }
            disabled={attendeesLimit ? false : true}
          />
        </Field>
      </div>
    </div>
  );
};

export default EventForm;
